import { Card, CardSection, Carousel, CarouselItem, CarouselViewport, Skeleton, Stacker, Styler, TypographyBody, TypographyHeading, } from '@honeycomb/ui-core';
import React from 'react';
import { CarouselDisplayStyle } from '@honeycomb/data';
import { CAROUSEL_THEME } from '../../types/carousel-types';
import { useRootComponent } from './RootComponentContext';
export function PersonalisedProductsLoading() {
    const { carouselDisplayStyle, hasRepeatingBackgroundImage } = useRootComponent();
    const fullWidth = carouselDisplayStyle === CarouselDisplayStyle.FullWidth;
    return (React.createElement(Carousel, { visibleItems: CAROUSEL_THEME.visibleItems[carouselDisplayStyle], spacing: CAROUSEL_THEME.spacing[carouselDisplayStyle], overflow: fullWidth ? 'hidden' : 'visible', color: hasRepeatingBackgroundImage ? 'secondary' : 'primary' },
        React.createElement(CarouselViewport, null, [...Array(3)].map(() => {
            return (React.createElement(CarouselItem, null,
                React.createElement(Card, null,
                    React.createElement(CardSection, null,
                        React.createElement(Skeleton, { h: 220, w: "100%" })),
                    React.createElement(CardSection, null,
                        React.createElement(Styler, { mt: 1, h: 137, w: "100%" },
                            React.createElement(TypographyHeading, { component: "div", size: "s" },
                                React.createElement(Skeleton, { variant: "text" })),
                            React.createElement(TypographyBody, { mt: 2, component: "div", w: "60%", size: "s" },
                                React.createElement(Skeleton, { variant: "text" })),
                            React.createElement(Styler, { mt: 3 },
                                React.createElement(Skeleton, { w: 80, h: "1em", variant: "text" }),
                                React.createElement(Skeleton, { mt: 1, w: 120, h: "1em", variant: "text" })),
                            React.createElement(Stacker, { mt: 1, spacing: 2 }, ['55%', '40%'].map((width) => {
                                return (React.createElement(TypographyBody, { component: "div", size: "s", key: width },
                                    React.createElement(Skeleton, { variant: "text", w: width })));
                            })))),
                    React.createElement(CardSection, null,
                        React.createElement(Skeleton, { mt: 1, h: 73, w: "100%" })))));
        }))));
}
