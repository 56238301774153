import React from 'react';
import { Button, EditIcon, FlexGrid, useHoneycombTheme } from '@honeycomb/ui-core';
import { useGlobalContext } from '@honeycomb/data';
import { CollapsedSearchLabel } from './CollapsedSearchLabel';
export function CollapsedSearchBar({ fields, loading, onCtaClick }) {
    const { resourceStrings: { COLLAPSED_SEARCH_CTA_LABEL }, } = useGlobalContext();
    const { theme } = useHoneycombTheme();
    // Match label height to the button
    const buttonSize = 's';
    const labelHeight = theme.components.themes.Button.size[buttonSize].height;
    const handleCtaClick = (event) => {
        if (onCtaClick) {
            onCtaClick(event);
        }
    };
    return (React.createElement(FlexGrid, { container: true, alignItems: "center", flexWrap: "nowrap", justifyContent: "center", spacing: 2 },
        React.createElement(FlexGrid, { xs: "fill" },
            React.createElement(FlexGrid, { container: true, alignItems: "center", flexWrap: "nowrap", spacing: 2 }, fields.map(({ value, placeholder, key }) => {
                return (React.createElement(FlexGrid, { xs: "fill", key: key },
                    React.createElement(CollapsedSearchLabel, { height: labelHeight, isPlaceholder: !value }, value || placeholder)));
            }))),
        React.createElement(FlexGrid, { xs: "hold" },
            React.createElement(Button, { color: "callToAction", size: buttonSize, icon: React.createElement(EditIcon, null), onClick: handleCtaClick, loading: loading }, COLLAPSED_SEARCH_CTA_LABEL))));
}
