import { router } from '../utils/router';
import { getDetailsPageUrl } from '../search/searchCreatedRouting';
function searchCreatedRoute(params) {
    const { searchParameters, hotelId } = params;
    const url = getDetailsPageUrl(searchParameters, Number(hotelId));
    return [url, undefined];
}
export function dealSelectedRouting(params) {
    router.push(params, searchCreatedRoute);
}
