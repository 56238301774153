import { useGlobalContext } from '@honeycomb/data';
import { TypographyBody, Button, FlexGrid, MinusIcon, PlusIcon, RadioGroup, Stacker, Paper, RadioChip, } from '@honeycomb/ui-core';
import React from 'react';
import { toUTCDate } from '../../../utils/date-utils';
import { useSearchContext } from '../SearchContext';
import { SearchDispatchActionType } from '../SearchReducer';
const generateMonthYear = (monthCount, monthRolloverDays) => {
    const today = new Date();
    const firstDayNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    const daysLeft = Math.ceil((firstDayNextMonth.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    if (daysLeft <= monthRolloverDays)
        today.setDate(today.getDate() + daysLeft + 1);
    const months = [];
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const index = today.getDate() === lastDayOfMonth.getDate() ? 1 : 0;
    for (let i = index; i < monthCount; i += 1) {
        const newDate = new Date(today.getFullYear(), today.getMonth() + i, 1);
        const monthName = newDate.toLocaleString('default', { month: 'long' });
        const year = newDate.getFullYear();
        months.push({
            label: `${monthName.slice(0, 3)} ${year}`,
            value: toUTCDate(newDate),
            index: i,
        });
    }
    return months;
};
export function FlexibleDateSelector() {
    const minNights = 1;
    const maxNights = 21;
    const totalMonthsToShow = 18;
    const { configSettings: { HCSearchDuration }, } = useGlobalContext();
    const { searchDispatch, searchState } = useSearchContext();
    const nights = searchState.duration || parseInt(HCSearchDuration);
    const { resourceStrings: { SINGLENIGHT, NIGHTS }, configSettings: { HCSearchMonthRolloverMaxDaysLeft }, } = useGlobalContext();
    const totalMonths = generateMonthYear(totalMonthsToShow, parseInt(HCSearchMonthRolloverMaxDaysLeft));
    const handleChangeMonth = (event) => {
        searchDispatch({
            type: SearchDispatchActionType.SET_WHOLE_MONTH_START_DATE,
            dateValue: event.target.value,
        });
    };
    return (React.createElement(Paper, { radius: "s", p: 4 },
        React.createElement(Stacker, { spacing: 4, alignItems: "center" },
            React.createElement(Stacker, { direction: "row", spacing: 3, alignItems: "center" },
                React.createElement(Button, { icon: React.createElement(MinusIcon, { biDirectional: true }), disabled: nights < minNights + 1, shape: "round", variant: "outlined", size: "l", "data-id": "decrease-night", onClick: () => {
                        searchDispatch({
                            type: SearchDispatchActionType.SET_DURATION,
                            duration: nights - 1,
                        });
                    } }),
                React.createElement(Stacker, { spacing: 0, ta: "center" },
                    React.createElement(TypographyBody, { component: "div", size: "xl", weight: "bold", lineHeight: 1 }, nights),
                    React.createElement(TypographyBody, { component: "div", size: "xs" }, `${nights === 1 ? SINGLENIGHT : NIGHTS} `)),
                React.createElement(Button, { icon: React.createElement(PlusIcon, { biDirectional: true }), disabled: nights > maxNights - 1, shape: "round", variant: "outlined", size: "l", "data-id": "increase-night", onClick: () => {
                        searchDispatch({
                            type: SearchDispatchActionType.SET_DURATION,
                            duration: nights + 1,
                        });
                    } })),
            React.createElement(RadioGroup, { name: "month-options", value: searchState.wholeMonthStartDate ? toUTCDate(new Date(searchState.wholeMonthStartDate)) : '', onChange: handleChangeMonth },
                React.createElement(FlexGrid, { container: true, spacing: 3, disableEqualOverflow: true }, totalMonths.map((item) => {
                    return (React.createElement(FlexGrid, { xs: 4, key: item.index.toString() },
                        React.createElement(RadioChip, { value: item.value, ChipProps: { fullWidth: true, size: 'l', cornerStyle: 'rounded' }, key: item.index.toString(), "data-id": `month-option-${item.index}` }, item.label)));
                }))))));
}
