import { AnywhereSearchResultEnum, TripType, initialSearchParameters, DateSearchType, FlightJourneyType, } from '@honeycomb/data';
import { toUTCDate } from '../../utils/date-utils';
export var SearchDispatchActionType;
(function (SearchDispatchActionType) {
    SearchDispatchActionType["SET_START_DATE"] = "SetStartDate";
    SearchDispatchActionType["SET_END_DATE"] = "SetEndDate";
    SearchDispatchActionType["SET_DURATION"] = "SetFlexibleDuration";
    SearchDispatchActionType["SET_DESTINATION_ID"] = "SetDestinationId";
    SearchDispatchActionType["ADD_DESTINATION_IDS"] = "AddDestinationIds";
    SearchDispatchActionType["SET_DESTINATION_TITLE"] = "SetDestinationTitle";
    SearchDispatchActionType["SET_HOTEL_ID"] = "SetHotelId";
    SearchDispatchActionType["ADD_HOTEL_IDS"] = "AddHotelIds";
    SearchDispatchActionType["SET_HOTEL_TITLE"] = "SetHotelTitle";
    SearchDispatchActionType["SET_ROOMS"] = "SetRooms";
    SearchDispatchActionType["SET_DEPARTURE_AIRPORTS"] = "SetDepartureAirports";
    SearchDispatchActionType["ADD_DEPARTURE_AIRPORTS"] = "AddDepartureAirports";
    SearchDispatchActionType["REMOVE_DEPARTURE_AIRPORTS"] = "RemoveDepartureAirports";
    SearchDispatchActionType["REMOVE_ALL_DEPARTURE_AIRPORTS"] = "RemoveAllDepartureAirports";
    SearchDispatchActionType["SET_ARRIVAL_AIRPORTS"] = "SetArrivalAirports";
    SearchDispatchActionType["ADD_ARRIVAL_AIRPORTS"] = "AddArrivalAirports";
    SearchDispatchActionType["REMOVE_ARRIVAL_AIRPORTS"] = "RemoveArrivalAirports";
    SearchDispatchActionType["REMOVE_ALL_ARRIVAL_AIRPORTS"] = "RemoveAllArrivalAirports";
    SearchDispatchActionType["SET_TRIP_TYPE"] = "SetTripType";
    SearchDispatchActionType["SET_CABIN_CLASS"] = "SetCabinClass";
    SearchDispatchActionType["SET_ADULTS"] = "SetAdults";
    SearchDispatchActionType["SET_CHILDAGES"] = "SetChildAges";
    SearchDispatchActionType["RESET_OCCUPANCY"] = "ResetOccupancy";
    SearchDispatchActionType["REMOVE_DESTINATION_ID"] = "RemoveDestinationId";
    SearchDispatchActionType["REMOVE_HOTEL_ID"] = "RemoveHotelId";
    SearchDispatchActionType["SET_FLEXIBLE_DAYS"] = "SetFlexibleDays";
    SearchDispatchActionType["ADD_FLEXIBLE_DESTINATION"] = "AddFexibleDestination";
    SearchDispatchActionType["REMOVE_FLEXIBLE_DESTINATION"] = "RemoveFexibleDestination";
    SearchDispatchActionType["CLEAR_FLEXIBLE_DESTINATION"] = "ClearFlexibleDestination";
    SearchDispatchActionType["SET_INITIAL_DESTINATIONS"] = "SetInitialDestinations";
    SearchDispatchActionType["REMOVE_NONCACHED"] = "RemoveAnyWhereOrNonCached";
    SearchDispatchActionType["SET_WHOLE_MONTH_START_DATE"] = "SetFlexibleStartDate";
    SearchDispatchActionType["SET_DATE_SEARCH_TYPE"] = "SetDateSearchType";
    SearchDispatchActionType["SET_PICKUP_POINT"] = "SetPickUpPoint";
    SearchDispatchActionType["SET_DROPOFF_POINT"] = "SetDropOffPoint";
    SearchDispatchActionType["SET_FLIGHT_JOURNEY_TYPE"] = "SetFlightJourneyType";
    SearchDispatchActionType["SET_USER_ID"] = "SetUserId";
})(SearchDispatchActionType || (SearchDispatchActionType = {}));
export const searchReducer = (searchState, { type, dateValue, destinationIdValue, destinationTitleValue, departureAirports, arrivalAirports, roomsValue, tripType, cabinValue, childAges, adultsValue, flexibleDays, duration, destination, idAndType, destinations, dateSearchType, pickDropPoint, flightJourneyType, userId, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    switch (type) {
        case SearchDispatchActionType.SET_START_DATE:
            return Object.assign(Object.assign({}, searchState), { startDate: dateValue || '' });
        case SearchDispatchActionType.SET_END_DATE:
            return Object.assign(Object.assign({}, searchState), { endDate: dateValue || '' });
        case SearchDispatchActionType.SET_DURATION:
            return Object.assign(Object.assign({}, searchState), { duration });
        case SearchDispatchActionType.SET_DESTINATION_ID:
            return Object.assign(Object.assign({}, searchState), { destinations: [], destinationIds: (destinationIdValue === null || destinationIdValue === void 0 ? void 0 : destinationIdValue[0]) ? [destinationIdValue === null || destinationIdValue === void 0 ? void 0 : destinationIdValue[0]] : [] });
        case SearchDispatchActionType.ADD_DESTINATION_IDS:
            return Object.assign(Object.assign({}, searchState), { destinations: [], destinationIds: [...new Set([...((_a = searchState.destinationIds) !== null && _a !== void 0 ? _a : []), ...(destinationIdValue !== null && destinationIdValue !== void 0 ? destinationIdValue : [])])] });
        case SearchDispatchActionType.ADD_HOTEL_IDS:
            return Object.assign(Object.assign({}, searchState), { destinations: [], hotelIds: [...new Set([...((_b = searchState.hotelIds) !== null && _b !== void 0 ? _b : []), ...(destinationIdValue !== null && destinationIdValue !== void 0 ? destinationIdValue : [])])] });
        case SearchDispatchActionType.REMOVE_DESTINATION_ID:
            return Object.assign(Object.assign({}, searchState), { destinations: [], destinationIds: (_d = (_c = searchState.destinationIds) === null || _c === void 0 ? void 0 : _c.filter((val) => !!val && !(destinationIdValue === null || destinationIdValue === void 0 ? void 0 : destinationIdValue.includes(val)))) !== null && _d !== void 0 ? _d : [] });
        case SearchDispatchActionType.REMOVE_HOTEL_ID:
            return Object.assign(Object.assign({}, searchState), { destinations: [], hotelIds: (_f = (_e = searchState.hotelIds) === null || _e === void 0 ? void 0 : _e.filter((val) => !!val && !(destinationIdValue === null || destinationIdValue === void 0 ? void 0 : destinationIdValue.includes(val)))) !== null && _f !== void 0 ? _f : [] });
        case SearchDispatchActionType.SET_HOTEL_ID:
            return Object.assign(Object.assign({}, searchState), { destinations: [], hotelIds: (destinationIdValue === null || destinationIdValue === void 0 ? void 0 : destinationIdValue[0]) ? [destinationIdValue === null || destinationIdValue === void 0 ? void 0 : destinationIdValue[0]] : [] });
        case SearchDispatchActionType.SET_ROOMS:
            if (!roomsValue)
                return searchState;
            return Object.assign(Object.assign({}, searchState), { rooms: roomsValue });
        case SearchDispatchActionType.SET_DESTINATION_TITLE:
            return Object.assign(Object.assign({}, searchState), { destinationTitle: destinationTitleValue || '' });
        case SearchDispatchActionType.SET_HOTEL_TITLE:
            return Object.assign(Object.assign({}, searchState), { hotelTitle: destinationTitleValue || '' });
        case SearchDispatchActionType.SET_DEPARTURE_AIRPORTS:
            return Object.assign(Object.assign({}, searchState), { departureAirports: departureAirports || [] });
        case SearchDispatchActionType.ADD_DEPARTURE_AIRPORTS:
            return Object.assign(Object.assign({}, searchState), { departureAirports: [
                    ...new Set([...(searchState.departureAirports || []), ...(departureAirports || [])]),
                ] });
        case SearchDispatchActionType.REMOVE_DEPARTURE_AIRPORTS:
            return Object.assign(Object.assign({}, searchState), { departureAirports: ((_g = searchState.departureAirports) === null || _g === void 0 ? void 0 : _g.filter((val) => !!val && (!departureAirports || !departureAirports.includes(val)))) || [] });
        case SearchDispatchActionType.REMOVE_ALL_DEPARTURE_AIRPORTS:
            return Object.assign(Object.assign({}, searchState), { departureAirports: [] });
        case SearchDispatchActionType.SET_TRIP_TYPE:
            return Object.assign(Object.assign({}, searchState), { tripType: tripType || TripType.Holiday });
        case SearchDispatchActionType.SET_CABIN_CLASS:
            return Object.assign(Object.assign({}, searchState), { cabin: cabinValue });
        case SearchDispatchActionType.SET_ARRIVAL_AIRPORTS:
            return Object.assign(Object.assign({}, searchState), { arrivalAirports: arrivalAirports || [] });
        case SearchDispatchActionType.ADD_ARRIVAL_AIRPORTS:
            return Object.assign(Object.assign({}, searchState), { arrivalAirports: [...new Set([...(arrivalAirports || [])])] });
        case SearchDispatchActionType.REMOVE_ARRIVAL_AIRPORTS:
            return Object.assign(Object.assign({}, searchState), { arrivalAirports: ((_h = searchState.arrivalAirports) === null || _h === void 0 ? void 0 : _h.filter((val) => !!val && (!arrivalAirports || !arrivalAirports.includes(val)))) || [] });
        case SearchDispatchActionType.REMOVE_ALL_ARRIVAL_AIRPORTS:
            return Object.assign(Object.assign({}, searchState), { arrivalAirports: [] });
        case SearchDispatchActionType.SET_ADULTS:
            return Object.assign(Object.assign({}, searchState), { adults: adultsValue || 2 });
        case SearchDispatchActionType.SET_CHILDAGES:
            return Object.assign(Object.assign({}, searchState), { childAges: childAges || [] });
        case SearchDispatchActionType.RESET_OCCUPANCY:
            return Object.assign(Object.assign({}, searchState), { rooms: initialSearchParameters.rooms });
        case SearchDispatchActionType.SET_FLEXIBLE_DAYS:
            return Object.assign(Object.assign({}, searchState), { flexibleDays });
        case SearchDispatchActionType.ADD_FLEXIBLE_DESTINATION: {
            if (destination) {
                if (destination.id === AnywhereSearchResultEnum.ANYWHERE_ID)
                    return Object.assign(Object.assign({}, searchState), { destinationIds: [], hotelIds: [], destinations: [destination] });
                const existingdestinations = [...((_j = searchState.destinations) !== null && _j !== void 0 ? _j : [])].filter((d) => (d === null || d === void 0 ? void 0 : d.id) !== AnywhereSearchResultEnum.ANYWHERE_ID);
                return Object.assign(Object.assign({}, searchState), { destinationIds: [], hotelIds: [], destinations: [destination, ...existingdestinations] });
            }
            return Object.assign({}, searchState);
        }
        case SearchDispatchActionType.REMOVE_FLEXIBLE_DESTINATION: {
            return Object.assign(Object.assign({}, searchState), { destinations: (_l = (_k = searchState.destinations) === null || _k === void 0 ? void 0 : _k.filter((val) => val && idAndType !== `${val.id}-${val.type}`)) !== null && _l !== void 0 ? _l : [] });
        }
        case SearchDispatchActionType.CLEAR_FLEXIBLE_DESTINATION:
            return Object.assign(Object.assign({}, searchState), { destinations: [] });
        case SearchDispatchActionType.SET_INITIAL_DESTINATIONS:
            return Object.assign(Object.assign({}, searchState), { destinations });
        case SearchDispatchActionType.REMOVE_NONCACHED:
            return Object.assign(Object.assign({}, searchState), { destinations: (_o = (_m = searchState.destinations) === null || _m === void 0 ? void 0 : _m.filter((val) => (val === null || val === void 0 ? void 0 : val.id) !== AnywhereSearchResultEnum.ANYWHERE_ID && (val === null || val === void 0 ? void 0 : val.isCached))) !== null && _o !== void 0 ? _o : [] });
        case SearchDispatchActionType.SET_WHOLE_MONTH_START_DATE: {
            const startDate = new Date(dateValue || '');
            const lastday = dateValue ? toUTCDate(new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)) : '';
            return Object.assign(Object.assign({}, searchState), { wholeMonthStartDate: dateValue || '', wholeMonthEndDate: lastday || '' });
        }
        case SearchDispatchActionType.SET_DATE_SEARCH_TYPE:
            return Object.assign(Object.assign({}, searchState), { dateSearchType: dateSearchType || DateSearchType.ChooseDate });
        case SearchDispatchActionType.SET_PICKUP_POINT:
            return Object.assign(Object.assign({}, searchState), { pickUpPoint: pickDropPoint || undefined });
        case SearchDispatchActionType.SET_DROPOFF_POINT:
            return Object.assign(Object.assign({}, searchState), { dropOffPoint: pickDropPoint || undefined });
        case SearchDispatchActionType.SET_FLIGHT_JOURNEY_TYPE:
            return Object.assign(Object.assign({}, searchState), { flightJourneyType: flightJourneyType || FlightJourneyType.Return });
        case SearchDispatchActionType.SET_USER_ID:
            return Object.assign(Object.assign({}, searchState), { userId: userId !== null && userId !== void 0 ? userId : undefined });
        default:
            return searchState;
    }
};
