import { gql } from '@apollo/client';
import { initializeApollo } from '../../apollo';
export function useAirportOptionCache() {
    const client = initializeApollo();
    return (code) => {
        const cached = client.readFragment({
            id: client.cache.identify({ code, __typename: 'SingleAirport' }),
            fragment: gql `
                fragment airport on SingleAirport {
                    code
                    title
                    country
                }
            `,
        });
        return (cached || {
            code,
            country: '',
            title: code,
            __typename: 'SingleAirport',
        });
    };
}
