var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { DatePicker, DigitalClock, FlexGrid, Paper, RadioChip, RadioGroup, Styler, usePrevious, inDOM, } from '@honeycomb/ui-core';
const DATE_PICKER_EXPECTED_HEIGHT = 388;
export function DateTimeSelector(props) {
    const { value, onChange, referenceDate, timeViews, withTabs = false, ClockProps, defaultHours = 12, defaultMinutes = 0 } = props, other = __rest(props, ["value", "onChange", "referenceDate", "timeViews", "withTabs", "ClockProps", "defaultHours", "defaultMinutes"]);
    const isClient = inDOM();
    const [step, setStep] = React.useState('date');
    const prevValue = usePrevious(value);
    // The time picker container needs a fixed height or else it will just grow to the size of its contents.
    // We want to both containers to be the same height, however the height of the date picker container
    // changes in relation to the number of weeks that are displayed for the current month.
    // A resize obeserver watches for changes to the date picker container's size, and then sets a state variable
    // which is applied as a fixed height to the time picker.
    const datePickerRef = React.useRef(null);
    const [datePickerHeight, setDatePickerHeight] = React.useState(DATE_PICKER_EXPECTED_HEIGHT);
    const resizeObserverRef = React.useRef(null);
    const firstResizeRef = React.useRef(true);
    React.useEffect(() => {
        if (withTabs && prevValue && !value) {
            setStep('date');
        }
    }, [withTabs, prevValue, value]);
    const handleChange = React.useCallback((stepChanged) => (date) => {
        if (!value) {
            date === null || date === void 0 ? void 0 : date.setHours(defaultHours);
            date === null || date === void 0 ? void 0 : date.setMinutes(defaultMinutes);
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(date);
        if (withTabs && stepChanged === 'date') {
            // Auto switch to the time step
            setStep('time');
        }
    }, [value, onChange, withTabs, defaultHours, defaultMinutes]);
    const handleChangeStep = React.useCallback((event) => {
        setStep(event.currentTarget.value);
    }, []);
    const handleSyncHeights = React.useCallback(() => {
        const element = datePickerRef.current;
        if (!element) {
            return;
        }
        const setHeightReference = () => {
            const { height } = element.getBoundingClientRect();
            if (height > 0) {
                setDatePickerHeight(height);
            }
        };
        if (firstResizeRef.current) {
            // We are adding a delay to the first resize, otherwise parent popover animation
            // gives us an incorrect height.
            setTimeout(() => {
                setHeightReference();
            }, 500);
            firstResizeRef.current = false;
        }
        else {
            setHeightReference();
        }
    }, []);
    React.useEffect(() => {
        return () => {
            var _a;
            // on unmount
            (_a = resizeObserverRef.current) === null || _a === void 0 ? void 0 : _a.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleDatePickerRef = React.useCallback((element) => {
        if (!isClient || !element) {
            return;
        }
        // Observe changes to the datepicker size.
        resizeObserverRef.current = new ResizeObserver(handleSyncHeights);
        resizeObserverRef.current.observe(element);
        datePickerRef.current = element;
    }, [handleSyncHeights, isClient]);
    const datePicker = (React.createElement(DatePicker, Object.assign({ ref: handleDatePickerRef, mode: "single", numberOfMonths: 1, selectedDate: value !== null && value !== void 0 ? value : undefined, onSelectDate: handleChange('date'), preserveTime: true }, other)));
    const clock = (React.createElement(DigitalClock, Object.assign({ value: value, onChange: handleChange('time'), sectionHeadings: true }, ClockProps)));
    if (withTabs) {
        return (React.createElement(Styler, { w: 360 },
            React.createElement(Styler, { mb: 3 },
                React.createElement(RadioGroup, { name: "date-picker-step", value: step, onChange: handleChangeStep },
                    React.createElement(FlexGrid, { container: true, spacing: 3 },
                        React.createElement(FlexGrid, { xs: 6 },
                            React.createElement(RadioChip, { ChipProps: { size: 'm', fullWidth: true }, value: "date" }, "Date")),
                        React.createElement(FlexGrid, { xs: 6 },
                            React.createElement(RadioChip, { ChipProps: { size: 'm', fullWidth: true }, value: "time" }, "Time"))))),
            step === 'date' && datePicker,
            step === 'time' && (React.createElement(Paper, { radius: "s", bg: "background.content", overflow: "hidden", border: 0, h: datePickerHeight || undefined }, React.cloneElement(clock, {
                sectionPadding: 'wide',
                justifyContent: 'center',
            })))));
    }
    return (React.createElement(Styler, { display: "flex", gap: 3 },
        React.cloneElement(datePicker, {
            alignSelf: 'flex-start',
        }),
        React.createElement(Paper, { radius: "s", bg: "background.content", overflow: "hidden", border: 0, h: datePickerHeight || undefined }, clock)));
}
