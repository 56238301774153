import React, { useCallback, useState, useReducer, useEffect, useMemo } from 'react';
import { useDestinationAutocompleterResults, usePrevious, useRenderCount, useGlobalContext, useAirportAutocompleterResults, TripType, useCurrentOpenSearchField, SearchFieldEnum, usePageRouting, parseBoolean, Cabin, DateSearchType, AirportListType, FlightJourneyType, } from '@honeycomb/data';
import { Button, SearchIcon, useBreakpoints, animate, FlexGrid, Styler, SwitchControlLabel, Switch, } from '@honeycomb/ui-core';
import { trackFunnelAction, FunnelAction } from '@honeycomb/tracking';
import { DateTime } from 'luxon';
import dynamic from 'next/dynamic';
import { DestinationAutocompleter } from './holiday-hotel/DestinationAutocompleter';
import { RoomSelector } from './holiday-hotel/RoomSelector';
import { DateRangeSelector } from './holiday-hotel/DateRangeSelector';
import { SearchContext, SearchDisplayType } from './SearchContext';
import { SearchDispatchActionType, searchReducer } from './SearchReducer';
import { AirportAutocompleter } from './holiday-hotel/AirportAutocompleter';
import { CollapsedSearch } from './CollapsedSearch';
import { StickySearch } from './StickySearch';
import { PassengersSelector } from './flights/PassengersSelector';
import { getSearchDisplayType, getSearchError } from './SearchUtil';
import { FlightJourneySelector } from './flights/FlightJourneySelector';
import { SEARCH_FIELD_SPACING } from '../../utils/style-constants';
const popupPlacement = 'bottom-start';
const MultiSearchDestinationAutocompleter = dynamic(() => import('./holiday-hotel/MultiSearchDestinationAutocompleter').then((module) => module.MultiSearchDestinationAutocompleter), {
    ssr: false,
});
// This component now contains the logic for the search unit for hotel/flights/holidays funnels, The tabs logic has
// been pulled up to Search component to allow for flexibility in multiple forms of Search Units.
export function HotelHolidayFlightsSearchUnit({ state, action, tracking, collapsible, sticky, open = true, onOpenChange, showToggle = false, hotel, selectedTripType, searchElementRef, entry, onSearchErrorChange, }) {
    var _a, _b, _c, _d, _e, _f;
    const { isPageRouting } = usePageRouting();
    const { createSearch, loading, error } = action;
    const [isValidCheckInDate, setIsValidCheckInDate] = useState(true);
    useEffect(() => {
        setIsValidCheckInDate(!(error === null || error === void 0 ? void 0 : error.message.includes('checkin date validation failed')));
    }, [error]);
    const { resourceStrings: { SEARCHBUTTONTEXT, SEARCH_DEPARTURE_ERROR_EMPTY, SEARCH_ARRIVAL_ERROR_EMPTY, HC_FLIGHTS_SAMEAIRPORTMSG, HC_SEARCH_SELECTED_HOTEL, }, configSettings: { HcDisplaySearchUnit, HCSearchDuration, HCDestinationSearchToggle, HotelHolidayDefaultAdultsPerRoom, ProductCacheEnabled, }, } = useGlobalContext();
    const HCDestinationSearchToggleConfig = parseBoolean(HCDestinationSearchToggle);
    // !! DEV-Only !! Counts and prints number of renders for component
    useRenderCount('Search');
    // Set up internal state variables for search unit
    const [openField, setOpenField] = useCurrentOpenSearchField();
    const [searchState, searchDispatch] = useReducer(searchReducer, state);
    const [isEstabSearch, setIsEstabSearch] = useState(true);
    const prevSearchState = usePrevious(searchState);
    const [searchClicked, setSearchClicked] = useState(false);
    const userIdInLocalStorage = typeof window !== 'undefined' && window.localStorage.getItem('userId');
    const userId = userIdInLocalStorage ? JSON.parse(userIdInLocalStorage) : undefined;
    const { largeUp } = useBreakpoints();
    const isHoliday = selectedTripType === TripType.Holiday;
    const isFlight = selectedTripType === TripType.Flight;
    const displayType = getSearchDisplayType(largeUp);
    const defaultRoom = React.useMemo(() => ({
        adults: parseInt(HotelHolidayDefaultAdultsPerRoom),
        childAges: [],
    }), [HotelHolidayDefaultAdultsPerRoom]);
    const showEstabToggle = HCDestinationSearchToggleConfig &&
        (hotel === null || hotel === void 0 ? void 0 : hotel.estabId) &&
        (((state === null || state === void 0 ? void 0 : state.destinationIds) && state.destinationIds.length > 0) ||
            ((state === null || state === void 0 ? void 0 : state.destinations) && state.destinations.length > 1) ||
            ((state === null || state === void 0 ? void 0 : state.destinations) && state.destinations.length === 1 && state.destinations.every((d) => (d === null || d === void 0 ? void 0 : d.type) !== 1)));
    const onToggleChange = () => {
        setIsEstabSearch(!isEstabSearch);
    };
    // Set up validity tracking variables
    const [destinationError, setDestinationError] = useState(false);
    const [dateRangeError, setDateRangeError] = useState(false);
    const [occupancyError, setOccupancyError] = useState(false);
    const [airportError, setAirportError] = useState(false);
    const [arrivalAirportError, setArrivalAirportError] = useState(false);
    const [passengersError, setPassengersError] = useState(false);
    const isMultiSearch = parseBoolean(ProductCacheEnabled);
    const [checkInDateError, setCheckInDateError] = useState(false);
    const validDestination = Boolean(((_a = searchState.destinationIds) === null || _a === void 0 ? void 0 : _a.length) ||
        (!isMultiSearch && ((_b = searchState.hotelIds) === null || _b === void 0 ? void 0 : _b.length)) ||
        ((_c = searchState.destinations) === null || _c === void 0 ? void 0 : _c.length));
    const isOneWayFlightSearch = (searchState === null || searchState === void 0 ? void 0 : searchState.flightJourneyType) === FlightJourneyType.Oneway && selectedTripType === TripType.Flight;
    const validDateRangeSearch = searchState.dateSearchType === DateSearchType.ChooseDate
        ? !!(searchState.startDate && searchState.endDate)
        : !!(searchState.wholeMonthStartDate && searchState.wholeMonthEndDate);
    const validDateRangeFlightOneWaySearch = searchState.dateSearchType === DateSearchType.ChooseDate
        ? !!searchState.startDate
        : !!searchState.wholeMonthStartDate;
    const validDateRange = isOneWayFlightSearch ? validDateRangeFlightOneWaySearch : validDateRangeSearch;
    const validAirport = !!(searchState.departureAirports && ((_d = searchState.departureAirports) === null || _d === void 0 ? void 0 : _d.length) > 0);
    const hasArrivalAirportsOverlap = (_e = searchState.arrivalAirports) === null || _e === void 0 ? void 0 : _e.some((a) => { var _a; return (_a = searchState.departureAirports) === null || _a === void 0 ? void 0 : _a.includes(a); });
    const validArrivalAirport = !!(searchState.arrivalAirports &&
        ((_f = searchState.arrivalAirports) === null || _f === void 0 ? void 0 : _f.length) > 0 &&
        !hasArrivalAirportsOverlap);
    const validOccupancy = !!searchState.rooms.every((room) => room.adults > 0 && room.childAges.every((age) => age >= 0));
    const validPassengers = !!searchState.adults &&
        !!searchState.childAges &&
        searchState.adults > 0 &&
        searchState.childAges.every((age) => age >= 0);
    const fieldValidity = useMemo(() => ({
        [SearchFieldEnum.DESTINATION]: { valid: validDestination, error: destinationError },
        [SearchFieldEnum.DATES]: { valid: validDateRange, error: dateRangeError },
        [SearchFieldEnum.CHECKIN_DATE]: { valid: !checkInDateError, error: checkInDateError },
        [SearchFieldEnum.ROOMS]: { valid: validOccupancy, error: occupancyError },
        [SearchFieldEnum.AIRPORTS]: { valid: validAirport, error: airportError },
        [SearchFieldEnum.ARRIVAL_AIRPORTS]: { valid: validArrivalAirport, error: arrivalAirportError },
        [SearchFieldEnum.PASSENGERS]: { valid: validPassengers, error: passengersError },
    }), [
        dateRangeError,
        destinationError,
        occupancyError,
        airportError,
        validDateRange,
        validDestination,
        validOccupancy,
        validAirport,
        arrivalAirportError,
        validArrivalAirport,
        validPassengers,
        passengersError,
        checkInDateError,
    ]);
    const searchError = getSearchError(fieldValidity);
    useEffect(() => {
        onSearchErrorChange(searchError);
    }, [onSearchErrorChange, searchError]);
    // Get the necessary details for the destination autocompleter field
    const [fetchMoreDestinations, destinationAutocompleterResults] = useDestinationAutocompleterResults();
    // Get the necessary details for airport picker field
    const [fetchDepartureAirportResults, departureAirportAutocompleterResults] = useAirportAutocompleterResults();
    const [fetchArrivalAirportResults, arrivalAirportAutocompleterResults] = useAirportAutocompleterResults();
    const fetchDestinations = useCallback((term) => {
        return fetchMoreDestinations({
            searchTerm: term,
            // TODO: move this number to a config setting
            maxResults: 15,
            holidaySearch: isHoliday,
        });
    }, [fetchMoreDestinations, isHoliday]);
    const fetchArrivalAirports = useCallback((term) => {
        return fetchArrivalAirportResults({
            searchTerm: term,
            airportList: AirportListType.Arrival,
        });
    }, [fetchArrivalAirportResults]);
    const fetchDepartureAirports = useCallback((term) => {
        return fetchDepartureAirportResults({
            searchTerm: term,
            airportList: AirportListType.Departure,
        });
    }, [fetchDepartureAirportResults]);
    useEffect(() => {
        if (selectedTripType !== searchState.tripType) {
            searchDispatch({
                type: SearchDispatchActionType.SET_TRIP_TYPE,
                tripType: selectedTripType,
            });
        }
    }, [selectedTripType, searchState.tripType]);
    // Set up necessary functions for search unit
    const handleOpen = useCallback((field) => {
        if (displayType === SearchDisplayType.POPUP) {
            // Scroll to the search element, then open the field.
            if (searchElementRef.current) {
                const rect = searchElementRef.current.getBoundingClientRect();
                const position = rect.top + document.documentElement.scrollTop;
                animate('scrollTop', document.documentElement, position, {}, () => {
                    setOpenField(field || SearchFieldEnum.NONE);
                });
            }
            return;
        }
        setOpenField(field || SearchFieldEnum.NONE);
    }, [displayType, searchElementRef, setOpenField]);
    const handleClose = useCallback(() => {
        setOpenField(SearchFieldEnum.NONE);
    }, [setOpenField]);
    const openNextField = useCallback(() => {
        if (!isFlight && !validDestination) {
            setOpenField(SearchFieldEnum.DESTINATION);
            return;
        }
        if ((isHoliday || isFlight) && !validAirport) {
            setOpenField(SearchFieldEnum.AIRPORTS);
            return;
        }
        if (isFlight && !validArrivalAirport) {
            setOpenField(SearchFieldEnum.ARRIVAL_AIRPORTS);
            return;
        }
        if (!validDateRange) {
            setOpenField(SearchFieldEnum.DATES);
            return;
        }
        if (!isFlight && !validOccupancy) {
            setOpenField(SearchFieldEnum.ROOMS);
            return;
        }
        if (isFlight && !validPassengers) {
            setOpenField(SearchFieldEnum.PASSENGERS);
            return;
        }
        setOpenField(SearchFieldEnum.NONE);
    }, [
        validDestination,
        isHoliday,
        isFlight,
        validAirport,
        validDateRange,
        validArrivalAirport,
        validOccupancy,
        setOpenField,
        validPassengers,
    ]);
    const onSearchClick = useCallback((e) => {
        e.stopPropagation();
        if ((isFlight && validAirport && validDateRange && validArrivalAirport && validPassengers) ||
            (!isHoliday && validDestination && validDateRange && validOccupancy && !checkInDateError) ||
            (isHoliday && validDestination && validAirport && validDateRange && validOccupancy && !checkInDateError)) {
            setCheckInDateError(false);
            if (setIsValidCheckInDate)
                setIsValidCheckInDate(true);
            createSearch(searchState);
            setOpenField(SearchFieldEnum.NONE);
        }
        else {
            setOccupancyError(!validOccupancy);
            setDestinationError(!validDestination);
            setDateRangeError(!validDateRange);
            if (isHoliday || isFlight) {
                setAirportError(!validAirport);
            }
            if (isFlight) {
                setArrivalAirportError(!validArrivalAirport);
                setPassengersError(!validPassengers);
            }
            openNextField();
            setSearchClicked(true);
        }
        trackFunnelAction(FunnelAction.SEARCH, selectedTripType, {
            destination: searchState.destinations,
            daysToDeparture: Math.ceil(DateTime.fromISO(searchState.startDate).diff(DateTime.now(), 'days').as('days')),
        });
    }, [
        createSearch,
        isFlight,
        isHoliday,
        openNextField,
        searchState,
        selectedTripType,
        setOpenField,
        validAirport,
        validArrivalAirport,
        validDateRange,
        validDestination,
        validOccupancy,
        validPassengers,
        setIsValidCheckInDate,
        checkInDateError,
    ]);
    // Effect to be called on destination field change
    useEffect(() => {
        if (validDestination && destinationError) {
            setDestinationError(false);
        }
    }, [validDestination, destinationError]);
    // Effect to be called on airport field change
    useEffect(() => {
        if (validAirport &&
            JSON.stringify(prevSearchState === null || prevSearchState === void 0 ? void 0 : prevSearchState.departureAirports) !== JSON.stringify(searchState.departureAirports)) {
            if (airportError) {
                setAirportError(false);
            }
        }
    }, [airportError, prevSearchState === null || prevSearchState === void 0 ? void 0 : prevSearchState.departureAirports, searchState.departureAirports, validAirport]);
    // Effect to be called on Arrival airport field change
    useEffect(() => {
        if (validArrivalAirport &&
            JSON.stringify(prevSearchState === null || prevSearchState === void 0 ? void 0 : prevSearchState.arrivalAirports) !== JSON.stringify(searchState.arrivalAirports)) {
            if (arrivalAirportError) {
                setArrivalAirportError(false);
            }
        }
    }, [arrivalAirportError, prevSearchState === null || prevSearchState === void 0 ? void 0 : prevSearchState.arrivalAirports, searchState.arrivalAirports, validArrivalAirport]);
    // Effect to be called on date range field change
    useEffect(() => {
        if (dateRangeError && ((prevSearchState === null || prevSearchState === void 0 ? void 0 : prevSearchState.startDate) !== searchState.startDate || validDateRange)) {
            setDateRangeError(false);
        }
    }, [dateRangeError, prevSearchState === null || prevSearchState === void 0 ? void 0 : prevSearchState.startDate, searchState.startDate, validDateRange]);
    // Effect to be called on rooms field change
    useEffect(() => {
        if (occupancyError && validOccupancy) {
            setOccupancyError(false);
        }
    }, [occupancyError, validOccupancy]);
    // Effect to be called on Passengers field change
    useEffect(() => {
        if (passengersError && validPassengers) {
            setPassengersError(false);
        }
    }, [passengersError, validPassengers]);
    // Set up context for children components to utilise
    const context = React.useMemo(() => ({
        openField,
        fieldValidity,
        onOpen: handleOpen,
        onClose: handleClose,
        searchDispatch,
        searchState,
        displayType,
        searchError,
    }), [openField, fieldValidity, handleOpen, handleClose, searchState, displayType, searchError]);
    // Effect to be called when the global state updates
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        if (isMultiSearch && (hotel === null || hotel === void 0 ? void 0 : hotel.estabId)) {
            searchDispatch({
                type: SearchDispatchActionType.SET_INITIAL_DESTINATIONS,
                destinations: [
                    {
                        id: Number(hotel === null || hotel === void 0 ? void 0 : hotel.estabId) || 0,
                        isCached: Boolean(hotel === null || hotel === void 0 ? void 0 : hotel.isCached),
                        title: (hotel === null || hotel === void 0 ? void 0 : hotel.name) || '',
                        subTitle: `${(_a = hotel === null || hotel === void 0 ? void 0 : hotel.geography) === null || _a === void 0 ? void 0 : _a.location}, ${(_b = hotel === null || hotel === void 0 ? void 0 : hotel.geography) === null || _b === void 0 ? void 0 : _b.province}, ${(_c = hotel === null || hotel === void 0 ? void 0 : hotel.geography) === null || _c === void 0 ? void 0 : _c.country}`,
                        type: 1,
                    },
                ],
            });
        }
        else {
            searchDispatch({
                type: SearchDispatchActionType.SET_DESTINATION_ID,
                destinationIdValue: (_d = (!showToggle || (showToggle && !isEstabSearch) ? state.destinationIds : undefined)) !== null && _d !== void 0 ? _d : undefined,
            });
            searchDispatch({
                type: SearchDispatchActionType.SET_HOTEL_ID,
                destinationIdValue: (isEstabSearch && ((state === null || state === void 0 ? void 0 : state.hotelIds) || (hotel && [parseInt(hotel === null || hotel === void 0 ? void 0 : hotel.estabId)]))) || undefined,
            });
            searchDispatch({
                type: SearchDispatchActionType.SET_DESTINATION_TITLE,
                destinationTitleValue: (!showToggle || (showToggle && !isEstabSearch)
                    ? (state === null || state === void 0 ? void 0 : state.hotelTitle) || (state === null || state === void 0 ? void 0 : state.destinationTitle)
                    : undefined),
            });
            searchDispatch({
                type: SearchDispatchActionType.SET_HOTEL_TITLE,
                destinationTitleValue: (isEstabSearch && ((state === null || state === void 0 ? void 0 : state.hotelTitle) || (hotel === null || hotel === void 0 ? void 0 : hotel.name))) || undefined,
            });
        }
        if (!isMultiSearch && ((_e = state.destinations) === null || _e === void 0 ? void 0 : _e.length)) {
            searchDispatch({
                type: SearchDispatchActionType.SET_HOTEL_ID,
                destinationIdValue: [((_g = (_f = state.destinations) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.id) || 0],
            });
            searchDispatch({
                type: SearchDispatchActionType.SET_HOTEL_TITLE,
                destinationTitleValue: showToggle && isEstabSearch ? hotel === null || hotel === void 0 ? void 0 : hotel.name : (_j = (_h = state.destinations) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.title,
            });
        }
        searchDispatch({
            type: SearchDispatchActionType.SET_START_DATE,
            dateValue: state.duration ? '' : state.startDate,
        });
        searchDispatch({
            type: SearchDispatchActionType.SET_FLIGHT_JOURNEY_TYPE,
            flightJourneyType: (_k = state.flightJourneyType) !== null && _k !== void 0 ? _k : FlightJourneyType.Return,
        });
        searchDispatch({ type: SearchDispatchActionType.SET_END_DATE, dateValue: state.duration ? '' : state.endDate });
        searchDispatch({ type: SearchDispatchActionType.SET_FLEXIBLE_DAYS, flexibleDays: state.flexibleDays || 0 });
        searchDispatch({
            type: SearchDispatchActionType.SET_DURATION,
            duration: state.duration || parseInt(HCSearchDuration),
        });
        searchDispatch({
            type: SearchDispatchActionType.SET_DATE_SEARCH_TYPE,
            dateSearchType: state.duration ? DateSearchType.ChooseMonth : DateSearchType.ChooseDate,
        });
        searchDispatch({
            type: SearchDispatchActionType.SET_WHOLE_MONTH_START_DATE,
            dateValue: state.duration ? state.wholeMonthStartDate : '',
        });
        searchDispatch({
            type: SearchDispatchActionType.SET_ROOMS,
            roomsValue: state.rooms.length ? state.rooms : [defaultRoom],
        });
        searchDispatch({
            type: SearchDispatchActionType.SET_DEPARTURE_AIRPORTS,
            departureAirports: state.departureAirports || [],
        });
        searchDispatch({
            type: SearchDispatchActionType.SET_ARRIVAL_AIRPORTS,
            arrivalAirports: state.arrivalAirports || [],
        });
        searchDispatch({
            type: SearchDispatchActionType.SET_ADULTS,
            adultsValue: state.adults || 2,
        });
        searchDispatch({
            type: SearchDispatchActionType.SET_CHILDAGES,
            childAges: state.childAges || [],
        });
        searchDispatch({
            type: SearchDispatchActionType.SET_CABIN_CLASS,
            cabinValue: (_l = state.cabin) !== null && _l !== void 0 ? _l : Cabin.Economy,
        });
        if (userId) {
            searchDispatch({
                type: SearchDispatchActionType.SET_USER_ID,
                userId,
            });
        }
        if (state.destinations && ((_m = state.destinations) === null || _m === void 0 ? void 0 : _m.length) > 0)
            searchDispatch({
                type: SearchDispatchActionType.SET_INITIAL_DESTINATIONS,
                destinations: isEstabSearch && showToggle
                    ? [
                        {
                            id: Number(hotel === null || hotel === void 0 ? void 0 : hotel.estabId) || 0,
                            isCached: Boolean(hotel === null || hotel === void 0 ? void 0 : hotel.isCached),
                            title: (hotel === null || hotel === void 0 ? void 0 : hotel.name) || '',
                            subTitle: `${(_o = hotel === null || hotel === void 0 ? void 0 : hotel.geography) === null || _o === void 0 ? void 0 : _o.location}, ${(_p = hotel === null || hotel === void 0 ? void 0 : hotel.geography) === null || _p === void 0 ? void 0 : _p.province}, ${(_q = hotel === null || hotel === void 0 ? void 0 : hotel.geography) === null || _q === void 0 ? void 0 : _q.country}`,
                            type: 1,
                        },
                    ]
                    : state.destinations,
            });
    }, [state, isEstabSearch, hotel, showToggle, isMultiSearch, HCSearchDuration, defaultRoom, userId]);
    useEffect(() => {
        if (!searchState.tripStateId) {
            searchDispatch({
                type: SearchDispatchActionType.SET_ROOMS,
                roomsValue: [defaultRoom],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultRoom, searchState.tripStateId]);
    if (isOneWayFlightSearch && (searchState === null || searchState === void 0 ? void 0 : searchState.endDate)) {
        searchDispatch({
            type: SearchDispatchActionType.SET_END_DATE,
            dateValue: '',
        });
    }
    // Update GTM dataLayer when searchState changes
    useEffect(() => {
        if (tracking) {
            tracking(searchState);
        }
    }, [searchState, tracking]);
    useEffect(() => {
        if (!isValidCheckInDate)
            setCheckInDateError(true);
    }, [isValidCheckInDate]);
    const onDateRangeFieldFocused = useCallback(() => {
        // For flight funnel ensure valid airports before opening date selector,
        // For other funnels ensure valid destinations before opening date selector.
        if (isFlight) {
            if (!validAirport) {
                handleOpen(SearchFieldEnum.NONE);
                setAirportError(true);
            }
            else {
                setAirportError(false);
            }
        }
        else if (!validDestination) {
            handleOpen(SearchFieldEnum.NONE);
            setDestinationError(true);
        }
        else {
            setDestinationError(false);
        }
    }, [handleOpen, isFlight, validAirport, validDestination]);
    useEffect(() => {
        setCheckInDateError(!isValidCheckInDate &&
            searchState.startDate.length > 0 &&
            new Date().toLocaleDateString() === new Date(searchState.startDate).toLocaleDateString());
    }, [searchState.startDate, isValidCheckInDate]);
    if (parseBoolean(HcDisplaySearchUnit) === false) {
        return null;
    }
    const fullSearch = () => {
        var _a;
        return (React.createElement(React.Fragment, null,
            showEstabToggle && (React.createElement(Styler, { mb: 3 },
                React.createElement(SwitchControlLabel, { control: React.createElement(Switch, { id: "search-hotel-switch", onChange: onToggleChange, checked: isEstabSearch, size: "s" }), label: HC_SEARCH_SELECTED_HOTEL, placement: "start", LabelProps: { size: 's' }, "data-id": "search_selected_hotel" }))),
            isFlight && (React.createElement(Styler, { mb: 1 },
                React.createElement(FlightJourneySelector, null))),
            React.createElement(FlexGrid, { container: true, alignItems: "flex-start", spacing: SEARCH_FIELD_SPACING },
                !isFlight && (React.createElement(FlexGrid, { xs: 12, m: isHoliday ? 6 : 12, l: "fill" }, isMultiSearch ? (React.createElement(MultiSearchDestinationAutocompleter, { state: Object.assign(Object.assign({}, destinationAutocompleterResults), { popupPlacement,
                        searchClicked }), actions: {
                        fetchData: fetchDestinations,
                        onDone: openNextField,
                        setSearchClicked,
                    } })) : (React.createElement(DestinationAutocompleter, { state: Object.assign(Object.assign({}, destinationAutocompleterResults), { popupPlacement,
                        searchClicked }), actions: {
                        fetchData: fetchDestinations,
                        onDone: openNextField,
                        setSearchClicked,
                    } })))),
                (isHoliday || isFlight) && (React.createElement(FlexGrid, { xs: 12, m: isHoliday ? 6 : 12, l: "fill" },
                    React.createElement(AirportAutocompleter, { state: Object.assign(Object.assign({}, departureAirportAutocompleterResults), { popupPlacement,
                            searchClicked, fieldListId: 'airport-autocompleter-list', fieldId: 'airport-autocompleter-field', openAirport: SearchFieldEnum.AIRPORTS, fieldErrorMessage: SEARCH_DEPARTURE_ERROR_EMPTY }), actions: {
                            fetchData: fetchDepartureAirports,
                            onDone: openNextField,
                            setSearchClicked,
                        } }))),
                isFlight && (React.createElement(FlexGrid, { xs: 12, m: isHoliday ? 6 : 12, l: "fill" },
                    React.createElement(AirportAutocompleter, { state: Object.assign(Object.assign({}, arrivalAirportAutocompleterResults), { popupPlacement,
                            searchClicked, fieldListId: 'arrival-airport-autocompleter-list', fieldId: 'arrival-airport-autocompleter-field', openAirport: SearchFieldEnum.ARRIVAL_AIRPORTS, isMultiSelect: false, fieldErrorMessage: hasArrivalAirportsOverlap
                                ? HC_FLIGHTS_SAMEAIRPORTMSG
                                : SEARCH_ARRIVAL_ERROR_EMPTY }), actions: {
                            fetchData: fetchArrivalAirports,
                            onDone: openNextField,
                            setSearchClicked,
                        } }))),
                React.createElement(FlexGrid, { xs: 12, m: 6, l: "fill" },
                    React.createElement(DateRangeSelector, { state: {
                            popupPlacement: largeUp ? 'bottom' : popupPlacement,
                            selectedTripType,
                            isCached: Boolean((_a = searchState.destinations) === null || _a === void 0 ? void 0 : _a.every((d) => d === null || d === void 0 ? void 0 : d.isCached)),
                        }, actions: {
                            onDone: openNextField,
                            onFieldFocused: onDateRangeFieldFocused,
                        } })),
                React.createElement(FlexGrid, { xs: 12, m: "fill" }, isFlight ? (React.createElement(PassengersSelector, { state: {
                        popupPlacement: 'bottom-end',
                    }, actions: {
                        onDone: openNextField,
                    } })) : (React.createElement(RoomSelector, { state: {
                        popupPlacement: 'bottom-end',
                        isHoliday,
                    }, actions: {
                        onDone: openNextField,
                    } }))),
                React.createElement(FlexGrid, { xs: 12, m: "auto" },
                    React.createElement(Button, { icon: React.createElement(SearchIcon, null), fullWidth: true, color: "callToAction", size: { xs: 'xl', m: '2xl' }, "data-id": "search-button", onClick: onSearchClick, loading: loading || isPageRouting }, SEARCHBUTTONTEXT)))));
    };
    return (React.createElement(SearchContext.Provider, { value: context }, selectedTripType !== TripType.Transfer && (React.createElement(React.Fragment, null,
        !collapsible && fullSearch(),
        collapsible && !open && (React.createElement(CollapsedSearch, { tripType: selectedTripType, loading: loading, onClick: () => onOpenChange === null || onOpenChange === void 0 ? void 0 : onOpenChange(true) })),
        collapsible && open && fullSearch(),
        sticky && (React.createElement(StickySearch, { searchRef: searchElementRef, inView: (entry === null || entry === void 0 ? void 0 : entry.isIntersecting) || false, onClick: () => onOpenChange === null || onOpenChange === void 0 ? void 0 : onOpenChange(true) },
            React.createElement(CollapsedSearch, { tripType: selectedTripType, loading: loading, stuck: true })))))));
}
