import React, { createContext, useEffect, useState } from 'react';
import { AnywhereSearchResultEnum, getLastSearch, getLastViewedEstab, TripType, useGlobalContext, useRecentSearchResults, useSearch, } from '@honeycomb/data';
import { DateTime } from 'luxon';
import { parseBoolean, useMountEffect } from '../../utils';
export const PersonalisedProductContext = createContext({
    hotels: undefined,
    loading: false,
    isPersonalisedProduct: false,
});
export function usePersonalisedProduct() {
    return React.useContext(PersonalisedProductContext);
}
export function PersonalisedProductContextProvider({ alias, children }) {
    var _a, _b;
    const { configSettings: { HCEnablePersonalisedProduct, HCPersonalisedProductCount }, } = useGlobalContext();
    const [loading, setLoading] = useState(false);
    const productCount = Number(HCPersonalisedProductCount);
    const [getRecentSearchResults, recentSearchResults] = useRecentSearchResults();
    const { loading: searchLoading, data } = recentSearchResults;
    const hotels = (_a = data === null || data === void 0 ? void 0 : data.hotelSearchResults) === null || _a === void 0 ? void 0 : _a.hotels;
    const [_hotelSearch, setSearch] = useSearch([
        (response) => {
            if (response === null || response === void 0 ? void 0 : response.tripStateId)
                getRecentSearchResults(response === null || response === void 0 ? void 0 : response.tripStateId, 6);
        },
    ]);
    const { createSearch, loading: createSearchLoading } = setSearch;
    useEffect(() => {
        setLoading(searchLoading || createSearchLoading);
    }, [searchLoading, createSearchLoading]);
    useMountEffect(() => {
        var _a, _b;
        if (alias === 'personalisedProductWidget' && parseBoolean(HCEnablePersonalisedProduct)) {
            const searchData = getLastSearch();
            let searchDetails = {};
            try {
                searchDetails = JSON.parse(searchData || '{}');
            }
            catch (error) {
                console.error('Error parsing search data:', error);
                return;
            }
            const today = DateTime.fromISO(new Date().toISOString(), { zone: 'UTC' }).startOf('day');
            const startDate = searchDetails.startDate || searchDetails.wholeMonthStartDate || '';
            if (startDate &&
                ((_b = (_a = searchDetails.destinations) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) !== AnywhereSearchResultEnum.ANYWHERE_ID &&
                Date.parse(startDate) > Date.parse(today.toString()) &&
                (searchDetails.tripType === TripType.Holiday || searchDetails.tripType === TripType.Hotel))
                createSearch(searchDetails);
        }
    });
    const estabData = getLastViewedEstab();
    const estabDetails = JSON.parse(estabData || '{}');
    const contextValue = React.useMemo(() => ({
        hotels: hotels === null || hotels === void 0 ? void 0 : hotels.filter((h) => !h.pricingSummary.isSponsored && String(estabDetails === null || estabDetails === void 0 ? void 0 : estabDetails.estabId) !== h.hotel.estabId).slice(0, productCount),
        loading,
        isPersonalisedProduct: alias === 'personalisedProductWidget',
    }), [estabDetails === null || estabDetails === void 0 ? void 0 : estabDetails.estabId, hotels, productCount, loading, alias]);
    if (alias === 'personalisedProductWidget' && ((_b = contextValue.hotels) === null || _b === void 0 ? void 0 : _b.length) !== productCount && !contextValue.loading)
        return null;
    return React.createElement(PersonalisedProductContext.Provider, { value: contextValue }, children);
}
