import React, { useCallback, useMemo } from 'react';
import { useGlobalContext, useRenderCount, useAirportOptionCache, SearchFieldEnum, } from '@honeycomb/data';
import { Autocompleter, AutocompleterCategory, AutocompleterField, AutocompleterList, AutocompleterOption, AutocompleterResults, ButtonField, LocationIcon, PlaneTakeOffIcon, SearchIcon, Anchor, Chip, Stacker, Styler, ScrollArea, PopoverAnchor, } from '@honeycomb/ui-core';
import { getSelectedOptionSummary } from '../../../utils/getSelectedOptionSummary';
import { SearchDisplayType, useSearchContext } from '../SearchContext';
import { SearchDispatchActionType } from '../SearchReducer';
import { FieldDialog } from '../components/FieldDialog';
import { AutocompleterSkeleton } from '../components/AutocompleterSkeleton';
import { AutocompleterNoMatches } from '../components/AutocompleterNoMatches';
import { FieldErrorMessage } from '../components/FieldErrorMessage';
import { pluraliseCount } from '../../../utils/pluraliseCount';
import { SearchFieldPopover } from '../components/SearchFieldPopover/SearchFieldPopover';
import { SearchFieldPopoverPanel } from '../components/SearchFieldPopover/SearchFieldPopoverPanel';
const flattenAutocompleterValue = (value) => {
    if (Array.isArray(value)) {
        // Type safe cast here as types sufficiently overlap
        return value.reduce((acc, el) => (Array.isArray(el) ? [...acc, ...el] : [...acc, el]), []);
    }
    return [value];
};
const renderSingleOption = (option, inset) => {
    const { code, title, country } = option;
    return (React.createElement(AutocompleterOption, { key: `option-${code}`, id: `option-${code}`, value: code, title: title, subtitle: country, appendix: code, inset: inset, icon: React.createElement(PlaneTakeOffIcon, { id: `flight_icon_${code}` }) }));
};
const renderGroupOption = (option) => {
    const { country, title, codes, subAirports } = option;
    const groupSubAirports = subAirports === null || subAirports === void 0 ? void 0 : subAirports.filter((airport) => !!airport);
    const children = groupSubAirports.map((airport) => renderSingleOption(airport, true)) || [];
    if (children.length === 0) {
        return null;
    }
    return (React.createElement(AutocompleterOption, { key: `option-${codes === null || codes === void 0 ? void 0 : codes.join(':')}`, id: `option-${codes === null || codes === void 0 ? void 0 : codes.join(':')}`, value: codes || [], title: title, subtitle: country, appendix: "", inset: false, icon: React.createElement(LocationIcon, { id: `map_icon_${(codes || []).join('_')}` }) },
        React.createElement(AutocompleterList, { subList: true }, children)));
};
export function AirportAutocompleter({ state, actions }) {
    var _a, _b, _c, _d;
    const { data, previousData, loading, searchClicked, error: errorProp, popupPlacement = 'bottom-start', called, fieldListId, fieldId, openAirport, fieldErrorMessage = '', isMultiSelect = true, } = state;
    const autocompleterFieldId = fieldId;
    const autocompleterListId = fieldListId;
    const dialogAutocompleterFieldId = `${autocompleterFieldId}-dialog`;
    const { fetchData, onDone, setSearchClicked } = actions;
    const { openField, onOpen, onClose, displayType, searchDispatch, searchState, fieldValidity, searchError } = useSearchContext();
    const getAirportOptionCache = useAirportOptionCache();
    const { resourceStrings: { SEARCH_DEPARTURE_FIELD_LABEL, SEARCH_ARRIVAL_FIELD_LABEL, SEARCH_AIRPORTS_FIELD_PLACEHOLDER, SEARCH_DEPARTURE_OPTIONS_TITLE, SEARCH_ARRIVAL_OPTIONS_TITLE, RESULT_COUNT_MESSAGE, RESULTS_COUNT_MESSAGE, HC_CLEAR_ALL, }, } = useGlobalContext();
    // DEV ONLY: Count the number of renders of the component
    useRenderCount('AirportAutoCompleter');
    const departure = openAirport === SearchFieldEnum.AIRPORTS;
    const arrival = openAirport === SearchFieldEnum.ARRIVAL_AIRPORTS;
    const error = ((_a = fieldValidity[SearchFieldEnum.AIRPORTS]) === null || _a === void 0 ? void 0 : _a.error) || false;
    const errorArrival = ((_b = fieldValidity[SearchFieldEnum.ARRIVAL_AIRPORTS]) === null || _b === void 0 ? void 0 : _b.error) || false;
    const open = openField === openAirport;
    const airportCodes = departure ? searchState.departureAirports : searchState.arrivalAirports;
    const selectedAirportCodes = (airportCodes || []).filter((code) => !!code);
    const selectedAirportOptions = selectedAirportCodes.map((code) => getAirportOptionCache(code));
    const summary = useMemo(() => getSelectedOptionSummary(selectedAirportOptions), [selectedAirportOptions]);
    const allAirportOptions = loading && previousData
        ? ((_c = previousData === null || previousData === void 0 ? void 0 : previousData.airportAutocompleterResults) === null || _c === void 0 ? void 0 : _c.airports) || []
        : ((_d = data === null || data === void 0 ? void 0 : data.airportAutocompleterResults) === null || _d === void 0 ? void 0 : _d.airports) || [];
    const totalAirports = allAirportOptions.reduce((acc, option) => {
        var _a, _b;
        return acc + ('subAirports' in option ? (_b = (_a = option.subAirports) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0 : 1);
    }, 0);
    const [searchTerm, setSearchTerm] = React.useState('');
    const popup = displayType === SearchDisplayType.POPUP;
    const dialog = displayType === SearchDisplayType.DIALOG;
    const [scrollingPopoverElement, setScrollingPopoverElement] = React.useState(null);
    const airportsSelected = selectedAirportCodes.length > 0;
    const withSearchTerm = searchTerm !== '';
    const handleFieldChange = (event) => {
        const { value } = event.currentTarget;
        setSearchTerm(value);
        setSearchClicked(false);
    };
    const handleOpen = useCallback(() => {
        onOpen(openAirport);
        setSearchTerm('');
    }, [onOpen, openAirport]);
    const resultsChildren = [];
    if (!loading && allAirportOptions.length > 0) {
        const id = 'category-airports';
        resultsChildren.push(React.createElement(AutocompleterCategory, { title: departure ? SEARCH_DEPARTURE_OPTIONS_TITLE : SEARCH_ARRIVAL_OPTIONS_TITLE, appendix: pluraliseCount(totalAirports, {
                singular: RESULT_COUNT_MESSAGE,
                plural: RESULTS_COUNT_MESSAGE,
            }), id: id, key: id },
            React.createElement(AutocompleterList, { labelledById: id }, allAirportOptions.reduce((acc, option) => {
                if ('subAirports' in option) {
                    const group = renderGroupOption(option);
                    return group ? [...acc, group] : acc;
                }
                if ('code' in option) {
                    return [...acc, renderSingleOption(option, false)];
                }
                return acc;
            }, []))));
    }
    function onAirportRemove(value) {
        if (departure) {
            searchDispatch({
                type: SearchDispatchActionType.REMOVE_DEPARTURE_AIRPORTS,
                departureAirports: flattenAutocompleterValue(value),
            });
        }
        else if (arrival) {
            searchDispatch({
                type: SearchDispatchActionType.REMOVE_ARRIVAL_AIRPORTS,
                arrivalAirports: flattenAutocompleterValue(value),
            });
        }
    }
    const handleClear = () => {
        if (departure) {
            searchDispatch({
                type: SearchDispatchActionType.REMOVE_ALL_DEPARTURE_AIRPORTS,
            });
        }
        else if (arrival) {
            searchDispatch({
                type: SearchDispatchActionType.REMOVE_ALL_ARRIVAL_AIRPORTS,
            });
        }
    };
    const selectedOptionPills = (withClear, inHeader) => {
        return (React.createElement(Styler, { pos: "relative", display: "flex" },
            React.createElement(ScrollArea, { flex: "1 1 auto" },
                React.createElement(Stacker, { spacing: 2, direction: "row", alignItems: "center" }, selectedAirportOptions === null || selectedAirportOptions === void 0 ? void 0 : selectedAirportOptions.map((item) => (React.createElement(Chip, { size: "xs", color: inHeader ? 'neutral' : 'white', onDismiss: () => onAirportRemove(item.code), key: item === null || item === void 0 ? void 0 : item.code, "data-id": "airport-autocomp-pill" }, item === null || item === void 0 ? void 0 : item.code))).reverse())),
            withClear && (React.createElement(Styler, { display: "flex", alignItems: "center", flex: "0 1 auto", bg: inHeader ? undefined : 'background.offset', pl: 3, zIndex: 1 },
                React.createElement(Anchor, { button: true, onClick: handleClear, size: "xs", weight: "semiBold", truncate: true, "data-id": "airport-autocomp-clear" }, HC_CLEAR_ALL)))));
    };
    const autocompleterResults = (React.createElement(React.Fragment, null,
        !dialog && selectedAirportOptions.length > 0 && (React.createElement(Styler, { mt: 1, mb: 3 }, selectedOptionPills(true, false))),
        React.createElement(AutocompleterResults, { id: autocompleterListId, label: "Suggest airports" }, resultsChildren),
        !called || loading ? (React.createElement(AutocompleterSkeleton, { multiSelect: true })) : (allAirportOptions.length === 0 && React.createElement(AutocompleterNoMatches, null))));
    const commonFieldProps = {
        fullWidth: true,
        error: departure ? error : errorArrival,
        variant: 'alternative',
    };
    function onAirportAdd(value) {
        if (departure) {
            searchDispatch({
                type: SearchDispatchActionType.ADD_DEPARTURE_AIRPORTS,
                departureAirports: flattenAutocompleterValue(value),
            });
        }
        else if (arrival) {
            searchDispatch({
                type: SearchDispatchActionType.ADD_ARRIVAL_AIRPORTS,
                arrivalAirports: flattenAutocompleterValue(value),
            });
        }
    }
    const handleClickOrFocus = () => {
        if (!open) {
            handleOpen();
        }
    };
    const handleClearSearchTerm = () => {
        setSearchTerm('');
    };
    const fieldError = (React.createElement(FieldErrorMessage, { error: departure ? error : errorArrival, message: fieldErrorMessage, "data-id": "airport-error-message" }));
    const label = departure ? SEARCH_DEPARTURE_FIELD_LABEL : SEARCH_ARRIVAL_FIELD_LABEL;
    const placeholder = SEARCH_AIRPORTS_FIELD_PLACEHOLDER;
    return (React.createElement(React.Fragment, null,
        React.createElement(Autocompleter, { fetch: fetchData, onAdd: (value) => onAirportAdd(value), onRemove: (value) => onAirportRemove(value), selectedValue: selectedAirportOptions.map((option) => option.code), multiSelect: isMultiSelect },
            React.createElement(React.Fragment, null,
                popup && open && (React.createElement(SearchFieldPopover, { open: open, onClose: () => onClose(), placement: popupPlacement, error: searchError, dynamicHeight: true, scrollingElement: scrollingPopoverElement },
                    React.createElement(PopoverAnchor, null,
                        React.createElement(AutocompleterField, Object.assign({ id: autocompleterFieldId, listId: autocompleterListId, value: error && searchClicked ? '' : searchTerm, label: label, placeholder: placeholder, onChange: handleFieldChange, autoFocus: true, shrinkLabel: true, size: { xs: 'm', m: 'l' }, showClear: withSearchTerm, onButtonClick: handleClearSearchTerm }, commonFieldProps))),
                    React.createElement(SearchFieldPopoverPanel, { width: 375, scrollingElementRef: (ref) => setScrollingPopoverElement(ref) }, autocompleterResults))),
                (dialog || !open) && (React.createElement(ButtonField, Object.assign({ value: summary, label: label, onClick: handleClickOrFocus, onFocus: () => popup && handleClickOrFocus(), size: { xs: 'm', m: 'l' } }, commonFieldProps))),
                fieldError),
            dialog && (React.createElement(FieldDialog, { open: open, onClose: onClose, title: label, headingProps: {
                    component: 'label',
                    htmlFor: dialogAutocompleterFieldId,
                }, ctaProps: {
                    onClick: onDone,
                    label: 'Next',
                    disabled: !airportsSelected,
                }, resetProps: {
                    onClick: handleClear,
                    label: 'Clear all',
                }, headerContent: React.createElement(React.Fragment, null,
                    React.createElement(AutocompleterField, Object.assign({ id: dialogAutocompleterFieldId, listId: autocompleterListId, value: searchTerm, placeholder: placeholder, onChange: handleFieldChange, autoFocus: true, startIcon: React.createElement(SearchIcon, null), showClear: withSearchTerm, onButtonClick: handleClearSearchTerm }, commonFieldProps)),
                    fieldError,
                    selectedAirportOptions.length > 0 && (React.createElement(Styler, { mt: 3 }, selectedOptionPills(true, true)))) }, autocompleterResults))),
        errorProp && React.createElement("div", null, errorProp.message)));
}
