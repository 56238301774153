import React, { useCallback, useReducer, useState, useEffect, useMemo } from 'react';
import { useGlobalContext, usePageRouting, TripType, SearchFieldEnum, } from '@honeycomb/data';
import { ArrowRightLongIcon, Button, FlexGrid, SearchIcon, SelectMenu, SelectMenuOption, Styler, SyncIcon, animate, useBreakpoints, usePrevious, } from '@honeycomb/ui-core';
import { TransferDateTimePicker } from './TransferDateTimePicker';
import { OccupantsSelector } from './OccupantsSelector';
import { StickySearch } from '../StickySearch';
import { SearchDispatchActionType, searchReducer } from '../SearchReducer';
import { SearchContext, SearchDisplayType } from '../SearchContext';
import { getSearchDisplayType, getSearchError } from '../SearchUtil';
import { TransferCollapsedSearch } from './TransferCollaspedSearch';
import { PickUpDropOffAutocompleter } from './PickUpDropOffAutoCompleter';
import { SEARCH_FIELD_SPACING } from '../../../utils/style-constants';
export function TransferSearchUnit({ state, tracking, collapsible, sticky, open = true, onOpenChange = (val) => val, action, entry, searchElementRef, selectedTripType, onSearchErrorChange, }) {
    var _a, _b, _c, _d, _e;
    const { isPageRouting } = usePageRouting();
    const { createSearch, loading, error } = action;
    const [searchState, searchDispatch] = useReducer(searchReducer, state);
    const { resourceStrings: { SEARCHBUTTONTEXT }, } = useGlobalContext();
    const [openField, setOpenField] = useState(SearchFieldEnum.NONE);
    const prevTransferSearchState = usePrevious(state);
    const TRIP_OPTIONS = [
        { id: 1, label: 'Return', icon: React.createElement(SyncIcon, null) },
        { id: 2, label: 'One-way', icon: React.createElement(ArrowRightLongIcon, null) },
    ];
    const [isReturnTransfer, setIsReturnTransfer] = useState(true);
    const [reverse, setReverse] = React.useState(false);
    const { mediumUp, largeUp } = useBreakpoints();
    const displayType = getSearchDisplayType(largeUp);
    const dialogFullScreen = !mediumUp;
    const [searchClicked, setSearchClicked] = useState(false);
    const [pickUpPointError, setpickUpPointError] = useState(false);
    const [dropOffError, setdropOffError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [occupentError, setOccupentError] = useState(false);
    const validPickUpPoint = !!((_a = searchState.pickUpPoint) === null || _a === void 0 ? void 0 : _a.id);
    const validDropOff = !!((_b = searchState.dropOffPoint) === null || _b === void 0 ? void 0 : _b.id);
    const validStartDate = !!searchState.startDate;
    const validEndDate = !!searchState.endDate;
    const validOccupancy = !!((_c = searchState.childAges) === null || _c === void 0 ? void 0 : _c.every((age) => age >= 0));
    const fieldValidity = useMemo(() => ({
        [SearchFieldEnum.PICKUPPOINT]: { valid: validPickUpPoint, error: pickUpPointError },
        [SearchFieldEnum.DROPOFFPOINT]: { valid: validDropOff, error: dropOffError },
        [SearchFieldEnum.STARTDATE]: { valid: validStartDate, error: startDateError },
        [SearchFieldEnum.ENDDATE]: { valid: validEndDate, error: endDateError },
        [SearchFieldEnum.OCCUPANTS]: { valid: validOccupancy, error: occupentError },
    }), [
        validPickUpPoint,
        pickUpPointError,
        validDropOff,
        dropOffError,
        validStartDate,
        startDateError,
        validEndDate,
        endDateError,
        validOccupancy,
        occupentError,
    ]);
    const searchError = getSearchError(fieldValidity);
    onSearchErrorChange(searchError);
    if (error !== undefined) {
        throw new Error(error.message);
    }
    // if one way and pickUpPoint is Resort
    const arrivalDateDisabled = !isReturnTransfer && reverse;
    // if one way and pickUpPoint is Airport
    const departureDateDisabled = !isReturnTransfer && !reverse;
    const openNextField = useCallback(() => {
        if (!validPickUpPoint) {
            setOpenField(SearchFieldEnum.PICKUPPOINT);
            return;
        }
        if (!validDropOff) {
            setOpenField(SearchFieldEnum.DROPOFFPOINT);
            return;
        }
        if (!validStartDate && !arrivalDateDisabled) {
            setOpenField(SearchFieldEnum.STARTDATE);
            return;
        }
        if (!validEndDate && !departureDateDisabled) {
            setOpenField(SearchFieldEnum.ENDDATE);
            return;
        }
        setOpenField(SearchFieldEnum.NONE);
    }, [arrivalDateDisabled, departureDateDisabled, validDropOff, validEndDate, validPickUpPoint, validStartDate]);
    useEffect(() => {
        var _a;
        if ((_a = state.pickUpPoint) === null || _a === void 0 ? void 0 : _a.type) {
            if (!state.startDate || !state.endDate) {
                setIsReturnTransfer(false);
            }
            if (!state.startDate) {
                setReverse(true);
            }
        }
    }, [state.endDate, state.pickUpPoint, state.startDate]);
    useEffect(() => {
        if (isReturnTransfer && reverse) {
            setReverse(false);
        }
    }, [isReturnTransfer, reverse]);
    const handleOpen = useCallback((field) => {
        if (displayType === SearchDisplayType.POPUP) {
            // Scroll to the search element, then open the field.
            if (searchElementRef.current) {
                const rect = searchElementRef.current.getBoundingClientRect();
                const position = rect.top + document.documentElement.scrollTop;
                animate('scrollTop', document.documentElement, position, {}, () => {
                    setOpenField(field || SearchFieldEnum.NONE);
                });
            }
            return;
        }
        setOpenField(field || SearchFieldEnum.NONE);
    }, [displayType, searchElementRef, setOpenField]);
    const onDropOff = useCallback(() => {
        if (!validPickUpPoint) {
            handleOpen(SearchFieldEnum.NONE);
            setpickUpPointError(!validPickUpPoint);
        }
        else {
            setpickUpPointError(false);
        }
    }, [handleOpen, validPickUpPoint]);
    const handleClose = useCallback(() => {
        setOpenField(SearchFieldEnum.NONE);
    }, [setOpenField]);
    const context = React.useMemo(() => ({
        openField,
        fieldValidity,
        onOpen: handleOpen,
        onClose: handleClose,
        searchDispatch,
        searchState,
        displayType,
        dialogFullScreen,
        searchError,
    }), [openField, fieldValidity, handleOpen, handleClose, searchState, displayType, dialogFullScreen, searchError]);
    // Effect to be called when the global state updates
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        if ((prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.adults) !== state.adults) {
            searchDispatch({
                type: SearchDispatchActionType.SET_ADULTS,
                adultsValue: (state === null || state === void 0 ? void 0 : state.adults) || undefined,
            });
        }
        if (((_a = prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.pickUpPoint) === null || _a === void 0 ? void 0 : _a.id) !== ((_b = state.pickUpPoint) === null || _b === void 0 ? void 0 : _b.id)) {
            searchDispatch({
                type: SearchDispatchActionType.SET_PICKUP_POINT,
                pickDropPoint: (_c = state === null || state === void 0 ? void 0 : state.pickUpPoint) !== null && _c !== void 0 ? _c : undefined,
            });
        }
        if (((_d = prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.dropOffPoint) === null || _d === void 0 ? void 0 : _d.id) !== ((_e = state.dropOffPoint) === null || _e === void 0 ? void 0 : _e.id)) {
            searchDispatch({
                type: SearchDispatchActionType.SET_DROPOFF_POINT,
                pickDropPoint: (_f = state === null || state === void 0 ? void 0 : state.dropOffPoint) !== null && _f !== void 0 ? _f : undefined,
            });
        }
        if ((prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.childAges) !== state.childAges) {
            searchDispatch({
                type: SearchDispatchActionType.SET_CHILDAGES,
                childAges: (state === null || state === void 0 ? void 0 : state.childAges) || undefined,
            });
        }
        if ((prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.endDate) !== state.endDate) {
            searchDispatch({
                type: SearchDispatchActionType.SET_END_DATE,
                dateValue: (state === null || state === void 0 ? void 0 : state.endDate) || undefined,
            });
        }
        if ((prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.startDate) !== state.startDate) {
            searchDispatch({
                type: SearchDispatchActionType.SET_START_DATE,
                dateValue: (state === null || state === void 0 ? void 0 : state.startDate) || undefined,
            });
        }
    }, [
        prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.adults,
        prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.childAges,
        (_d = prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.dropOffPoint) === null || _d === void 0 ? void 0 : _d.id,
        prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.endDate,
        (_e = prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.pickUpPoint) === null || _e === void 0 ? void 0 : _e.id,
        prevTransferSearchState === null || prevTransferSearchState === void 0 ? void 0 : prevTransferSearchState.startDate,
        state.adults,
        state.childAges,
        state.dropOffPoint,
        state.endDate,
        state.pickUpPoint,
        state.startDate,
    ]);
    const onSearchClick = useCallback((e) => {
        e.stopPropagation();
        if (validPickUpPoint &&
            validDropOff &&
            ((arrivalDateDisabled && validEndDate) ||
                (departureDateDisabled && validStartDate) ||
                (validStartDate && validEndDate)) &&
            validOccupancy) {
            createSearch(searchState);
            setOpenField(SearchFieldEnum.NONE);
        }
        else {
            setpickUpPointError(!validPickUpPoint);
            setdropOffError(!validDropOff);
            if (!arrivalDateDisabled) {
                setStartDateError(!validStartDate);
            }
            if (!departureDateDisabled) {
                setEndDateError(!validEndDate);
            }
            setOccupentError(!validOccupancy);
            openNextField();
            setSearchClicked(true);
        }
    }, [
        validPickUpPoint,
        validDropOff,
        arrivalDateDisabled,
        validEndDate,
        departureDateDisabled,
        validStartDate,
        validOccupancy,
        searchState,
        createSearch,
        openNextField,
    ]);
    useEffect(() => {
        if (selectedTripType === TripType.Transfer && searchState.tripType !== TripType.Transfer) {
            searchDispatch({
                type: SearchDispatchActionType.SET_TRIP_TYPE,
                tripType: selectedTripType,
            });
        }
    }, [searchState.tripType, selectedTripType]);
    useEffect(() => {
        if (pickUpPointError && validPickUpPoint) {
            setpickUpPointError(false);
        }
    }, [pickUpPointError, validPickUpPoint]);
    useEffect(() => {
        if (dropOffError && validDropOff) {
            setdropOffError(false);
        }
    }, [dropOffError, validDropOff]);
    useEffect(() => {
        if (startDateError && validStartDate && !arrivalDateDisabled) {
            setStartDateError(false);
        }
    }, [startDateError, validStartDate, arrivalDateDisabled]);
    useEffect(() => {
        if (endDateError && validEndDate) {
            setEndDateError(false);
        }
    }, [endDateError, validEndDate]);
    // Effect to be called on rooms field change
    useEffect(() => {
        if (occupentError && validOccupancy) {
            setOccupentError(false);
        }
    }, [occupentError, validOccupancy]);
    // Update GTM dataLayer when searchState changes
    useEffect(() => {
        if (tracking) {
            tracking(searchState);
        }
    }, [searchState, tracking]);
    const handleReturnTypeChange = (value) => {
        setIsReturnTransfer(value === 'Return');
    };
    const fullSearch = () => (React.createElement(React.Fragment, null,
        React.createElement(Styler, { mb: 1 },
            React.createElement(SelectMenu, { "data-id": "transfer-return", value: isReturnTransfer ? TRIP_OPTIONS[0].label : TRIP_OPTIONS[1].label, defaultValue: "Return", onChange: handleReturnTypeChange, icon: isReturnTransfer ? TRIP_OPTIONS[0].icon : TRIP_OPTIONS[1].icon }, TRIP_OPTIONS.map((item) => (React.createElement(SelectMenuOption, { value: item.label, key: item.id }, item.label))))),
        React.createElement(FlexGrid, { container: true, alignItems: "flex-start", spacing: SEARCH_FIELD_SPACING },
            React.createElement(FlexGrid, { xs: 12, m: 6, l: "fill" },
                React.createElement(PickUpDropOffAutocompleter, { searchClicked: searchClicked, fieldType: SearchFieldEnum.PICKUPPOINT, setSearchClicked: setSearchClicked, isReturn: isReturnTransfer, setReverse: setReverse, onDone: openNextField })),
            React.createElement(FlexGrid, { xs: 12, m: 6, l: "fill" },
                React.createElement(PickUpDropOffAutocompleter, { searchClicked: searchClicked, fieldType: SearchFieldEnum.DROPOFFPOINT, setSearchClicked: setSearchClicked, onDropOff: onDropOff, isReturn: isReturnTransfer, setReverse: setReverse, onDone: openNextField })),
            React.createElement(FlexGrid, { xs: 12, m: "fill", style: { maxWidth: largeUp ? 196 : undefined } },
                React.createElement(TransferDateTimePicker, { dateType: "start", disabled: arrivalDateDisabled, onDone: openNextField })),
            React.createElement(FlexGrid, { xs: 12, m: "fill", style: { maxWidth: largeUp ? 196 : undefined } },
                React.createElement(TransferDateTimePicker, { dateType: "end", disabled: departureDateDisabled, onDone: openNextField })),
            React.createElement(FlexGrid, { xs: 12, m: "fill", style: { maxWidth: largeUp ? 206 : undefined } },
                React.createElement(OccupantsSelector, { popupPlacement: "bottom-end", onDone: openNextField })),
            React.createElement(FlexGrid, { xs: 12, m: "auto", mt: { xs: 2, m: 0 } },
                React.createElement(Button, { icon: React.createElement(SearchIcon, null), fullWidth: true, color: "callToAction", size: { xs: 'xl', m: '2xl' }, "data-id": "search-button", onClick: onSearchClick, loading: loading || isPageRouting }, SEARCHBUTTONTEXT)))));
    return (React.createElement(SearchContext.Provider, { value: context }, selectedTripType === TripType.Transfer && (React.createElement(React.Fragment, null,
        !collapsible && fullSearch(),
        collapsible && !open && (React.createElement(TransferCollapsedSearch, { loading: loading, onClick: () => onOpenChange === null || onOpenChange === void 0 ? void 0 : onOpenChange(true) })),
        collapsible && open && fullSearch(),
        sticky && (React.createElement(StickySearch, { searchRef: searchElementRef, inView: (entry === null || entry === void 0 ? void 0 : entry.isIntersecting) || false, onClick: () => onOpenChange === null || onOpenChange === void 0 ? void 0 : onOpenChange(true) },
            React.createElement(TransferCollapsedSearch, { loading: loading })))))));
}
