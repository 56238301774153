var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useRemoveHotelFromShortlist, useAddHotelToShortlist, useGlobalContext } from '@honeycomb/data';
import { IconButton, HeartFilledIcon, HeartIcon, Popover, PopoverAnchor, Paper, PopoverPanel, TickIcon, TypographyBody, Stacker, Anchor, CrossIcon, } from '@honeycomb/ui-core';
import { pushAddToShortlist, pushRemoveFromShortlist } from '@honeycomb/tracking';
import React, { useCallback, useEffect, useState } from 'react';
import { useTimeout } from '../../utils/useTimeout';
export function WishlistHeartButton(props) {
    const { isInShortlist, estabId } = props, iconProps = __rest(props, ["isInShortlist", "estabId"]);
    const { resourceStrings: { HC_WISHLIST_ERROR_POPUP, HC_WISHLIST_ADDED_TO_LIST_POPUP, HC_WISHLIST_VIEW, HC_WISHLIST_UNDO, HC_WISHLIST_REMOVED_FROM_LIST_POPUP, }, } = useGlobalContext();
    const [isHotelInShortlist, setHotelInShortList] = useState(isInShortlist);
    const [open, setOpen] = React.useState(false);
    const [removeHotelFailure, setRemoveHotelFailure] = React.useState(false);
    const [addHotelFailure, setAddHotelFailure] = React.useState(false);
    const [removeHotelFromShortlist, { loading: removeHotelToShortlistLoading }] = useRemoveHotelFromShortlist([
        pushRemoveFromShortlist,
    ]);
    const [addHotelToShortlist, { loading: addHotelToShortlistLoading }] = useAddHotelToShortlist([pushAddToShortlist]);
    useEffect(() => setHotelInShortList(isInShortlist), [isInShortlist]);
    const { restartTimer } = useTimeout(5000, () => {
        setOpen(false);
    });
    const handleHeartIconClick = useCallback((event) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        event.preventDefault();
        setOpen(false);
        if (isHotelInShortlist) {
            const removeData = yield removeHotelFromShortlist({
                variables: {
                    estabId,
                },
            });
            if ((_b = (_a = removeData.data) === null || _a === void 0 ? void 0 : _a.removeHotelFromShortlist) === null || _b === void 0 ? void 0 : _b.success) {
                setHotelInShortList(false);
            }
            else {
                setRemoveHotelFailure(true);
            }
        }
        else {
            const addData = yield addHotelToShortlist({
                variables: {
                    estabId,
                },
            });
            if ((_d = (_c = addData.data) === null || _c === void 0 ? void 0 : _c.addHotelToShortlist) === null || _d === void 0 ? void 0 : _d.success) {
                setHotelInShortList(true);
            }
            else {
                setAddHotelFailure(true);
            }
        }
        setOpen(true);
        restartTimer();
    }), [addHotelToShortlist, estabId, isHotelInShortlist, removeHotelFromShortlist, restartTimer]);
    const undoRemoveEstabFromShortlist = useCallback((event) => __awaiter(this, void 0, void 0, function* () {
        var _e, _f;
        event.preventDefault();
        setOpen(false);
        const addData = yield addHotelToShortlist({
            variables: {
                estabId,
            },
        });
        if ((_f = (_e = addData.data) === null || _e === void 0 ? void 0 : _e.addHotelToShortlist) === null || _f === void 0 ? void 0 : _f.success) {
            setHotelInShortList(true);
        }
        else {
            setAddHotelFailure(true);
        }
        setOpen(true);
        restartTimer();
    }), [addHotelToShortlist, estabId, restartTimer]);
    return (React.createElement(Popover, { open: open, onClose: (_event) => {
            setOpen(false);
        }, dismiss: true, placement: "bottom-end", shift: true },
        React.createElement(PopoverAnchor, { asChild: true },
            React.createElement(IconButton, Object.assign({}, iconProps, { onClick: handleHeartIconClick, disabled: addHotelToShortlistLoading || removeHotelToShortlistLoading }), isHotelInShortlist ? React.createElement(HeartFilledIcon, { fill: "primary.base" }) : React.createElement(HeartIcon, { color: "primary.base" }))),
        React.createElement(PopoverPanel, null,
            React.createElement(Paper, { shadow: "s", border: 1, radius: "s", p: 3 },
                React.createElement(Stacker, { flexDirection: "row", alignItems: "center" }, removeHotelFailure || addHotelFailure ? (React.createElement(TypographyBody, { weight: "semiBold", size: "s" }, HC_WISHLIST_ERROR_POPUP)) : isHotelInShortlist ? (React.createElement(React.Fragment, null,
                    React.createElement(TickIcon, { color: "system.success.base" }),
                    React.createElement(TypographyBody, { weight: "semiBold", size: "s" }, HC_WISHLIST_ADDED_TO_LIST_POPUP),
                    React.createElement(Anchor, { size: "s", href: "/wishlist", underline: "always", color: "text.link", weight: "semiBold", pl: 3 }, HC_WISHLIST_VIEW))) : (React.createElement(React.Fragment, null,
                    React.createElement(CrossIcon, { color: "system.error.base" }),
                    React.createElement(TypographyBody, { weight: "semiBold", size: "s" }, HC_WISHLIST_REMOVED_FROM_LIST_POPUP),
                    React.createElement(Anchor, { size: "s", component: "button", underline: "always", color: "text.link", weight: "semiBold", pl: 3, onClick: undoRemoveEstabFromShortlist }, HC_WISHLIST_UNDO))))))));
}
