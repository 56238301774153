import { gql, useQuery } from '@apollo/client';
import { callSideEffects } from '../../util';
export const GET_SHORTLIST = gql `
    query getShortlist {
        getShortlist {
            shortlistId
            name
            hotels {
                estabId
            }
        }
    }
`;
export function useShortlist(sideEffects, skipQuery = false) {
    return useQuery(GET_SHORTLIST, {
        onCompleted: (data) => callSideEffects(sideEffects, data),
        ssr: false,
        skip: skipQuery,
    });
}
