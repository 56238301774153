var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TypographyBody } from '@honeycomb/ui-core';
import React from 'react';
export function FieldErrorMessage(_a) {
    var { error, message } = _a, other = __rest(_a, ["error", "message"]);
    return error ? (React.createElement(TypographyBody, Object.assign({ component: "div", color: "system.error.base", size: "xs", mt: 0.5, weight: "semiBold" }, other), message)) : null;
}
