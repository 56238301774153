import { initDataLayer } from '../util/initDataLayer';
import { isBrowser } from '../util/isBrowser';
export function pushPromotedDeals(data) {
    if (!isBrowser())
        return;
    initDataLayer();
    const promotedDeals = data.flatMap((card) => {
        return card.deals.map((deal, dealIndex) => {
            return {
                creative: 'Deals Widget',
                title: card.title,
                description: card.description,
                textLine1: deal.textLine1,
                textLine2: deal.textLine2,
                textLine3: deal.textLine3,
                position: dealIndex + 1,
            };
        });
    });
    window.dataLayer.push({ promotedDeals: null });
    window.dataLayer.push({ event: 'dealsWidgetDisplayed', promotedDeals });
}
