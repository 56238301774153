export const getOccupancyTotals = (rooms) => {
    return Object.assign({ rooms: rooms.length }, rooms.reduce((acc, cur) => {
        return {
            adults: acc.adults + cur.adults,
            children: acc.children + cur.childAges.length,
        };
    }, { adults: 0, children: 0 }));
};
export const getOccupancySummary = (rooms, resourceStrings, cabinText = '') => {
    const totals = getOccupancyTotals(rooms);
    const { ADULT, ADULTS, CHILDREN, CHILD, ROOMS, ROOM } = resourceStrings;
    const adultString = `${totals.adults} ${totals.adults > 1 ? ADULTS : ADULT}, `;
    const childString = `${totals.children} ${totals.children > 1 ? CHILDREN : CHILD}, `;
    const roomString = `${totals.rooms} ${totals.rooms > 1 ? ROOMS : ROOM}`;
    const cabinString = cabinText ? `, ${cabinText}` : '';
    return `${adultString}${totals.children > 0 ? childString : ''}${roomString}${cabinString}`;
};
export const getFlightOccupancySummary = (rooms, resourceStrings, cabinText = '') => {
    const totals = getOccupancyTotals(rooms);
    const { ADULT, ADULTS, CHILDREN, CHILD } = resourceStrings;
    const adultString = `${totals.adults} ${totals.adults > 1 ? ADULTS : ADULT}`;
    const childString = `${totals.children} ${totals.children > 1 ? CHILDREN : CHILD} `;
    const cabinString = cabinText ? `, ${cabinText}` : '';
    return `${adultString}${totals.children > 0 ? `, ${childString}` : ''}${cabinString}`;
};
export const getTransferOccupancySummary = (adults, childAges, resourceStrings) => {
    const totalAdults = adults;
    const totalChildren = childAges === null || childAges === void 0 ? void 0 : childAges.length;
    const { ADULT, ADULTS, CHILDREN, CHILD } = resourceStrings;
    const adultString = `${totalAdults} ${totalAdults > 1 ? ADULTS : ADULT}`;
    const childString = `, ${totalChildren} ${totalChildren > 1 ? CHILDREN : CHILD}`;
    return `${adultString}${totalChildren > 0 ? childString : ''}`;
};
export const getPassengerOccupancySummary = (adults, childAges, cabinText, resourceStrings) => {
    const totalAdults = adults;
    const totalChildren = childAges === null || childAges === void 0 ? void 0 : childAges.length;
    const { ADULT, ADULTS, CHILDREN, CHILD } = resourceStrings;
    const adultString = `${totalAdults} ${totalAdults > 1 ? ADULTS : ADULT} `;
    const childString = `, ${totalChildren} ${totalChildren > 1 ? CHILDREN : CHILD}`;
    const cabinString = cabinText ? `, ${cabinText}` : '';
    return `${adultString}${totalChildren > 0 ? childString : ''}${cabinString}`;
};
