import { useGlobalContext } from '@honeycomb/data';
// LATER: We should make these default options to be configurable per domain.
// Perhaps they should also be on global context?
const defaultDateOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit', // Two-digit representation of the day
};
const defaultTimeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
};
export const useFormatDateTime = ({ date, options }) => {
    const { currentContext } = useGlobalContext();
    if (!date) {
        return 'Invalid date';
    }
    const { cultureCode } = currentContext;
    return new Intl.DateTimeFormat(cultureCode, options).format(date);
};
export const useFormatDate = ({ date, options }) => {
    const formatOptions = Object.assign(Object.assign({}, defaultDateOptions), options);
    return useFormatDateTime({ date, options: formatOptions });
};
export const useFormatTime = ({ date, options }) => {
    const formatOptions = Object.assign(Object.assign({}, defaultTimeOptions), options);
    return useFormatDateTime({ date, options: formatOptions });
};
