import React from 'react';
import { useGlobalContext } from '@honeycomb/data';
import { NumberPicker } from '@honeycomb/ui-core';
import { RoomPickerPanel } from './RoomPickerPanel';
export function AdultSelector({ state, setNumberOfAdults }) {
    const { resourceStrings: { ADULTS, YEARS }, } = useGlobalContext();
    const { roomIndex, maxAdults, adults, minAdultAge } = state;
    const key = roomIndex !== undefined ? `-${roomIndex}` : '';
    const fieldId = `adults-picker${key}`;
    return (React.createElement(RoomPickerPanel, { title: ADULTS, subTitle: `${minAdultAge}+ ${YEARS}`, fieldId: fieldId },
        React.createElement(NumberPicker, { id: fieldId, name: fieldId, min: 1, max: maxAdults, value: adults, inputReadOnly: true, onChange: (value) => {
                setNumberOfAdults(value, roomIndex);
            } })));
}
