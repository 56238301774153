import React from 'react';
import { CardSection, Card, TypographyBody, TypographyHero, Stacker } from '@honeycomb/ui-core';
import { DealCardItem } from './DealCardItem';
export function DealCard(props) {
    const { card } = props;
    const { title, description, deals } = card;
    return (React.createElement(Card, { h: "100%" },
        React.createElement(CardSection, { bs: "solid", bc: "accent.light", bw: "24px 0 0 0", styleId: "deal-card-section" },
            React.createElement(TypographyHero, { color: "text.heading", size: "xs", mb: 2 }, title),
            React.createElement(TypographyBody, { size: "xs", mb: 4 }, description),
            React.createElement(Stacker, { spacing: 3 }, deals === null || deals === void 0 ? void 0 : deals.filter((deal) => !!deal.linkUrl).map((deal) => {
                return (React.createElement(DealCardItem, { key: deal.textLine1, deal: deal, resourceStrings: card === null || card === void 0 ? void 0 : card.resourceString }));
            })))));
}
