import { Clone, Paper, Skeleton, Styler } from '@honeycomb/ui-core';
import React from 'react';
const ITEM_BORDER_PROPS = {
    bs: 'solid',
    bw: 0,
    bbw: 1,
    bc: 'grey.lightest',
};
export function AutocompleterSkeleton({ multiSelect = false, rows = 8, }) {
    // Skeleton depicting an AutocompleterCategory with some options.
    return (React.createElement(Paper, { radius: "s", border: 0 },
        React.createElement(Clone, { copies: rows, renderChildren: (index) => {
                const last = index === rows - 1;
                const other = !last ? ITEM_BORDER_PROPS : null;
                return (React.createElement(Styler, Object.assign({ px: 3, h: 56, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }, other),
                    React.createElement(Skeleton, { variant: "text", h: "20px", w: "70%" }),
                    multiSelect && React.createElement(Skeleton, { w: "24px", h: "24px", br: "5px" })));
            } })));
}
