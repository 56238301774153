import { FlexGrid, Paper, TypographyBody } from '@honeycomb/ui-core';
import React from 'react';
export function OccupancyTotals({ totals }) {
    return (React.createElement(FlexGrid, { container: true, spacing: 2 }, totals.map((total) => (React.createElement(FlexGrid, { xs: 12 / totals.length, key: total.caption },
        React.createElement(Paper, { radius: "s", p: 2, mb: 3, ta: "center", bg: "background.offset", border: 0 },
            React.createElement(TypographyBody, { size: "s" },
                React.createElement("strong", null,
                    total.caption,
                    ":"),
                " ",
                total.value)))))));
}
