import { persistTrackingData } from '../util';
import { initDataLayer } from '../util/initDataLayer';
import { isBrowser } from '../util/isBrowser';
export function pushAddToShortlist(data) {
    var _a;
    if (!isBrowser())
        return;
    initDataLayer();
    const message = {
        event: 'addToWishlistResults',
        wishlist: {
            lastAdded: (_a = data.addHotelToShortlist) === null || _a === void 0 ? void 0 : _a.estabId,
        },
    };
    window.dataLayer.push(message);
    persistTrackingData(message);
}
