import { gql, useMutation } from '@apollo/client';
import { callSideEffects } from '../../util';
import { GET_SHORTLIST } from './useShortlist';
const ADD_HOTEL_TO_SHORTLIST_MUTATION = gql `
    mutation AddHotelToShortlist($estabId: Int!) {
        addHotelToShortlist(estabId: $estabId) {
            success
            estabId
        }
    }
`;
export function useAddHotelToShortlist(sideEffects) {
    return useMutation(ADD_HOTEL_TO_SHORTLIST_MUTATION, {
        onCompleted: (removeData) => {
            callSideEffects(sideEffects, removeData);
        },
        refetchQueries: [GET_SHORTLIST, 'getShortlist'],
    });
}
