export const CAROUSEL_THEME = {
    visibleItems: {
        Default: { xs: 1.1, s: 1.5, m: 1.8, l: 3 },
        FullWidth: 1,
        Adjacent: { xs: 1.1, s: 1.25, m: 1.8, l: 1.25, xl: 1.5 },
    },
    spacing: {
        Default: { xs: 4, m: 5, l: 6 },
        FullWidth: { xs: 4, m: 5, l: 10 },
        Adjacent: { xs: 5, m: 7 },
    },
};
