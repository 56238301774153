import { gql, useLazyQuery } from '@apollo/client';
const ADDITIONAL_CONTENT_QUERY = gql `
    query GetUSPContent($contentId: Int!) {
        getUSPContent(contentId: $contentId) {
            alias
            contentId
            deferred
            title
            icon
            text
            trustPilot
            additionalContent
            additionalContentAvailable
            linkUrl
        }
    }
`;
export function useAdditionalContent() {
    return useLazyQuery(ADDITIONAL_CONTENT_QUERY, {
        notifyOnNetworkStatusChange: true,
        ssr: false,
    });
}
