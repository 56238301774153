var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
export function useDestinationAutocompleterResults(optionalFields = '') {
    const DESTINATION_AUTOCOMPLETER_RESULTS_QUERY = gql `
        query DestinationAutocompleter($request: DestinationAutocompleterRequest!) {
            destinationAutocompleterResults(request: $request) {
                destinations {
                    destinationId
                    title
                    hotelCount
                    subTitle
                    id
                    type
                    isCached
                }
                destinationGroups {
                    title
                    id
                    hotelCount
                    type
                    destinationGroupIds
                    destinationIds
                    isCached
                    subTitle
                }
                hotels {
                    hotelId
                    title
                    subTitle
                    id
                    type
                    isCached
                }
                ${optionalFields}
            }
        }
    `;
    const [getDestinationQuery, destinationResults] = useLazyQuery(DESTINATION_AUTOCOMPLETER_RESULTS_QUERY, {
        notifyOnNetworkStatusChange: true,
        ssr: false,
        variables: {
            request: {
                searchTerm: '',
                maxResults: 15,
                holidaySearch: true,
            },
        },
    });
    const getDestinations = useCallback((request) => __awaiter(this, void 0, void 0, function* () {
        const { data } = yield getDestinationQuery({ variables: { request } });
        return data;
    }), [getDestinationQuery]);
    return [getDestinations, destinationResults];
}
