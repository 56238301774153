import { useGlobalContext, SearchFieldEnum, Cabin } from '@honeycomb/data';
import { ButtonField, Paper, Stacker, TypographyBody, PopoverAnchor, } from '@honeycomb/ui-core';
import React, { useEffect } from 'react';
import { getPassengerOccupancySummary } from '../../../utils/getOccupancySummary';
import { SearchDisplayType, useSearchContext } from '../SearchContext';
import { getCabinDisplay } from '../../../utils/getCabinDisplay';
import { FieldDialog } from '../components/FieldDialog';
import { SearchDispatchActionType } from '../SearchReducer';
import { AdultSelector } from '../components/AdultSelector';
import { ChildSelector } from '../components/ChildSelector';
import { CabinSelector } from '../components/CabinSelector';
import { OccupancyHelpMessage } from '../components/OccupancyHelpMessage';
import { SearchFieldPopover } from '../components/SearchFieldPopover/SearchFieldPopover';
import { SearchFieldPopoverPanel } from '../components/SearchFieldPopover/SearchFieldPopoverPanel';
import { FieldErrorMessage } from '../components/FieldErrorMessage';
export function PassengersSelector({ state, actions }) {
    var _a, _b, _c, _d;
    const { popupPlacement = 'bottom-end' } = state;
    const { onDone } = actions;
    const { openField, onOpen, onClose, displayType, searchDispatch, searchState, fieldValidity, searchError } = useSearchContext();
    const { resourceStrings: { ADULT, ADULTS, CHILD, CHILDREN, DATEPICKER_CHILD_VALIDATION_MSG, SEARCH_STEP_DONEBUTTONLABEL, SEARCH_FLIGHT_OCCUPANCY_POPUP_TITLE, HC_CABIN_ECONOMY, HC_CABIN_PREMIUM_ECONOMY, HC_CABIN_BUSINESS, HC_CABIN_FIRST, }, configSettings: { HcMaxPassengerAdultFlights, HcMaxPassengerChildrenFlights, HcMaxChildAgeFlights, HcMinAdultPassengersFlights, }, } = useGlobalContext();
    const maxAdults = parseInt(HcMaxPassengerAdultFlights);
    const maxChildren = parseInt(HcMaxPassengerChildrenFlights);
    const maxChildAge = parseInt(HcMaxChildAgeFlights);
    const minAdultAge = maxChildAge + 1;
    const childAgeOptions = [...Array(maxChildAge + 1).keys()];
    const defaultPassengers = {
        adults: parseInt(HcMinAdultPassengersFlights),
        childAges: [],
    };
    const [cabin, setCabin] = React.useState(String(Cabin.Economy));
    // Is the search currently open on this field
    const open = openField === SearchFieldEnum.PASSENGERS;
    const validPassengers = (_b = (_a = fieldValidity[SearchFieldEnum.PASSENGERS]) === null || _a === void 0 ? void 0 : _a.valid) !== null && _b !== void 0 ? _b : false;
    const error = (_d = (_c = fieldValidity[SearchFieldEnum.PASSENGERS]) === null || _c === void 0 ? void 0 : _c.error) !== null && _d !== void 0 ? _d : false;
    const { adults, childAges } = searchState;
    const popup = displayType === SearchDisplayType.POPUP;
    const dialog = displayType === SearchDisplayType.DIALOG;
    const [scrollingPopoverElement, setScrollingPopoverElement] = React.useState(null);
    // Resetting the cabin state with current search state
    useEffect(() => {
        setCabin(searchState.cabin ? String(searchState.cabin) : String(Cabin.Economy));
    }, [searchState.cabin]);
    const setNumberOfAdults = (value) => {
        searchDispatch({
            type: SearchDispatchActionType.SET_ADULTS,
            adultsValue: value,
        });
    };
    const setNumberOfChildren = (value) => {
        if (!childAges)
            return;
        const oldChildAgeLength = childAges ? childAges.length : 0;
        let newChildAge = [];
        if (value - oldChildAgeLength > 0) {
            newChildAge = [...childAges, ...Array(value - oldChildAgeLength).fill(undefined)];
        }
        else {
            newChildAge = childAges === null || childAges === void 0 ? void 0 : childAges.slice(0, value);
        }
        searchDispatch({
            type: SearchDispatchActionType.SET_CHILDAGES,
            childAges: newChildAge !== null && newChildAge !== void 0 ? newChildAge : [],
        });
    };
    const setSelectedChildAge = (childAge, childIndex) => {
        if (!childAges) {
            return;
        }
        const updatedChildAges = [...childAges];
        updatedChildAges[childIndex] = childAge;
        searchDispatch({
            type: SearchDispatchActionType.SET_CHILDAGES,
            childAges: updatedChildAges,
        });
    };
    const content = (React.createElement(Stacker, { spacing: 4 },
        React.createElement(Paper, { radius: "s", py: 4, px: 3, border: 0 },
            React.createElement(Stacker, { spacing: 6 },
                React.createElement(AdultSelector, { state: { maxAdults, adults: adults || defaultPassengers.adults, minAdultAge }, setNumberOfAdults: setNumberOfAdults }),
                React.createElement(ChildSelector, { state: {
                        maxChildAge,
                        maxChildren,
                        childAges: childAges !== null && childAges !== void 0 ? childAges : defaultPassengers.childAges,
                        childAgeOptions,
                        error,
                    }, setNumberOfChildren: setNumberOfChildren, setSelectedChildAge: setSelectedChildAge }))),
        React.createElement(CabinSelector, { state: { cabin, id: 'cabin-select' }, onChange: (value) => {
                if (value) {
                    setCabin(value);
                    searchDispatch({
                        type: SearchDispatchActionType.SET_CABIN_CLASS,
                        cabinValue: Number(value),
                    });
                }
            } }),
        React.createElement(OccupancyHelpMessage, null)));
    const cabinText = getCabinDisplay(Number(cabin), {
        HC_CABIN_ECONOMY,
        HC_CABIN_PREMIUM_ECONOMY,
        HC_CABIN_BUSINESS,
        HC_CABIN_FIRST,
    });
    const summaryText = getPassengerOccupancySummary(adults || defaultPassengers.adults, childAges || defaultPassengers.childAges, cabinText, {
        ADULT,
        ADULTS,
        CHILD,
        CHILDREN,
    });
    const label = SEARCH_FLIGHT_OCCUPANCY_POPUP_TITLE;
    const fieldError = (React.createElement(FieldErrorMessage, { error: error, message: DATEPICKER_CHILD_VALIDATION_MSG, "data-id": "passengers-error-message" }));
    const handleClickOrFocus = () => {
        if (!open) {
            onOpen(SearchFieldEnum.PASSENGERS);
        }
    };
    const handleReset = () => {
        searchDispatch({
            type: SearchDispatchActionType.RESET_OCCUPANCY,
        });
    };
    const button = (React.createElement(ButtonField, { label: label, value: summaryText, onClick: handleClickOrFocus, onFocus: () => popup && handleClickOrFocus(), fullWidth: true, error: error, size: { xs: 'm', m: 'l' }, variant: "alternative" }));
    return (React.createElement(React.Fragment, null,
        popup && (React.createElement(SearchFieldPopover, { open: open, onClose: () => onClose(), placement: popupPlacement, dynamicHeight: true, error: searchError, scrollingElement: scrollingPopoverElement },
            React.createElement(PopoverAnchor, null, button),
            React.createElement(SearchFieldPopoverPanel, { width: 375, scrollingElementRef: (ref) => setScrollingPopoverElement(ref) }, content))),
        dialog && (React.createElement(React.Fragment, null,
            button,
            React.createElement(FieldDialog, { open: open, onClose: onClose, title: label, ctaProps: {
                    onClick: onDone,
                    label: SEARCH_STEP_DONEBUTTONLABEL,
                    disabled: !validPassengers,
                }, resetProps: {
                    onClick: handleReset,
                    label: 'Reset',
                }, headerContent: error ? (React.createElement(Paper, { radius: "s", p: 2, border: 0 },
                    React.createElement(TypographyBody, { size: "s", color: "system.error.base" }, DATEPICKER_CHILD_VALIDATION_MSG))) : undefined }, content))),
        fieldError));
}
