import React, { useMemo } from 'react';
import { DateSearchType, FlightJourneyType, SearchFieldEnum, TripType, useGlobalContext } from '@honeycomb/data';
import { TypographyBody } from '@honeycomb/ui-core';
import { formatDate } from '../../../utils/formatDate';
import { useSearchContext } from '../SearchContext';
import { interjectResourceString } from '../../../utils/interjectResourceString';
export function DateRangeFooter({ selectedTripType }) {
    var _a, _b;
    const { searchState, fieldValidity } = useSearchContext();
    const { resourceStrings: { HC_SEARCH_ERROR_MISSING_MONTH, HC_SEARCH_MONTH_VIEW_SELECT_DATE, HC_SEARCH_SELECT_DATE, SINGLENIGHT, NIGHTS, SEARCH_DATES_ERROR_MISSINGDATE, SEARCH_DATES_ERROR_MISSINGDATES, SEARCH_DATES_ERROR_MISSINGDATES_ONE_WAY, SEARCH_FROMDATENAME_HOLIDAY, SEARCH_FROMDATENAME_HOTELONLY, SEARCH_FROMDATENAME_FLIGHTS, SEARCH_TODATENAME_HOLIDAY, SEARCH_TODATENAME_HOTELONLY, SEARCH_TODATENAME_FLIGHTS, }, } = useGlobalContext();
    const error = (_b = (_a = fieldValidity[SearchFieldEnum.DATES]) === null || _a === void 0 ? void 0 : _a.error) !== null && _b !== void 0 ? _b : false;
    const startDate = searchState.startDate ? new Date(searchState.startDate) : undefined;
    const endDate = searchState.endDate ? new Date(searchState.endDate) : undefined;
    const isOneWayFlightSearch = searchState.flightJourneyType === FlightJourneyType.Oneway && selectedTripType === TripType.Flight;
    const dateFromLabel = useMemo(() => {
        switch (selectedTripType) {
            case TripType.Holiday:
                return SEARCH_FROMDATENAME_HOLIDAY;
            case TripType.Hotel:
                return SEARCH_FROMDATENAME_HOTELONLY;
            case TripType.Flight:
                return SEARCH_FROMDATENAME_FLIGHTS;
            default:
                return SEARCH_FROMDATENAME_HOTELONLY;
        }
    }, [SEARCH_FROMDATENAME_FLIGHTS, SEARCH_FROMDATENAME_HOLIDAY, SEARCH_FROMDATENAME_HOTELONLY, selectedTripType]);
    const dateToLabel = useMemo(() => {
        switch (selectedTripType) {
            case TripType.Holiday:
                return SEARCH_TODATENAME_HOLIDAY;
            case TripType.Hotel:
                return SEARCH_TODATENAME_HOTELONLY;
            case TripType.Flight:
                return SEARCH_TODATENAME_FLIGHTS;
            default:
                return SEARCH_TODATENAME_HOTELONLY;
        }
    }, [SEARCH_TODATENAME_HOLIDAY, SEARCH_TODATENAME_HOTELONLY, SEARCH_TODATENAME_FLIGHTS, selectedTripType]);
    const getFooterMessageMonthView = () => {
        if (error || !searchState.wholeMonthStartDate) {
            const getPromptText = () => {
                // Validation error
                if (error) {
                    return HC_SEARCH_ERROR_MISSING_MONTH;
                }
                return HC_SEARCH_MONTH_VIEW_SELECT_DATE;
            };
            return (React.createElement(TypographyBody, { component: "span", weight: "semiBold", size: "s", color: error ? 'system.error.base' : undefined, "data-id": error ? 'date-range-error-message' : 'date-range-help-message' }, getPromptText()));
        }
        // Month is selected
        return (React.createElement(TypographyBody, { component: "span", size: "s" },
            React.createElement("strong", { "data-id": "date-range-selected-month" }, `${new Date(searchState.wholeMonthStartDate).toLocaleString('default', {
                month: 'long',
            })} ${searchState.wholeMonthStartDate.substring(0, 4)}`),
            ' ',
            React.createElement("span", { "data-id": "date-range-selected-nights" }, `(${searchState.duration} ${searchState.duration === 1 ? SINGLENIGHT : NIGHTS})`)));
    };
    if (searchState.dateSearchType === DateSearchType.ChooseMonth) {
        return getFooterMessageMonthView();
    }
    if (error || !startDate || (!isOneWayFlightSearch && !endDate)) {
        // Return a prompt message
        const getPromptText = () => {
            // Validation error
            if (error) {
                if (startDate) {
                    return interjectResourceString(SEARCH_DATES_ERROR_MISSINGDATE, dateToLabel);
                }
                return isOneWayFlightSearch ? SEARCH_DATES_ERROR_MISSINGDATES_ONE_WAY : SEARCH_DATES_ERROR_MISSINGDATES;
            }
            // Start date prompt
            if (!startDate) {
                return interjectResourceString(HC_SEARCH_SELECT_DATE, dateFromLabel);
            }
            // End date prompt
            if (!endDate && !isOneWayFlightSearch) {
                return interjectResourceString(HC_SEARCH_SELECT_DATE, dateToLabel);
            }
            return null;
        };
        return (React.createElement(TypographyBody, { component: "span", weight: "semiBold", size: "s", color: error ? 'system.error.base' : undefined, "data-id": "date-range-help-message" }, getPromptText()));
    }
    // Both dates are selected
    const timeDiff = endDate && Math.abs(endDate.getTime() - startDate.getTime());
    const numberOfNights = timeDiff && Math.ceil(timeDiff / (1000 * 3600 * 24));
    const dateSelectionText = isOneWayFlightSearch
        ? `${formatDate(startDate, true)}`
        : startDate && endDate
            ? `${formatDate(startDate, true)} - ${formatDate(endDate, true)}`
            : '';
    return (React.createElement(TypographyBody, { component: "span", size: "s" },
        React.createElement("strong", { "data-id": "date-range-selected-dates" }, dateSelectionText),
        ' ',
        !isOneWayFlightSearch && (React.createElement("span", { "data-id": "date-range-selected-nights" }, `(${numberOfNights} ${numberOfNights === 1 ? SINGLENIGHT : NIGHTS})`))));
}
