import { useCallback, useEffect, useRef } from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTimeout = (delay, callback) => {
    const timeoutRef = useRef(null);
    const startTimer = useCallback(() => {
        if (timeoutRef.current)
            clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            callback();
        }, delay);
    }, [callback, delay]);
    const cancelTimer = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, []);
    const restartTimer = useCallback(() => {
        cancelTimer();
        startTimer();
    }, [cancelTimer, startTimer]);
    useEffect(() => {
        return () => cancelTimer();
    }, [cancelTimer]);
    return { startTimer, cancelTimer, restartTimer };
};
