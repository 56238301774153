import React from 'react';
import { Stacker } from '@honeycomb/ui-core';
import { PriceBlockCardItem } from './PriceBlockCardItem/PriceBlockCardItem';
export function PriceBlockCard(props) {
    var _a;
    const { cards } = props;
    return (React.createElement(Stacker, { spacing: 5 }, (_a = cards === null || cards === void 0 ? void 0 : cards.items) === null || _a === void 0 ? void 0 : _a.map((cardItem) => {
        return (React.createElement(PriceBlockCardItem, { key: cardItem === null || cardItem === void 0 ? void 0 : cardItem.overlayText, cardItem: cardItem, resourceStrings: cards === null || cards === void 0 ? void 0 : cards.resourceString }));
    })));
}
