import { useGlobalContext, Cabin } from '@honeycomb/data';
import { Paper, SelectField, TypographyHeading } from '@honeycomb/ui-core';
import React from 'react';
export function CabinSelector({ state, onChange }) {
    const { resourceStrings: { CABIN_CLASS, HC_CABIN_ECONOMY, HC_CABIN_PREMIUM_ECONOMY, HC_CABIN_BUSINESS, HC_CABIN_FIRST, HC_SELECT, }, } = useGlobalContext();
    return (React.createElement(Paper, { radius: "s", py: 4, px: 3, border: 0 },
        React.createElement(TypographyHeading, { size: "2xs", component: "label", htmlFor: state.id, mb: 2 }, CABIN_CLASS),
        React.createElement(SelectField, { size: "s", fullWidth: true, id: state.id, value: state.cabin, onChange: (event) => {
                const { value } = event.target;
                onChange(value);
            } },
            React.createElement("option", { value: "" }, HC_SELECT),
            React.createElement(React.Fragment, null,
                React.createElement("option", { value: Cabin.Economy, key: `cabin-${Cabin.Economy}` }, HC_CABIN_ECONOMY),
                React.createElement("option", { value: Cabin.PremiumEconomy, key: `cabin-${Cabin.PremiumEconomy}` }, HC_CABIN_PREMIUM_ECONOMY),
                React.createElement("option", { value: Cabin.First, key: `cabin-${Cabin.First}` }, HC_CABIN_FIRST),
                React.createElement("option", { value: Cabin.Business, key: `cabin-${Cabin.Business}` }, HC_CABIN_BUSINESS)))));
}
