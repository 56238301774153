import * as React from 'react';
import { SearchFieldEnum, initialSearchParameters } from '@honeycomb/data';
export var SearchDisplayType;
(function (SearchDisplayType) {
    SearchDisplayType["POPUP"] = "Popup";
    SearchDisplayType["DIALOG"] = "Dialog";
})(SearchDisplayType || (SearchDisplayType = {}));
export const initialFieldValidity = {
    [SearchFieldEnum.DESTINATION]: {
        valid: false,
        error: false,
    },
    [SearchFieldEnum.DATES]: {
        valid: false,
        error: false,
    },
    [SearchFieldEnum.ROOMS]: {
        valid: false,
        error: false,
    },
    [SearchFieldEnum.PASSENGERS]: {
        valid: false,
        error: false,
    },
    [SearchFieldEnum.OCCUPANTS]: {
        valid: false,
        error: false,
    },
};
export const SearchContext = React.createContext({
    openField: SearchFieldEnum.NONE,
    fieldValidity: initialFieldValidity,
    onOpen: () => null,
    onClose: () => null,
    displayType: SearchDisplayType.POPUP,
    searchDispatch: () => null,
    searchState: initialSearchParameters,
    searchError: false,
});
export function useSearchContext() {
    return React.useContext(SearchContext);
}
