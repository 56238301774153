import React from 'react';
import { CarouselDisplayStyle } from '@honeycomb/data';
import { Stacker, Styler, TypographyBody, TypographyHero } from '@honeycomb/ui-core';
import { useRootComponent } from './RootComponentContext';
import { getHeadingColor, getBodyColor } from './utils';
export function SectionHeading({ sectionHeading: { heading, subheading, __typename }, alias, }) {
    const { backgroundColor, carouselDisplayStyle } = useRootComponent();
    const adjacentLayout = carouselDisplayStyle === CarouselDisplayStyle.Adjacent;
    if (!heading) {
        return null;
    }
    const content = (React.createElement(Stacker, { spacing: { xs: 3, xl: 5 }, px: { l: 10 }, pl: adjacentLayout ? { l: 0 } : undefined, mb: 8, alignItems: adjacentLayout ? { xs: 'center', l: 'flex-start' } : 'center', ta: adjacentLayout ? { xs: 'center', l: 'start' } : 'center' },
        React.createElement(TypographyHero, { component: "h2", size: "2xl", color: getHeadingColor(backgroundColor), "data-id": `${__typename}-${alias}` }, heading),
        subheading && (React.createElement(TypographyBody, { size: "l", color: getBodyColor(backgroundColor), "data-id": `subheading-${alias}` }, subheading))));
    if (adjacentLayout) {
        return (React.createElement(Styler, { pos: "relative", zIndex: 2 }, content));
    }
    return content;
}
