import React from 'react';
import { StarRating, TypographyBody, Styler, FlexGrid, Anchor, TypographyHeading, Stacker, PriceBlock, PriceBlockCaption, PriceBlockAmount, } from '@honeycomb/ui-core';
import { StyledMoney } from '../../../components/StyledMoney';
export function DealCardItem(props) {
    var _a;
    const { deal, resourceStrings } = props;
    const { textLine1, textLine2, textLine3, starRating, teaserPrice, linkUrl } = deal;
    const dataKey = [textLine1, textLine2, textLine3].join('_');
    return (
    // data-id and data-key attributes referenced by click handlers in Google Tag Manager
    React.createElement(Anchor, { href: linkUrl, underline: "none", "data-id": "deal_promo", "data-key": dataKey, bs: "solid", bc: "divider", bw: "1px 0 0 0", pt: 3 },
        React.createElement(TypographyHeading, { size: "2xs", mb: 1 }, textLine1),
        React.createElement(FlexGrid, { container: true, spacing: 3, alignItems: "flex-end", flexWrap: "nowrap" },
            React.createElement(FlexGrid, { xs: "fill" },
                React.createElement(Stacker, null,
                    starRating && (React.createElement(Styler, { display: "flex" },
                        React.createElement(StarRating, { rating: Number(starRating) }))),
                    textLine2 && React.createElement(TypographyBody, { size: "xs" }, textLine2),
                    textLine3 && React.createElement(TypographyBody, { size: "xs" }, textLine3))),
            React.createElement(FlexGrid, { xs: "hold" }, teaserPrice && (React.createElement(PriceBlock, { align: "end" },
                React.createElement(PriceBlockCaption, null, resourceStrings.pricePrefixText),
                React.createElement(PriceBlockAmount, { styleId: "deal-card-price" },
                    React.createElement(StyledMoney, { parts: (_a = teaserPrice === null || teaserPrice === void 0 ? void 0 : teaserPrice.parts) !== null && _a !== void 0 ? _a : [], formatted: (teaserPrice === null || teaserPrice === void 0 ? void 0 : teaserPrice.formatted) || '' }))))))));
}
