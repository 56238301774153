import React from 'react';
import { useGlobalContext } from '@honeycomb/data';
export const useHotelFlag = (isSponsored, tags) => {
    const { resourceStrings: { HOTEL_SPONSORED_LABEL }, } = useGlobalContext();
    const getValues = React.useCallback(() => {
        if (isSponsored) {
            return { label: HOTEL_SPONSORED_LABEL, color: 'white' };
        }
        if (Array.isArray(tags) && tags.length > 0) {
            const tag = tags[0];
            return { label: tag.text, color: tag.themeColor };
        }
        return null;
    }, [HOTEL_SPONSORED_LABEL, isSponsored, tags]);
    return getValues();
};
