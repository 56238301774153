import React, { useMemo } from 'react';
import { useRenderCount, useGlobalContext } from '@honeycomb/data';
import { Container, Paper, useBreakpoints } from '@honeycomb/ui-core';
import { formatDate, formatDateTime } from '../../../utils/formatDate';
import { getTransferOccupancySummary } from '../../../utils/getOccupancySummary';
import { CollapsedSearchSummary } from '../components/CollapsedSearchSummary';
import { CollapsedSearchBar } from '../components/CollapsedSearchBar';
import { useSearchContext } from '../SearchContext';
export function TransferCollapsedSearch({ loading, onClick }) {
    const { searchState } = useSearchContext();
    const { largeUp } = useBreakpoints();
    const { resourceStrings: { ADULT, ADULTS, CHILD, CHILDREN }, } = useGlobalContext();
    // !! DEV-Only !! Counts and prints number of renders for component
    useRenderCount('Collapsed Search');
    const fields = useMemo(() => {
        var _a, _b, _c, _d;
        const destination = [
            {
                key: 'pickUpPoint',
                value: ((_a = searchState.pickUpPoint) === null || _a === void 0 ? void 0 : _a.name) || '',
            },
            {
                key: 'dropOffPoint',
                value: ((_b = searchState.dropOffPoint) === null || _b === void 0 ? void 0 : _b.name) || '',
            },
        ];
        const other = [];
        const startDate = searchState.startDate ? new Date(searchState.startDate) : undefined;
        const endDate = searchState.endDate ? new Date(searchState.endDate) : undefined;
        if (largeUp && startDate) {
            other.push({
                key: 'startDate',
                value: startDate ? formatDateTime(startDate) : '',
            });
        }
        if (largeUp && endDate) {
            other.push({
                key: 'endDate',
                value: endDate ? formatDateTime(endDate) : '',
            });
        }
        if (!largeUp) {
            const dates = [];
            if (startDate) {
                dates.push(startDate);
            }
            if (endDate) {
                dates.push(endDate);
            }
            other.push({
                key: 'dates',
                value: dates.map((date) => formatDate(date, false)).join(' - '),
            });
        }
        other.push({
            key: 'occupancy',
            value: getTransferOccupancySummary((_c = searchState.adults) !== null && _c !== void 0 ? _c : 2, (_d = searchState.childAges) !== null && _d !== void 0 ? _d : [], {
                ADULT,
                ADULTS,
                CHILD,
                CHILDREN,
            }) || '',
        });
        return [...destination, ...other];
    }, [
        ADULT,
        ADULTS,
        CHILD,
        CHILDREN,
        largeUp,
        searchState.adults,
        searchState.childAges,
        searchState.dropOffPoint,
        searchState.endDate,
        searchState.pickUpPoint,
        searchState.startDate,
    ]);
    return (React.createElement(Paper, { py: { xs: 2, l: 3 }, pos: "relative", zIndex: 1, border: 2, bw: "0 0 2px 0", btlr: 0, btrr: 0, onClick: () => {
            if (onClick) {
                onClick();
            }
        } },
        React.createElement(Container, null, largeUp ? (React.createElement(CollapsedSearchBar, { fields: fields, loading: loading })) : (React.createElement(CollapsedSearchSummary, { fields: {
                top: fields.slice(0, 2).filter((field) => Boolean(field.value)),
                bottom: fields.slice(-2).filter((field) => Boolean(field.value)),
            }, loading: loading })))));
}
