import { gql, useLazyQuery } from '@apollo/client';
const GET_PLATFORM_195_IMAGE_QUERY = gql `
    query GetPlatform195ImageCards($zoneIds: [Int!]!) {
        getPlatform195ImageCards(zoneIds: $zoneIds) {
            imageUrl
            mobileImageUrl
            overlayLogo
            promotionText
            clickUrl
            impressionUrl
            headline
            subhead
            link
            viewEventUrl
            zoneId
        }
    }
`;
export function useImageGroupCard() {
    return useLazyQuery(GET_PLATFORM_195_IMAGE_QUERY, {
        notifyOnNetworkStatusChange: true,
        ssr: false,
    });
}
