import { PickUpDropOffType, SearchFieldEnum, TransfersAutocompleterRequestFor, } from '@honeycomb/data';
export const parseAutocompleterValue = (value) => {
    var _a, _b, _c;
    if (value.startsWith(PickUpDropOffType.Resort)) {
        return {
            id: value.replace(PickUpDropOffType.Resort, ''),
            type: PickUpDropOffType.Resort,
            resortId: 0,
        };
    }
    if (value.startsWith(PickUpDropOffType.Airport)) {
        return {
            id: (_a = value.replace(PickUpDropOffType.Airport, '').split('RESID')) === null || _a === void 0 ? void 0 : _a[0],
            type: PickUpDropOffType.Airport,
            resortId: parseInt((_c = (_b = value.split('RESID')) === null || _b === void 0 ? void 0 : _b[1]) !== null && _c !== void 0 ? _c : 0),
        };
    }
    return { resortId: 0 };
};
export const toAutocompleterValue = (searchState, type) => {
    var _a, _b, _c, _d, _e, _f;
    if (type === SearchFieldEnum.PICKUPPOINT && ((_a = searchState.pickUpPoint) === null || _a === void 0 ? void 0 : _a.name)) {
        return `${(_b = searchState.pickUpPoint) === null || _b === void 0 ? void 0 : _b.type}-${(_c = searchState.pickUpPoint) === null || _c === void 0 ? void 0 : _c.id}`;
    }
    if (type === SearchFieldEnum.DROPOFFPOINT && ((_d = searchState.dropOffPoint) === null || _d === void 0 ? void 0 : _d.name)) {
        return `${(_e = searchState.dropOffPoint) === null || _e === void 0 ? void 0 : _e.type}-${(_f = searchState.dropOffPoint) === null || _f === void 0 ? void 0 : _f.id}`;
    }
    return undefined;
};
// Define the translation functions between schema and autocompleter option
export const mapAirportToOption = (result) => {
    var _a, _b, _c;
    return {
        value: `AIRPORT${result === null || result === void 0 ? void 0 : result.airportCode}RESID${result === null || result === void 0 ? void 0 : result.resortId}`,
        id: `option-${result === null || result === void 0 ? void 0 : result.airportCode}-${result === null || result === void 0 ? void 0 : result.country}`,
        code: (_a = result === null || result === void 0 ? void 0 : result.airportCode) !== null && _a !== void 0 ? _a : '',
        subTitle: (_b = result === null || result === void 0 ? void 0 : result.country) !== null && _b !== void 0 ? _b : '',
        title: (_c = result === null || result === void 0 ? void 0 : result.title) !== null && _c !== void 0 ? _c : '',
        type: PickUpDropOffType.Airport,
    };
};
export const mapResortToOption = (result) => {
    var _a, _b;
    const resortId = (result === null || result === void 0 ? void 0 : result.type) === 2 ? result === null || result === void 0 ? void 0 : result.resortId : result === null || result === void 0 ? void 0 : result.locationId;
    return {
        value: `RESORT${resortId}`,
        id: `option-${resortId}-${result === null || result === void 0 ? void 0 : result.country}`,
        title: (_a = result === null || result === void 0 ? void 0 : result.title) !== null && _a !== void 0 ? _a : '',
        subTitle: (_b = result === null || result === void 0 ? void 0 : result.country) !== null && _b !== void 0 ? _b : '',
        type: PickUpDropOffType.Resort,
    };
};
export const getTransferSearchType = (isPickUp, isReturn, pickUpPointType) => {
    if (isPickUp) {
        return isReturn ? TransfersAutocompleterRequestFor.Airports : TransfersAutocompleterRequestFor.All;
    }
    return pickUpPointType && pickUpPointType === PickUpDropOffType.Airport
        ? TransfersAutocompleterRequestFor.Resorts
        : TransfersAutocompleterRequestFor.Airports;
};
