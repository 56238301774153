import React, { useMemo } from 'react';
import { useRenderCount, useGlobalContext, useAirportOptionCache, TripType, DateSearchType, FlightJourneyType, } from '@honeycomb/data';
import { Container, Paper, useBreakpoints } from '@honeycomb/ui-core';
import { useSearchContext } from './SearchContext';
import { getOccupancySummary, getPassengerOccupancySummary } from '../../utils/getOccupancySummary';
import { getDate, getDateSummary } from '../../utils/getDateSummary';
import { getSelectedOptionSummary } from '../../utils/getSelectedOptionSummary';
import { getCabinDisplay } from '../../utils/getCabinDisplay';
import { CollapsedSearchSummary } from './components/CollapsedSearchSummary';
import { CollapsedSearchBar } from './components/CollapsedSearchBar';
import { getDestinationSummary } from './holiday-hotel/MultiSearchDestinationAutocompleter';
import { formatDateMonthYear } from '../../utils/formatDate';
export function CollapsedSearch({ tripType, loading, onClick, stuck }) {
    const { searchState } = useSearchContext();
    const { largeUp } = useBreakpoints();
    const getAirportOptionCache = useAirportOptionCache();
    const { destinationTitle, hotelTitle } = searchState;
    const selectedDepartureAirportCodes = (searchState.departureAirports || []).filter((code) => !!code);
    const selectedDepartureAirportOptions = selectedDepartureAirportCodes.map((code) => getAirportOptionCache(code));
    const selectedArrivalAirportCodes = (searchState.arrivalAirports || []).filter((code) => !!code);
    const selectedArrivalAirportOptions = selectedArrivalAirportCodes.map((code) => getAirportOptionCache(code));
    const isOneWayFlightSearch = searchState.flightJourneyType === FlightJourneyType.Oneway && searchState.tripType === TripType.Flight;
    const { resourceStrings: { ADULT, ADULTS, CHILD, CHILDREN, ROOM, ROOMS, HC_CABIN_ECONOMY, HC_CABIN_PREMIUM_ECONOMY, HC_CABIN_BUSINESS, HC_CABIN_FIRST, SEARCH_DESTINATION_FIELD_LABEL, SEARCH_DEPARTURE_FIELD_LABEL, SEARCH_ARRIVAL_FIELD_LABEL, SEARCH_DATES_FIELD_LABEL_HOTELONLY, SEARCH_DATES_FIELD_LABEL_HOLIDAY, SINGLENIGHT, NIGHTS, }, } = useGlobalContext();
    // !! DEV-Only !! Counts and prints number of renders for component
    useRenderCount('Collapsed Search');
    const cabinText = useMemo(() => {
        if (tripType === TripType.Hotel) {
            return '';
        }
        return getCabinDisplay(Number(searchState.cabin), {
            HC_CABIN_ECONOMY,
            HC_CABIN_PREMIUM_ECONOMY,
            HC_CABIN_BUSINESS,
            HC_CABIN_FIRST,
        });
    }, [tripType, searchState.cabin, HC_CABIN_ECONOMY, HC_CABIN_PREMIUM_ECONOMY, HC_CABIN_BUSINESS, HC_CABIN_FIRST]);
    const occupancySummary = useMemo(() => getOccupancySummary(searchState.rooms, { ADULT, ADULTS, CHILD, CHILDREN, ROOM, ROOMS }, cabinText) || '', [ADULT, ADULTS, CHILD, CHILDREN, ROOM, ROOMS, searchState.rooms, cabinText]);
    const passengerSummary = useMemo(() => {
        return getPassengerOccupancySummary(searchState.adults || 0, searchState.childAges || [], cabinText, {
            ADULT,
            ADULTS,
            CHILD,
            CHILDREN,
            ROOM,
            ROOMS,
        });
    }, [ADULT, ADULTS, CHILD, CHILDREN, ROOM, ROOMS, cabinText, searchState.adults, searchState.childAges]);
    const fields = useMemo(() => {
        const other = [];
        const startDate = searchState.startDate ? new Date(searchState.startDate) : undefined;
        const endDate = searchState.endDate ? new Date(searchState.endDate) : undefined;
        if (tripType !== TripType.Flight) {
            other.push({
                key: 'destination',
                placeholder: SEARCH_DESTINATION_FIELD_LABEL,
                value: destinationTitle || hotelTitle || getDestinationSummary(searchState) || '',
            });
        }
        if (tripType !== TripType.Hotel) {
            other.push({
                key: 'departure',
                placeholder: SEARCH_DEPARTURE_FIELD_LABEL,
                value: getSelectedOptionSummary(selectedDepartureAirportOptions, !largeUp),
            });
        }
        if (tripType === TripType.Flight) {
            other.push({
                key: 'arrival',
                placeholder: SEARCH_ARRIVAL_FIELD_LABEL,
                value: getSelectedOptionSummary(selectedArrivalAirportOptions, !largeUp),
            });
        }
        const wholeMonthStartDate = searchState.wholeMonthStartDate
            ? new Date(searchState.wholeMonthStartDate)
            : undefined;
        const flexibleDateNightsSummary = wholeMonthStartDate
            ? `${formatDateMonthYear(wholeMonthStartDate)} , ${searchState.duration} ${searchState.duration === 1 ? SINGLENIGHT : NIGHTS}`
            : '';
        return [
            ...other,
            ...[
                {
                    key: 'dates',
                    placeholder: tripType === TripType.Hotel
                        ? SEARCH_DATES_FIELD_LABEL_HOTELONLY
                        : SEARCH_DATES_FIELD_LABEL_HOLIDAY,
                    value: searchState.dateSearchType === DateSearchType.ChooseDate
                        ? isOneWayFlightSearch
                            ? getDate(startDate, largeUp)
                            : getDateSummary(startDate, endDate, largeUp)
                        : flexibleDateNightsSummary,
                },
                {
                    key: 'occupancy',
                    placeholder: '',
                    value: tripType !== TripType.Flight ? occupancySummary : passengerSummary,
                },
            ],
        ];
    }, [
        searchState,
        tripType,
        SINGLENIGHT,
        NIGHTS,
        SEARCH_DATES_FIELD_LABEL_HOTELONLY,
        SEARCH_DATES_FIELD_LABEL_HOLIDAY,
        isOneWayFlightSearch,
        occupancySummary,
        passengerSummary,
        SEARCH_DESTINATION_FIELD_LABEL,
        destinationTitle,
        hotelTitle,
        SEARCH_DEPARTURE_FIELD_LABEL,
        selectedDepartureAirportOptions,
        largeUp,
        SEARCH_ARRIVAL_FIELD_LABEL,
        selectedArrivalAirportOptions,
    ]);
    return (React.createElement(Paper, { radius: "s", py: { xs: 2, l: 3 }, pos: "relative", zIndex: 1, btlr: 0, btrr: 0, bw: "0 0 2px 0", bc: "border", bs: "solid", shadow: stuck ? 'm' : undefined, onClick: () => {
            if (onClick) {
                onClick();
            }
        } },
        React.createElement(Container, null, largeUp ? (React.createElement(CollapsedSearchBar, { fields: fields, loading: loading })) : (React.createElement(CollapsedSearchSummary, { fields: {
                top: fields.slice(0, fields.length - 2).filter((field) => Boolean(field.value)),
                bottom: fields.slice(-2).filter((field) => Boolean(field.value)),
            }, loading: loading })))));
}
