import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRenderCount } from '@honeycomb/data';
import { Styler } from '@honeycomb/ui-core';
export function StickySearch({ children, searchRef, inView, onClick }) {
    const scrollToSearch = useCallback(() => {
        var _a;
        (_a = searchRef === null || searchRef === void 0 ? void 0 : searchRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
    }, [searchRef]);
    // !! DEV-Only !! Counts and prints number of renders for component
    useRenderCount('Sticky Search');
    const scrollVal = useRef(0);
    const [scrollDir, setScrollDir] = useState(false);
    const handleScroll = useCallback(() => {
        const newVal = window.pageYOffset || document.documentElement.scrollTop;
        setScrollDir(newVal < scrollVal.current);
        scrollVal.current = newVal;
    }, []);
    useEffect(() => {
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);
    return (React.createElement(Styler, { pos: "fixed", top: `${-100 * (inView || !scrollDir ? 1 : 0)}px`, w: "100%", left: 0, transition: "top 0.5s", zIndex: 20, onClick: () => {
            scrollToSearch();
            if (onClick) {
                onClick();
            }
        } }, children));
}
