import React, { useEffect } from 'react';
import { useGlobalContext, SearchFieldEnum, Cabin } from '@honeycomb/data';
import { ButtonField, Stacker, SelectField, TypographyHeading, Paper, TypographyBody, PopoverAnchor, } from '@honeycomb/ui-core';
import { getOccupancySummary, getOccupancyTotals } from '../../../utils/getOccupancySummary';
import { getCabinDisplay } from '../../../utils/getCabinDisplay';
import { interjectResourceString } from '../../../utils/interjectResourceString';
import { AdultSelector } from '../components/AdultSelector';
import { ChildSelector } from '../components/ChildSelector';
import { CabinSelector } from '../components/CabinSelector';
import { SearchDisplayType, useSearchContext } from '../SearchContext';
import { SearchDispatchActionType } from '../SearchReducer';
import { FieldDialog } from '../components/FieldDialog';
import { FieldErrorMessage } from '../components/FieldErrorMessage';
import { OccupancyHelpMessage } from '../components/OccupancyHelpMessage';
import { OccupancyTotals } from '../components/OccupancyTotals';
import { SearchFieldPopover } from '../components/SearchFieldPopover/SearchFieldPopover';
import { SearchFieldPopoverPanel } from '../components/SearchFieldPopover/SearchFieldPopoverPanel';
import { SEARCH_FIELD_POPOVER_INNER_PADDING } from '../../../utils/style-constants';
export function RoomSelector({ state, actions }) {
    var _a, _b;
    const { popupPlacement = 'bottom-end', isHoliday, isFlight } = state;
    const { onDone } = actions;
    const { openField, onOpen, onClose, displayType, searchDispatch, searchState, fieldValidity, searchError } = useSearchContext();
    const { resourceStrings: { ADULT, ADULTS, CHILD, CHILDREN, DATEPICKER_CHILD_VALIDATION_MSG, ROOM, ROOMS, SEARCH_OCCUPANCY_FIELD_TITLE, SEARCH_OCCUPANCY_ROOM_TITLE, SEARCH_STEP_DONEBUTTONLABEL, CABIN_CLASS, HC_CABIN_ECONOMY, HC_CABIN_PREMIUM_ECONOMY, HC_CABIN_BUSINESS, HC_CABIN_FIRST, }, configSettings: { HotelHolidayMaxRooms, HotelHolidayMaxAdultsPerRoom, HotelHolidayMaxChildrenPerRoom, HotelHolidayMaxChildAge, HotelHolidayDefaultAdultsPerRoom, }, } = useGlobalContext();
    const defaultRoom = {
        adults: parseInt(HotelHolidayDefaultAdultsPerRoom),
        childAges: [],
    };
    const roomOptions = [...Array(parseInt(HotelHolidayMaxRooms) + 1).keys()].slice(1);
    const childAgeOptions = [...Array(parseInt(HotelHolidayMaxChildAge) + 1).keys()];
    const [cabin, setCabin] = React.useState(String(Cabin.Economy));
    // Is the search currently open on this field
    const open = openField === SearchFieldEnum.ROOMS;
    const validRooms = ((_a = fieldValidity[SearchFieldEnum.ROOMS]) === null || _a === void 0 ? void 0 : _a.valid) || false;
    const error = ((_b = fieldValidity[SearchFieldEnum.ROOMS]) === null || _b === void 0 ? void 0 : _b.error) || false;
    const { rooms } = searchState;
    const totals = getOccupancyTotals(rooms);
    const totalsSummary = [
        { caption: ROOMS, value: totals.rooms },
        { caption: ADULTS, value: totals.adults },
        { caption: CHILDREN, value: totals.children },
    ];
    const popup = displayType === SearchDisplayType.POPUP;
    const dialog = displayType === SearchDisplayType.DIALOG;
    const [scrollingPopoverElement, setScrollingPopoverElement] = React.useState(null);
    // Resetting the cabin state with current search state
    useEffect(() => {
        setCabin(searchState.cabin ? String(searchState.cabin) : String(Cabin.Economy));
    }, [searchState.cabin]);
    const setNumberOfRooms = (value) => {
        if (rooms.length < value) {
            searchDispatch({
                type: SearchDispatchActionType.SET_ROOMS,
                roomsValue: [...rooms, ...Array(value - rooms.length).fill(defaultRoom)],
            });
        }
        else if (rooms.length > value) {
            searchDispatch({
                type: SearchDispatchActionType.SET_ROOMS,
                roomsValue: rooms.slice(0, value),
            });
        }
    };
    const setNumberOfAdults = (value, roomIndex) => {
        if (roomIndex === undefined)
            return;
        const newRooms = rooms.map((room, idx) => roomIndex === idx
            ? Object.assign(Object.assign({}, room), { adults: value }) : room);
        searchDispatch({
            type: SearchDispatchActionType.SET_ROOMS,
            roomsValue: newRooms,
        });
    };
    const setNumberOfChildren = (value, roomIndex) => {
        if (roomIndex === undefined)
            return;
        const oldChildAgesLength = rooms[roomIndex].childAges.length;
        let newChildAges = [];
        if (value - oldChildAgesLength > 0) {
            newChildAges = [...rooms[roomIndex].childAges, ...Array(value - oldChildAgesLength).fill(undefined)];
        }
        else {
            newChildAges = rooms[roomIndex].childAges.slice(0, value);
        }
        const newRooms = rooms.map((room, idx) => roomIndex === idx
            ? Object.assign(Object.assign({}, room), { childAges: newChildAges }) : room);
        searchDispatch({
            type: SearchDispatchActionType.SET_ROOMS,
            roomsValue: newRooms,
        });
    };
    const setSelectedChildAge = (childAge, childIndex, roomIndex) => {
        if (roomIndex === undefined)
            return;
        const updatedChildAges = [...rooms[roomIndex].childAges];
        updatedChildAges[childIndex] = childAge;
        const newRooms = rooms.map((room, idx) => roomIndex === idx
            ? Object.assign(Object.assign({}, room), { childAges: updatedChildAges }) : room);
        searchDispatch({
            type: SearchDispatchActionType.SET_ROOMS,
            roomsValue: newRooms,
        });
    };
    const roomsField = (React.createElement("div", null,
        React.createElement(TypographyHeading, { size: "2xs", weight: "bold", component: "label", htmlFor: "rooms-select", mb: 2 }, "How many rooms do you need?"),
        React.createElement(SelectField, { size: "s", fullWidth: true, id: "rooms-select", name: "rooms-select", value: rooms.length.toString(), onChange: (event) => {
                setNumberOfRooms(Number(event.currentTarget.value));
            } }, roomOptions.map((val) => {
            return (React.createElement("option", { value: val.toString(), key: `rooms-select-option-${val.toString()}` }, val));
        }))));
    const roomDetailsPanel = (roomIndex) => (React.createElement("div", { key: `room_panel_${roomIndex}` },
        rooms.length > 1 && (React.createElement(TypographyHeading, { size: "2xs", mb: 3 }, interjectResourceString(SEARCH_OCCUPANCY_ROOM_TITLE, (roomIndex + 1).toString()))),
        React.createElement(Stacker, { spacing: 6 },
            React.createElement(AdultSelector, { state: {
                    maxAdults: parseInt(HotelHolidayMaxAdultsPerRoom),
                    adults: rooms[roomIndex].adults,
                    minAdultAge: parseInt(HotelHolidayMaxChildAge) + 1,
                    roomIndex,
                }, setNumberOfAdults: setNumberOfAdults }),
            React.createElement(ChildSelector, { state: {
                    childAgeOptions,
                    childAges: rooms[roomIndex].childAges,
                    error,
                    maxChildAge: parseInt(HotelHolidayMaxChildAge),
                    maxChildren: parseInt(HotelHolidayMaxChildrenPerRoom),
                    roomIndex,
                }, setNumberOfChildren: setNumberOfChildren, setSelectedChildAge: setSelectedChildAge }))));
    const content = (React.createElement(Stacker, { spacing: SEARCH_FIELD_POPOVER_INNER_PADDING },
        React.createElement(Paper, { radius: "s", py: 4, px: 3, border: 0 },
            React.createElement(Stacker, { spacing: 6 },
                !isFlight ? roomsField : '',
                rooms.map((_room, idx) => roomDetailsPanel(idx)))),
        (isHoliday || isFlight) && (React.createElement(CabinSelector, { state: { cabin, id: 'cabin-select' }, onChange: (value) => {
                if (value) {
                    setCabin(value);
                    searchDispatch({
                        type: SearchDispatchActionType.SET_CABIN_CLASS,
                        cabinValue: Number(value),
                    });
                }
            } })),
        React.createElement(OccupancyHelpMessage, null)));
    const cabinText = isHoliday
        ? getCabinDisplay(Number(cabin), {
            HC_CABIN_ECONOMY,
            HC_CABIN_PREMIUM_ECONOMY,
            HC_CABIN_BUSINESS,
            HC_CABIN_FIRST,
        })
        : '';
    const summaryText = getOccupancySummary(rooms, { ADULT, ADULTS, CHILD, CHILDREN, ROOM, ROOMS }, cabinText);
    const label = isHoliday ? `${SEARCH_OCCUPANCY_FIELD_TITLE} & ${CABIN_CLASS}` : SEARCH_OCCUPANCY_FIELD_TITLE;
    const fieldError = (React.createElement(FieldErrorMessage, { error: error, message: DATEPICKER_CHILD_VALIDATION_MSG, "data-id": "rooms-error-message" }));
    const handleClickOrFocus = () => {
        if (!open) {
            onOpen(SearchFieldEnum.ROOMS);
        }
    };
    const handleReset = () => {
        searchDispatch({
            type: SearchDispatchActionType.RESET_OCCUPANCY,
        });
    };
    const button = (React.createElement(ButtonField, { label: label, value: summaryText, onClick: handleClickOrFocus, onFocus: () => popup && handleClickOrFocus(), fullWidth: true, error: error, size: { xs: 'm', m: 'l' }, variant: "alternative" }));
    return (React.createElement(React.Fragment, null,
        popup && (React.createElement(SearchFieldPopover, { open: open, onClose: () => onClose(), placement: popupPlacement, dynamicHeight: true, error: searchError, scrollingElement: scrollingPopoverElement },
            React.createElement(PopoverAnchor, null, button),
            React.createElement(SearchFieldPopoverPanel, { width: 375, scrollingElementRef: (ref) => setScrollingPopoverElement(ref) }, content))),
        dialog && (React.createElement(React.Fragment, null,
            button,
            React.createElement(FieldDialog, { open: open, onClose: onClose, title: label, ctaProps: {
                    onClick: onDone,
                    label: SEARCH_STEP_DONEBUTTONLABEL,
                    disabled: !validRooms,
                }, resetProps: {
                    onClick: handleReset,
                    label: 'Reset',
                }, headerContent: error ? (React.createElement(Paper, { radius: "s", p: 2, border: 0 },
                    React.createElement(TypographyBody, { size: "s", color: "system.error.base" }, DATEPICKER_CHILD_VALIDATION_MSG))) : undefined, footerContent: React.createElement(OccupancyTotals, { totals: totalsSummary }) }, content))),
        fieldError));
}
