var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CrossIcon, Dialog2, IconButton, Paper } from '@honeycomb/ui-core';
import React from 'react';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
const VideoModalTheme = {
    width: '800px',
};
export function VideoModal(props) {
    const { videoSrc, open, onClose } = props, other = __rest(props, ["videoSrc", "open", "onClose"]);
    return (React.createElement(Dialog2, Object.assign({ open: open, onClose: onClose, size: VideoModalTheme.width, cornerStyle: "square", SurfaceProps: { overflow: 'visible', bg: 'transparent', shadow: 'none' } }, other),
        React.createElement(IconButton, { pos: "absolute", top: "-36px", right: 0, zIndex: 1, color: "white", size: "s" },
            React.createElement(CrossIcon, { onClick: onClose })),
        React.createElement(Paper, { radius: "s", w: "100%", h: "100%", overflow: "hidden", shadow: "l" },
            React.createElement(VideoPlayer, { videoUrl: videoSrc }))));
}
