import React, { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { usePrevious, SearchFieldEnum, useGlobalContext, PickUpDropOffType, useTransfersAutoCompleterResults, } from '@honeycomb/data';
import { Autocompleter, AutocompleterCategory, AutocompleterField, AutocompleterList, AutocompleterOption, AutocompleterResults, LocationIcon, ButtonField, SearchIcon, FlexGrid, PlaneIcon, AirconIcon, PopoverAnchor, } from '@honeycomb/ui-core';
import { SearchFieldPopover } from '../components/SearchFieldPopover/SearchFieldPopover';
import { SearchFieldPopoverPanel } from '../components/SearchFieldPopover/SearchFieldPopoverPanel';
import { SearchDisplayType, useSearchContext } from '../SearchContext';
import { SearchDispatchActionType } from '../SearchReducer';
import { AutocompleterSkeleton } from '../components/AutocompleterSkeleton';
import { AutocompleterNoMatches } from '../components/AutocompleterNoMatches';
import { FieldDialog } from '../components/FieldDialog';
import { FieldErrorMessage } from '../components/FieldErrorMessage';
import { pluraliseCount } from '../../../utils/pluraliseCount';
import { getTransferSearchType, mapAirportToOption, mapResortToOption, parseAutocompleterValue, toAutocompleterValue, } from './transferUtil';
import { useDebounce } from '../../../utils/useDebounce';
// Define the function to build the list of options for a given set of airports/resorts
const buildOptionsList = (options, labelledById) => {
    return (React.createElement(AutocompleterList, { labelledById: labelledById }, options.map((option) => {
        const { id, value, title, type, subTitle, code } = option;
        return (React.createElement(AutocompleterOption, { key: `option-${id}-${uuid}`, id: id, value: value, title: title, subtitle: subTitle, appendix: code, icon: type === PickUpDropOffType.Airport ? React.createElement(PlaneIcon, null) : React.createElement(LocationIcon, null), searchTermMatchedTo: ['title', 'subtitle', 'appendix'] }));
    })));
};
const autocompleterCategory = (title, label, singular, plural, option) => (React.createElement(AutocompleterCategory, { title: title, appendix: pluraliseCount(option.length, {
        singular,
        plural,
    }), icon: React.createElement(AirconIcon, null), id: "category-airports", key: "airports" }, option.length > 0 ? buildOptionsList(option, label) : undefined));
export function PickUpDropOffAutocompleter({ searchClicked, popupPlacement, fieldType, onDone, onDropOff, setSearchClicked, isReturn = true, setReverse, }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { openField, onOpen, onClose, displayType, searchDispatch, searchState, fieldValidity, searchError } = useSearchContext();
    const { resourceStrings: { AIRPORT, POPULAR_AIRPORTS, POPULAR_RESORTS, SEARCH_DROPOFF_POINT_ERROR_EMPTY, SEARCH_DROPOFF_POINT_FIELD_LABEL, SEARCH_PICKUP_POINT_ERROR_EMPTY, SEARCH_PICKUP_POINT_FIELD_LABEL, RESORT_POPUP_TITLE, RESULT_COUNT_MESSAGE, RESULTS_COUNT_MESSAGE, SEARCH_AIRPORTS_FIELD_PLACEHOLDER, SEARCH_RESORT_FIELD_PLACEHOLDER, SEARCH_PICKUP_POINT_FIELD_PLACEHOLDER, }, configSettings: { TransferAutoCompleterMaxResults }, } = useGlobalContext();
    const [searchTerm, setSearchTerm] = React.useState('');
    const [fetchMoreDestinations, pickUpDropOffResults] = useTransfersAutoCompleterResults();
    const { error: errorProp, previousData, loading, called, data } = pickUpDropOffResults;
    const isPickUp = fieldType === SearchFieldEnum.PICKUPPOINT;
    const requestFor = getTransferSearchType(isPickUp, isReturn, (_b = (_a = searchState.pickUpPoint) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : undefined);
    const fetchResults = useCallback((term) => {
        var _a, _b;
        return fetchMoreDestinations({
            searchTerm: term !== null && term !== void 0 ? term : '',
            maxResults: (_a = parseInt(TransferAutoCompleterMaxResults)) !== null && _a !== void 0 ? _a : 15,
            filterBy: !isPickUp ? (_b = searchState.pickUpPoint) === null || _b === void 0 ? void 0 : _b.id : undefined,
            requestFor,
            isPickUp,
        });
    }, [TransferAutoCompleterMaxResults, fetchMoreDestinations, isPickUp, requestFor, (_c = searchState.pickUpPoint) === null || _c === void 0 ? void 0 : _c.id]);
    const fetchDestinations = useDebounce(fetchResults, searchTerm === '' ? 0 : 300);
    const autocompleterListId = `${fieldType}-autocompleter-list`;
    const [scrollingPopoverElement, setScrollingPopoverElement] = React.useState(null);
    const [updateId, setUpdateId] = React.useState('');
    const dispatchAction = isPickUp
        ? SearchDispatchActionType.SET_PICKUP_POINT
        : SearchDispatchActionType.SET_DROPOFF_POINT;
    const open = openField === fieldType;
    const error = ((_d = fieldValidity[fieldType]) === null || _d === void 0 ? void 0 : _d.error) || false;
    const searchTitle = (isPickUp ? (_e = searchState.pickUpPoint) === null || _e === void 0 ? void 0 : _e.name : (_f = searchState.dropOffPoint) === null || _f === void 0 ? void 0 : _f.name) || '';
    // Get the data and store the previous instance to smoothly switch when it changes
    const prevOpen = usePrevious(open);
    const prevUpdateId = usePrevious(updateId);
    const isPopup = displayType === SearchDisplayType.POPUP;
    const isDialog = displayType === SearchDisplayType.DIALOG;
    useEffect(() => {
        var _a;
        if (isPickUp && isReturn && ((_a = searchState.pickUpPoint) === null || _a === void 0 ? void 0 : _a.type) === PickUpDropOffType.Resort) {
            searchDispatch({
                type: SearchDispatchActionType.SET_PICKUP_POINT,
            });
            searchDispatch({
                type: SearchDispatchActionType.SET_DROPOFF_POINT,
            });
        }
    }, [isPickUp, isReturn, searchDispatch, (_g = searchState.pickUpPoint) === null || _g === void 0 ? void 0 : _g.type]);
    const airports = loading && previousData
        ? ((previousData === null || previousData === void 0 ? void 0 : previousData.getTransfersAutocompleterResults.airports) || []).map(mapAirportToOption)
        : ((data === null || data === void 0 ? void 0 : data.getTransfersAutocompleterResults.airports) || []).map(mapAirportToOption);
    const resorts = loading && previousData
        ? ((previousData === null || previousData === void 0 ? void 0 : previousData.getTransfersAutocompleterResults.resorts) || []).map(mapResortToOption)
        : ((data === null || data === void 0 ? void 0 : data.getTransfersAutocompleterResults.resorts) || []).map(mapResortToOption);
    const matchesFound = airports.length > 0 || resorts.length > 0;
    const stateVal = isPickUp ? searchState.pickUpPoint : searchState.dropOffPoint;
    useEffect(() => {
        if (prevOpen && !open) {
            if (!(stateVal === null || stateVal === void 0 ? void 0 : stateVal.id)) {
                searchDispatch({
                    type: dispatchAction,
                });
            }
        }
    }, [dispatchAction, open, prevOpen, searchDispatch, stateVal === null || stateVal === void 0 ? void 0 : stateVal.id]);
    useEffect(() => {
        if (updateId && updateId !== prevUpdateId) {
            if (onDone) {
                onDone();
            }
            else {
                onClose();
            }
        }
    }, [onClose, onDone, prevUpdateId, updateId]);
    // Define the functions for the autocompleter to work
    const handleFieldChange = useCallback((event) => {
        const { value } = event.currentTarget;
        setSearchTerm(value);
        setSearchClicked(false);
    }, [setSearchClicked]);
    const handleAdd = useCallback((value, title) => {
        var _a;
        const selectedValue = parseAutocompleterValue(value);
        if ((stateVal === null || stateVal === void 0 ? void 0 : stateVal.id) === selectedValue.id && (stateVal === null || stateVal === void 0 ? void 0 : stateVal.type) === selectedValue.type && onDone) {
            onDone();
        }
        else {
            searchDispatch({
                type: dispatchAction,
                pickDropPoint: {
                    id: selectedValue.id,
                    name: title,
                    type: selectedValue.type,
                },
            });
            // Note : If pickUpPoint is Resort, then pickUpPointId is currenlty locationId.To perform full search we need resortId instead of LocationId.
            // So while selecting dropOffPoint as Airport, need to update PickUpPoint Id as resortId. We are receing resortId with AirportData.
            // This is apply only when PickUpPoint is Resort and DropOffPoint is Airport.
            if (!isPickUp && selectedValue.type === PickUpDropOffType.Airport && selectedValue.resortId > 0) {
                searchDispatch({
                    type: SearchDispatchActionType.SET_PICKUP_POINT,
                    pickDropPoint: {
                        id: selectedValue.resortId.toString(),
                        name: (_a = searchState.pickUpPoint) === null || _a === void 0 ? void 0 : _a.name,
                        type: PickUpDropOffType.Resort,
                    },
                });
            }
            if (isPickUp) {
                searchDispatch({
                    type: SearchDispatchActionType.SET_DROPOFF_POINT,
                });
                setReverse(selectedValue.type === PickUpDropOffType.Resort);
            }
            // Trigger a render update to close/open next field with data which has just been set on next tick
            setUpdateId(uuid());
        }
    }, [
        dispatchAction,
        isPickUp,
        onDone,
        searchDispatch,
        (_h = searchState.pickUpPoint) === null || _h === void 0 ? void 0 : _h.name,
        setReverse,
        stateVal === null || stateVal === void 0 ? void 0 : stateVal.id,
        stateVal === null || stateVal === void 0 ? void 0 : stateVal.type,
    ]);
    const handleRemove = useCallback(() => {
        if (isPickUp) {
            searchDispatch({ type: SearchDispatchActionType.SET_PICKUP_POINT });
        }
        searchDispatch({ type: SearchDispatchActionType.SET_DROPOFF_POINT });
    }, [searchDispatch, isPickUp]);
    const resultsChildren = [];
    if (isPickUp) {
        resultsChildren.push(autocompleterCategory(searchTerm ? AIRPORT : POPULAR_AIRPORTS, 'category-airports', RESULT_COUNT_MESSAGE, RESULTS_COUNT_MESSAGE, airports));
        if (!isReturn) {
            resultsChildren.push(autocompleterCategory(searchTerm ? RESORT_POPUP_TITLE : POPULAR_RESORTS, 'category-resorts', RESULT_COUNT_MESSAGE, RESULTS_COUNT_MESSAGE, resorts));
        }
    }
    else if (!isPickUp && ((_j = searchState.pickUpPoint) === null || _j === void 0 ? void 0 : _j.id)) {
        if (((_k = searchState.pickUpPoint) === null || _k === void 0 ? void 0 : _k.type) === PickUpDropOffType.Resort) {
            resultsChildren.push(autocompleterCategory(AIRPORT, 'category-airports', RESULT_COUNT_MESSAGE, RESULTS_COUNT_MESSAGE, airports));
        }
        else {
            resultsChildren.push(autocompleterCategory(RESORT_POPUP_TITLE, 'category-resorts', RESULT_COUNT_MESSAGE, RESULTS_COUNT_MESSAGE, resorts));
        }
    }
    // Build the final results element including "no matches" case for empty searches or apollo errors
    const autoCompleterResults = (children) => {
        var _a;
        if (!called || loading) {
            return isPickUp && !isReturn ? (React.createElement(FlexGrid, { container: true, spacing: 4 },
                React.createElement(FlexGrid, { xs: 12, l: 6 },
                    React.createElement(AutocompleterSkeleton, null)),
                React.createElement(FlexGrid, { xs: 12, l: 6 },
                    React.createElement(AutocompleterSkeleton, null)))) : (React.createElement(AutocompleterSkeleton, null));
        }
        if (matchesFound) {
            return !isPickUp || (isPickUp && isReturn) ? (React.createElement(AutocompleterResults, { id: autocompleterListId }, children)) : (React.createElement(FlexGrid, { container: true, spacing: 4, disableEqualOverflow: true },
                React.createElement(FlexGrid, { xs: 12, l: 6 },
                    React.createElement(AutocompleterResults, { id: autocompleterListId }, [children[0]])),
                React.createElement(FlexGrid, { xs: 12, l: 6 },
                    React.createElement(AutocompleterResults, { id: autocompleterListId, startIndex: ((_a = airports.length) !== null && _a !== void 0 ? _a : 0) + 100 }, [children[1]]))));
        }
        return React.createElement(AutocompleterNoMatches, null);
    };
    const fieldError = (React.createElement(FieldErrorMessage, { error: error, message: isPickUp ? SEARCH_PICKUP_POINT_ERROR_EMPTY : SEARCH_DROPOFF_POINT_ERROR_EMPTY, "data-id": `${fieldType}-error-message` }));
    const commonFieldProps = useMemo(() => ({
        fullWidth: true,
        error,
        variant: 'alternative',
    }), [error]);
    const label = isPickUp ? SEARCH_PICKUP_POINT_FIELD_LABEL : SEARCH_DROPOFF_POINT_FIELD_LABEL;
    const handleClickOrFocus = () => {
        if (!open) {
            setSearchTerm('');
            onOpen(fieldType);
        }
        if (onDropOff && !isPickUp) {
            onDropOff();
        }
    };
    useEffect(() => {
        var _a;
        if (open) {
            if (((_a = searchState.pickUpPoint) === null || _a === void 0 ? void 0 : _a.id) && !isPickUp)
                fetchDestinations(searchTerm);
            if (isPickUp)
                fetchDestinations(searchTerm);
        }
    }, [fetchDestinations, fieldType, isPickUp, open, (_l = searchState.pickUpPoint) === null || _l === void 0 ? void 0 : _l.id, searchTerm]);
    const handleClearSearchTerm = () => {
        setSearchTerm('');
    };
    let placeholder = '';
    if (isPickUp) {
        placeholder = isReturn ? SEARCH_AIRPORTS_FIELD_PLACEHOLDER : SEARCH_PICKUP_POINT_FIELD_PLACEHOLDER;
    }
    else {
        placeholder =
            ((_m = searchState.pickUpPoint) === null || _m === void 0 ? void 0 : _m.type) === PickUpDropOffType.Airport
                ? SEARCH_RESORT_FIELD_PLACEHOLDER
                : SEARCH_AIRPORTS_FIELD_PLACEHOLDER;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Autocompleter, { fetch: fetchDestinations, onAdd: handleAdd, onRemove: handleRemove, selectedValue: toAutocompleterValue(searchState, fieldType) },
            React.createElement(React.Fragment, null,
                isPopup && open && (React.createElement(SearchFieldPopover, { open: open, onClose: () => onClose(), placement: popupPlacement, error: searchError, dynamicHeight: true, scrollingElement: scrollingPopoverElement },
                    React.createElement(PopoverAnchor, null,
                        React.createElement(AutocompleterField, Object.assign({ id: `${fieldType}-autocompleter-field`, listId: autocompleterListId, value: error && searchClicked ? '' : searchTerm, label: label, onChange: handleFieldChange, placeholder: placeholder, autoFocus: true, shrinkLabel: true, size: { xs: 'm', m: 'l' }, onButtonClick: handleClearSearchTerm }, commonFieldProps))),
                    React.createElement(SearchFieldPopoverPanel, { width: isPickUp && !isReturn ? 740 : 365, scrollingElementRef: (ref) => setScrollingPopoverElement(ref) }, autoCompleterResults(resultsChildren)))),
                !open && (
                // A button acts as a trigger to open the popup or dialog.
                React.createElement(ButtonField, Object.assign({ value: searchTitle, label: label, onClick: handleClickOrFocus, onFocus: () => handleClickOrFocus(), size: { xs: 'm', m: 'l' } }, commonFieldProps))),
                fieldError),
            isDialog && (React.createElement(FieldDialog, { open: open, onClose: onClose, title: label, headingProps: {
                    component: 'label',
                    htmlFor: `${fieldType}-autocompleter-field-dialog`,
                }, ctaProps: {
                    onClick: () => {
                        if (onDone) {
                            onDone();
                        }
                    },
                    label: 'Next',
                    disabled: isPickUp ? !((_o = searchState.pickUpPoint) === null || _o === void 0 ? void 0 : _o.id) : !((_p = searchState.dropOffPoint) === null || _p === void 0 ? void 0 : _p.id),
                }, headerContent: React.createElement(React.Fragment, null,
                    React.createElement(AutocompleterField, Object.assign({ id: `${fieldType}-autocompleter-field-dialog`, listId: autocompleterListId, value: searchTerm, placeholder: placeholder, onChange: handleFieldChange, autoFocus: true, startIcon: React.createElement(SearchIcon, null), onButtonClick: handleClearSearchTerm }, commonFieldProps)),
                    fieldError) }, autoCompleterResults(resultsChildren)))),
        errorProp && React.createElement("div", null, errorProp.message)));
}
