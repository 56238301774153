import { TripType } from '../../contracts/types';
import { AnywhereSearchResultEnum } from '../../util';
export function setRecentSearch(searchState) {
    var _a;
    if (!searchState)
        return;
    if (searchState.tripType !== TripType.Hotel && searchState.tripType !== TripType.Holiday)
        return;
    const localStorageData = [];
    let storedSearches = [];
    if ((_a = searchState.destinations) === null || _a === void 0 ? void 0 : _a.some((x) => `${x === null || x === void 0 ? void 0 : x.id}-${x === null || x === void 0 ? void 0 : x.type}` === `${AnywhereSearchResultEnum.ANYWHERE_ID}-${AnywhereSearchResultEnum.TYPE}`)) {
        return;
    }
    const destinationLocalStorage = searchState === null || searchState === void 0 ? void 0 : searchState.destinations;
    const recentSearch = typeof window !== 'undefined' && window.localStorage.getItem('recentSearches');
    const recentChanges = recentSearch ? JSON.parse(recentSearch) : {};
    if (destinationLocalStorage === null || destinationLocalStorage === void 0 ? void 0 : destinationLocalStorage.length) {
        destinationLocalStorage.forEach((val) => {
            localStorageData.push({
                id: Number(val === null || val === void 0 ? void 0 : val.id),
                title: String(val === null || val === void 0 ? void 0 : val.title),
                type: Number(val === null || val === void 0 ? void 0 : val.type),
                isCached: Boolean(val === null || val === void 0 ? void 0 : val.isCached),
                hotelCount: 0,
                subTitle: val === null || val === void 0 ? void 0 : val.subTitle,
            });
        });
    }
    if (recentChanges && (recentChanges === null || recentChanges === void 0 ? void 0 : recentChanges.length) > 0) {
        recentChanges.forEach((val) => {
            if (!localStorageData.some((i) => `${i.id}-${i.type}` === `${val.id}-${val.type}`))
                localStorageData.push({
                    id: Number(val.id),
                    title: String(val.title),
                    type: Number(val.type),
                    isCached: Boolean(val.isCached),
                    hotelCount: 0,
                    subTitle: val.subTitle,
                });
        });
    }
    storedSearches = localStorageData.slice(0, 6);
    if (storedSearches.length > 0 && typeof window !== 'undefined') {
        window.localStorage.setItem('recentSearches', JSON.stringify(storedSearches));
    }
}
export const getRecentSearch = () => {
    return typeof window !== 'undefined' && window.localStorage.getItem('recentSearches');
};
