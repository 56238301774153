import { AnywhereSearchResultEnum, removeTypename, TripType } from '@honeycomb/data';
import { persistTrackingData } from '../util';
import { initDataLayer } from '../util/initDataLayer';
import { isBrowser } from '../util/isBrowser';
import { passengerCount } from '../util/passengerCount';
export function pushSearchCriteria({ startDate, endDate, destinationIds, destinations, destinationTitle, hotelTitle, rooms, departureAirports, tripType, arrivalAirports, childAges, adults, }) {
    if (!isBrowser())
        return;
    initDataLayer();
    window.dataLayer.push({
        searchForm: {
            rooms: null, // clear down rooms to prevent GTM merging new data with old
        },
    });
    const anywhereSearch = (destinations === null || destinations === void 0 ? void 0 : destinations.some((destination) => (destination === null || destination === void 0 ? void 0 : destination.id) === AnywhereSearchResultEnum.ANYWHERE_ID)) || false;
    const isFlight = tripType === TripType.Flight;
    const message = {
        searchForm: {
            startDate,
            endDate,
            departureAirports,
            tripType,
            arrivalAirports,
            anywhereSearch,
            destinationIds: isFlight ? undefined : destinationIds,
            destinationTitle: isFlight ? undefined : destinationTitle,
            destinations: isFlight ? undefined : destinations === null || destinations === void 0 ? void 0 : destinations.map((destination) => destination === null || destination === void 0 ? void 0 : destination.title),
            hotelTitle: isFlight ? undefined : hotelTitle,
            rooms: isFlight ? undefined : rooms.map((room) => removeTypename(room)),
            passengers: isFlight ? passengerCount(adults, childAges) : undefined,
        },
    };
    window.dataLayer.push(message);
    persistTrackingData(message);
}
