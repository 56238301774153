//! Todo update with generic recursive return type
export function removeTypename(obj) {
    const pickedKey = '__typename';
    if (Array.isArray(obj)) {
        return obj.map((item) => removeTypename(item));
    }
    if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).reduce((previousValue, key) => {
            return pickedKey === key ? previousValue : Object.assign(Object.assign({}, previousValue), { [key]: removeTypename(obj[key]) });
        }, {});
    }
    return obj;
}
