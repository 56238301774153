var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { TransfersAutocompleterRequestFor, } from '../../contracts/types';
export function useTransfersAutoCompleterResults() {
    const TRANSFERS_AUTOCOMPLETER_RESULTS_QUERY = gql `
        query transfersAutoCompleterResults($request: TransfersAutoCompleterRequest!) {
            getTransfersAutocompleterResults(request: $request) {
                airports {
                    type
                    title
                    airportCode
                    country
                    resortId
                    locationId
                }
                resorts {
                    type
                    title
                    airportCode
                    country
                    resortId
                    locationId
                }
            }
        }
    `;
    const [getPickUpDropOffQuery, pickUpDropOffResults] = useLazyQuery(TRANSFERS_AUTOCOMPLETER_RESULTS_QUERY, {
        notifyOnNetworkStatusChange: true,
        ssr: false,
        variables: {
            request: {
                searchTerm: '',
                maxResults: 15,
                filterBy: '',
                requestFor: TransfersAutocompleterRequestFor.All,
                isPickUp: false,
            },
        },
    });
    const getpickUpDropOff = useCallback((request) => __awaiter(this, void 0, void 0, function* () {
        const { data } = yield getPickUpDropOffQuery({ variables: { request } });
        return data;
    }), [getPickUpDropOffQuery]);
    return [getpickUpDropOff, pickUpDropOffResults];
}
