import React from 'react';
import { ArrowRightLongIcon, SelectMenu, SelectMenuOption, SyncIcon } from '@honeycomb/ui-core';
import { FlightJourneyType, useGlobalContext } from '@honeycomb/data';
import { useSearchContext } from '../SearchContext';
import { SearchDispatchActionType } from '../SearchReducer';
export function FlightJourneySelector() {
    const { resourceStrings: { FLIGHT_OPTION_RETURN, FLIGHT_OPTION_ONE_WAY }, } = useGlobalContext();
    const { searchDispatch, searchState } = useSearchContext();
    const isReturnFlightSearch = (searchState === null || searchState === void 0 ? void 0 : searchState.flightJourneyType) === FlightJourneyType.Return;
    const onFlightJourneyChange = (value) => {
        const updatedFlightJourneyType = value;
        searchDispatch({
            type: SearchDispatchActionType.SET_FLIGHT_JOURNEY_TYPE,
            flightJourneyType: updatedFlightJourneyType,
        });
    };
    return (React.createElement(SelectMenu, { value: isReturnFlightSearch ? FlightJourneyType.Return : FlightJourneyType.Oneway, onChange: onFlightJourneyChange, defaultValue: FlightJourneyType.Return, icon: isReturnFlightSearch ? React.createElement(SyncIcon, null) : React.createElement(ArrowRightLongIcon, null) },
        React.createElement(SelectMenuOption, { value: FlightJourneyType.Return, key: FlightJourneyType.Return }, FLIGHT_OPTION_RETURN),
        React.createElement(SelectMenuOption, { value: FlightJourneyType.Oneway, key: FlightJourneyType.Oneway }, FLIGHT_OPTION_ONE_WAY)));
}
