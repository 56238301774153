import React, { useState } from 'react';
import { Styler, SvgIconBaseProps, ScrollArea, StylerProps, TypographyBody, useDisclosure } from '@honeycomb/ui-core';
import { Usp as USPType, UspItem as USPItemType } from '@honeycomb/data';
import dynamic from 'next/dynamic';
import { USPDialog } from '@honeycomb/ui';

export type USPGroupProps = {
    uspGroup: USPType;
};

export function getIcon(name: string): React.ReactElement {
    switch (name) {
        case 'twenty-four-seven': {
            const Icon = dynamic<StylerProps>(
                () => import('../../USPIcons/TwentyFourSeven').then((module) => module.TwentyFourSeven),
                { ssr: false }
            ) as React.FunctionComponent<StylerProps>;
            return <Icon />;
        }
        case 'sustainable': {
            const Icon = dynamic<StylerProps>(
                () => import('../../USPIcons/Sustainable').then((module) => module.Sustainable),
                { ssr: false }
            ) as React.FunctionComponent<StylerProps>;
            return <Icon />;
        }
        case 'flexible': {
            const Icon = dynamic<StylerProps>(
                () => import('../../USPIcons/Flexible').then((module) => module.Flexible),
                { ssr: false }
            ) as React.FunctionComponent<StylerProps>;
            return <Icon />;
        }
        case 'points': {
            const Icon = dynamic<StylerProps>(() => import('../../USPIcons/Points').then((module) => module.Points), {
                ssr: false,
            }) as React.FunctionComponent<StylerProps>;
            return <Icon />;
        }
        case 'easy-payment': {
            const Icon = dynamic<StylerProps>(
                () => import('../../USPIcons/EasyPayment').then((module) => module.EasyPayment),
                { ssr: false }
            ) as React.FunctionComponent<StylerProps>;
            return <Icon />;
        }

        default: {
            const Icon = dynamic<SvgIconBaseProps>(() =>
                import('@honeycomb/ui-core').then((module) => module.HeartIcon)
            ) as React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<SvgIconBaseProps>>>;
            return <Icon />;
        }
    }
}

const ITEM_PADDING = 5;

type USPItemProps = {
    item: USPItemType;
    index: number;
    onItemClick: (contentId: string, title: string) => void;
};

function USPItem(props: USPItemProps): React.JSX.Element {
    const { item, index, onItemClick } = props;
    const { icon, title, contentId } = item;

    return (
        <Styler
            button={true}
            onClick={() => onItemClick(contentId, title)}
            data-id="usp"
            key={contentId}
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            px={ITEM_PADDING}
            w={240}
        >
            {icon && (
                <Styler data-id={`usp-icon-${index}`} mr={2}>
                    {getIcon(icon?.toLowerCase())}
                </Styler>
            )}
            <TypographyBody size="xs" weight="semiBold" data-id={`usp-title-${index}`}>
                {title}
            </TypographyBody>
        </Styler>
    );
}

export function USPs({ uspGroup }: USPGroupProps): React.JSX.Element {
    const { items } = uspGroup;

    const [selectedItemContentId, setSelectedItemContentId] = useState<number | null>(null);

    const [dialogOpen, { open: handleOpenDialog, close: handleCloseDialog }] = useDisclosure(false);
    const [dialogExited, setDialogExited] = React.useState<boolean>(false);

    const [dialogTitle, setDialogTitle] = useState<string>('');

    const handleItemClick = (contentId: string, title: string) => {
        if (contentId && Number(contentId) > 0) {
            setSelectedItemContentId(Number(contentId));
            setDialogTitle(title);
            setDialogExited(false);
            handleOpenDialog();
        }
    };

    return (
        <>
            <ScrollArea offsetScrollbarX={true} contained={true}>
                <Styler display="flex" justifyContent="center">
                    <Styler display="flex" alignItems="center">
                        {items.map((item, index) => {
                            const key = `usp-${item.contentId}-${index}`;
                            return <USPItem item={item} index={index} onItemClick={handleItemClick} key={key} />;
                        })}
                    </Styler>
                </Styler>
            </ScrollArea>
            {(dialogOpen || !dialogExited) && (
                <USPDialog
                    dialogTitle={dialogTitle}
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    onExited={() => setDialogExited(true)}
                    additionalContentId={Number(selectedItemContentId)}
                />
            )}
        </>
    );
}
