import * as React from 'react';
import { useAdditionalContent } from '@honeycomb/data';
import { Dialog2, DialogContent, DialogHeader, Styler, TypographyStyler, UnsafeHTML } from '@honeycomb/ui-core';
function getFirstWord(title) {
    const words = title.length && title.split(' ');
    if (words && words.length) {
        return words[0].toLowerCase();
    }
    return '';
}
export function USPDialog({ dialogTitle, additionalContentId, open, onClose, onExited, }) {
    var _a, _b;
    const [getAdditionalContent, additionalContentResult] = useAdditionalContent();
    const { data, loading, error } = additionalContentResult;
    let displayData = '';
    React.useEffect(() => {
        // Only if we have a contentId present and its a valid one,
        // we will make a call to fetch the content data.
        if (additionalContentId > 0) {
            getAdditionalContent({ variables: { contentId: additionalContentId } });
        }
    });
    if ((_a = data === null || data === void 0 ? void 0 : data.getUSPContent) === null || _a === void 0 ? void 0 : _a.additionalContent) {
        displayData = (_b = data === null || data === void 0 ? void 0 : data.getUSPContent) === null || _b === void 0 ? void 0 : _b.additionalContent;
    }
    return (React.createElement(Dialog2, { loading: loading, open: open, onClose: onClose, onExited: onExited, size: "m" },
        React.createElement(DialogHeader, { withCloseButton: true, HeadingProps: { 'data-id': `modal-header-${getFirstWord(dialogTitle)}` } }, dialogTitle),
        React.createElement(DialogContent, null,
            React.createElement(Styler, { pt: 4, pb: 6, "data-id": `modal-content-${!error && getFirstWord(dialogTitle)}` },
                React.createElement(TypographyStyler, { headingSizeMap: { h1: 's', h2: 's', h3: 's', h4: 's', h5: 's', h6: 's' } },
                    React.createElement(UnsafeHTML, null, displayData)),
                error && React.createElement("p", null, "Unable to load content.")))));
}
