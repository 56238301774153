var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { callSideEffects } from '../../util';
import { DateFormats, CurrencyFormats, } from '../../contracts/types';
const HOTELS_PAGING_TYPE = 1;
export function useRecentSearchResults(sideEffects) {
    const HOTEL_SEARCH_RESULTS_QUERY = gql `
        query HotelRecentSearchResults(
            $tripStateId: ID!
            $sortCriterion: SortCriterion
            $filters: HotelFiltersInput
            $paging: HotelPage
            $currencyFormat: CurrencyFormats
        ) {
            hotelSearchResults(
                tripStateId: $tripStateId
                filters: $filters
                sortCriterion: $sortCriterion
                paging: $paging
            ) {
                isNumberOfNightsAdjusted
                hotels {
                    hotel {
                        estabId
                        name
                        starRating
                        geography {
                            location
                            country
                            province
                            latitude
                            longitude
                        }
                        tripadvisorFeedback {
                            rating
                            numberOfReviews
                            ratingImageUrl
                            tripAdvisorURL
                            reviews {
                                title
                                comment
                                publishedDate
                                ratingImageUrl
                            }
                        }
                        tags {
                            text
                            themeColor
                        }
                    }
                    pricingSummary {
                        isSponsored
                        boardTypeDescription
                        price
                        priceFormatted(format: $currencyFormat)
                        pricePerNightFormatted(format: $currencyFormat)
                        pricePerPersonFormatted(format: $currencyFormat)
                        includedExtras
                        callout {
                            text
                            themeColor
                        }
                        checkInDate
                        numberOfNights
                        departureDate
                        teaserPrice(rounded: true) {
                            parts {
                                type
                                value
                            }
                            value
                            formatted
                        }
                    }
                    flight {
                        outboundLeg {
                            segments {
                                departureAirport {
                                    title
                                }
                            }
                        }
                    }
                }
                tripStateId
            }
        }
    `;
    const [getRecentSearchResultQuery, getRecentSearchResultState] = useLazyQuery(HOTEL_SEARCH_RESULTS_QUERY, {
        notifyOnNetworkStatusChange: true,
        ssr: false,
    });
    const getRecentSearchResult = useCallback((tripStateId, pageSize = 3) => __awaiter(this, void 0, void 0, function* () {
        const { data: getSearchResultResponse } = yield getRecentSearchResultQuery({
            variables: {
                paging: {
                    limit: pageSize,
                    offset: 0,
                    pagingType: HOTELS_PAGING_TYPE,
                },
                tripStateId,
                format: DateFormats.Default,
                departureDateFormat: DateFormats.Fulldate,
                timeFormat: DateFormats.Time,
                currencyFormat: CurrencyFormats.Rounded,
            },
            onCompleted: (completedData) => {
                if (sideEffects) {
                    callSideEffects(sideEffects, completedData);
                }
            },
        });
        return getSearchResultResponse;
    }), [getRecentSearchResultQuery, sideEffects]);
    return [getRecentSearchResult, getRecentSearchResultState];
}
