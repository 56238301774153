import React from 'react';
import { useGlobalContext } from '@honeycomb/data';
import { FlexGrid, NumberPicker, Styler, SelectField, TypographyBody } from '@honeycomb/ui-core';
import { RoomPickerPanel } from './RoomPickerPanel';
export function ChildSelector({ state, setNumberOfChildren, setSelectedChildAge, }) {
    const { resourceStrings: { AGEOFCHILDREN, CHILD, CHILDREN, YEARS }, } = useGlobalContext();
    const { roomIndex, maxChildren, maxChildAge, childAges, childAgeOptions, error } = state;
    const key = roomIndex !== undefined ? `-${roomIndex}` : '';
    const keyChild = roomIndex !== undefined ? `room-${roomIndex}-` : '';
    const fieldId = `child-picker${key}`;
    return (React.createElement("div", null,
        React.createElement(RoomPickerPanel, { title: CHILDREN, subTitle: `0-${maxChildAge} ${YEARS}`, fieldId: fieldId },
            React.createElement(NumberPicker, { id: fieldId, name: fieldId, min: 0, max: maxChildren, value: childAges === null || childAges === void 0 ? void 0 : childAges.length, inputReadOnly: true, onChange: (value) => {
                    setNumberOfChildren(value, roomIndex);
                } })),
        (childAges === null || childAges === void 0 ? void 0 : childAges.length) > 0 && (React.createElement(Styler, { mt: 4 },
            React.createElement(TypographyBody, { size: "s", mb: 4 }, AGEOFCHILDREN),
            React.createElement(FlexGrid, { container: true, spacing: 4 }, childAges === null || childAges === void 0 ? void 0 : childAges.map((age, childIndex) => {
                const id = `${keyChild}child-${childIndex}`;
                const options = childAgeOptions.map((option, index) => {
                    return (React.createElement("option", { key: `${id}-age-${index + 1}`, value: option.toString() }, option));
                });
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                options.unshift(React.createElement("option", { key: `${id}-age-none`, value: "" }));
                return (React.createElement(FlexGrid, { xs: 6, key: `child-${childIndex + 1}` },
                    React.createElement(SelectField, { id: id, label: `${CHILD} ${childIndex + 1}`, value: age !== undefined ? age.toString() : '', 
                        // onClick={() => setSelectedChildData(childIndex, roomIndex)}
                        onChange: (event) => {
                            const { value } = event.currentTarget;
                            const selectedAge = value === '' ? undefined : Number(value);
                            setSelectedChildAge(selectedAge, childIndex, roomIndex);
                        }, required: true, fullWidth: true, success: age !== undefined, error: error && age === undefined }, options)));
            }))))));
}
