import React from 'react';
import { ImageOrigin } from '@honeycomb/data';
import { Card, ChevronRightIcon, Anchor, Image, TypographyHero, CardSection, Stacker, ImageContent, PriceBlock, PriceBlockAmount, PriceBlockCaption, PriceBlockLegend, } from '@honeycomb/ui-core';
import { augmentImageUrl } from '../../../../utils/images';
import { StyledMoney } from '../../../../components/StyledMoney';
export function PriceBlockCardItem(props) {
    var _a, _b, _c;
    const { cardItem, resourceStrings } = props;
    const { teaserPrice, imageOrigin, image } = cardItem;
    const imgOrigin = imageOrigin !== null && imageOrigin !== void 0 ? imageOrigin : ImageOrigin.Facade;
    const imageSrc = React.useMemo(() => augmentImageUrl(imgOrigin, image !== null && image !== void 0 ? image : '', 'priceBlock'), [imgOrigin, image]);
    return (React.createElement(Card, { h: "120px", size: "xs", orientation: "landscape", SurfaceProps: { radius: 's' } },
        React.createElement(CardSection, { w: "50%", p: 0 },
            React.createElement(Image, { withPlaceholder: true, src: imageSrc, backgroundMask: "linear-gradient(180deg,rgba(0, 0, 0, 0.19), rgba(0, 0, 0, 0.59))", h: "100%", styleId: "price-block-card-image" },
                React.createElement(ImageContent, { display: "flex", alignItems: "center", justifyContent: "center", ta: "center" },
                    React.createElement(TypographyHero, { size: "xs", color: "common.white" }, (_a = cardItem === null || cardItem === void 0 ? void 0 : cardItem.overlayText) !== null && _a !== void 0 ? _a : '')))),
        React.createElement(CardSection, { w: "50%", display: "flex", alignItems: "center" },
            React.createElement(Stacker, { direction: "row", spacing: 3, alignItems: "center", justifyContent: "space-between", w: "100%" },
                React.createElement(Anchor, { underline: "none", href: (_b = cardItem.linkUrl) !== null && _b !== void 0 ? _b : '', blockLink: true },
                    React.createElement(PriceBlock, { align: "start" },
                        React.createElement(PriceBlockCaption, null, resourceStrings.pricePrefixText),
                        React.createElement(PriceBlockAmount, { styleId: "price-block-card-price" },
                            React.createElement(StyledMoney, { parts: (_c = teaserPrice === null || teaserPrice === void 0 ? void 0 : teaserPrice.parts) !== null && _c !== void 0 ? _c : [], formatted: (teaserPrice === null || teaserPrice === void 0 ? void 0 : teaserPrice.formatted) || '' })),
                        React.createElement(PriceBlockLegend, null, resourceStrings.priceSuffixText))),
                React.createElement(ChevronRightIcon, { color: "primary.base" })))));
}
