import { Cabin } from '@honeycomb/data';
export function getCabinDisplay(cabin, resourceStrings) {
    const { HC_CABIN_ECONOMY, HC_CABIN_PREMIUM_ECONOMY, HC_CABIN_BUSINESS, HC_CABIN_FIRST } = resourceStrings;
    switch (cabin) {
        case Cabin.Economy: {
            return HC_CABIN_ECONOMY;
        }
        case Cabin.PremiumEconomy: {
            return HC_CABIN_PREMIUM_ECONOMY;
        }
        case Cabin.First: {
            return HC_CABIN_FIRST;
        }
        case Cabin.Business: {
            return HC_CABIN_BUSINESS;
        }
        default: {
            return '';
        }
    }
}
