import { useEffect, useRef, useState } from 'react';
export function useIntersect({ root = null, rootMargin = undefined, threshold = 0, }) {
    const [entry, updateEntry] = useState(null);
    const [node, setNode] = useState(null);
    const isClient = Boolean(typeof window !== 'undefined' && window.document && window.document.createElement);
    const observer = useRef(isClient
        ? new IntersectionObserver(([e]) => updateEntry(e), {
            root,
            rootMargin,
            threshold,
        })
        : null);
    useEffect(() => {
        const { current: currentObserver } = observer;
        currentObserver === null || currentObserver === void 0 ? void 0 : currentObserver.disconnect();
        if (node)
            currentObserver === null || currentObserver === void 0 ? void 0 : currentObserver.observe(node);
        return () => currentObserver === null || currentObserver === void 0 ? void 0 : currentObserver.disconnect();
    }, [node]);
    return [setNode, entry];
}
