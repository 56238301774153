import { SearchDisplayType } from './SearchContext';
export const popupPlacement = 'bottom-start';
export const defaultRoom = {
    adults: 2,
    childAges: [],
};
export const getSearchDisplayType = (largeUp) => {
    if (largeUp) {
        return SearchDisplayType.POPUP;
    }
    return SearchDisplayType.DIALOG;
};
export function getSearchError(fieldValidity) {
    return Object.keys(fieldValidity).reduce((acc, curr) => {
        const values = fieldValidity[curr];
        return (values === null || values === void 0 ? void 0 : values.error) || acc;
    }, false);
}
function addMissingTrip(key, trips) {
    if (key === 'landingpage' || key === 'establandingpage')
        return trips;
    if (trips.length > 0 && !trips.includes(key.toUpperCase())) {
        trips.unshift(key.toUpperCase());
    }
    return trips;
}
export function getSearchTabs(pageName, funnelSettings) {
    try {
        const settings = JSON.parse(funnelSettings);
        const mapping = {
            transfer: 'TransferFunnel',
            hotel: 'HotelFunnel',
            holiday: 'HolidayFunnel',
            flight: 'FlightFunnel',
            landingpage: 'LandingPage',
            establandingpage: 'EstabLandingPage',
        };
        const key = mapping[pageName.toLowerCase()];
        if (key && key in settings) {
            const data = settings[key].map((value) => value);
            const finalTrips = addMissingTrip(pageName.toLowerCase(), data);
            return finalTrips;
        }
        return [];
    }
    catch (error) {
        console.warn(`Failed to parse funnelSettings - ${funnelSettings}`);
        return [];
    }
}
