import React, { useState, useCallback, useRef } from 'react';
import { useRenderCount, useGlobalContext, TripType, parseBoolean, hasFeatureFlag, FeatureFlagEnum, useCurrentTripId, } from '@honeycomb/data';
import { Button, Container, EyeHideIcon, Paper, Styler, Tab, Tabs, TabsList, FlexGrid, } from '@honeycomb/ui-core';
import { HotelHolidayFlightsSearchUnit } from './HotelHolidayFlightsSearchUnit';
import { useIntersect } from '../../utils/useIntersect';
import { TransferSearchUnit } from './transferUnit/TransferSearchUnit';
export function Search({ state, action, tracking, collapsible = false, sticky = false, open = true, onOpenChange = (val) => val, showToggle = false, hotel, PaperProps: paperProps, searchFunnel, }) {
    var _a, _b, _c;
    const { resourceStrings: { SEARCH_TABS_HOLIDAY_LABEL, SEARCH_TABS_HOTEL_LABEL, SEARCH_TABS_FLIGHT_LABEL, SEARCH_TABS_TRANSFER_LABEL, }, configSettings: { HcDisplaySearchUnit }, } = useGlobalContext();
    const tripStateId = (_c = (_b = (_a = useCurrentTripId()[0]) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.currentTripStateId) !== null && _c !== void 0 ? _c : '';
    // if some how searchfunnel will blank then Hotel will be default funnel
    const searchTabs = searchFunnel && searchFunnel.length > 0 ? [] : [{ key: TripType.Hotel, value: SEARCH_TABS_HOTEL_LABEL }];
    searchFunnel === null || searchFunnel === void 0 ? void 0 : searchFunnel.forEach((item) => {
        if (item === TripType.Flight) {
            searchTabs.push({ key: item, value: SEARCH_TABS_FLIGHT_LABEL });
        }
        else if (item === TripType.Hotel) {
            searchTabs.push({ key: item, value: SEARCH_TABS_HOTEL_LABEL });
        }
        else if (item === TripType.Holiday) {
            searchTabs.push({ key: item, value: SEARCH_TABS_HOLIDAY_LABEL });
        }
        else if (item === TripType.Transfer) {
            searchTabs.push({ key: item, value: SEARCH_TABS_TRANSFER_LABEL });
        }
    });
    // TODO: This can be removed once Flight funnel in live
    if (hasFeatureFlag(FeatureFlagEnum.FLIGHT_FUNNEL) && !(searchFunnel === null || searchFunnel === void 0 ? void 0 : searchFunnel.includes(TripType.Flight))) {
        searchTabs.push({ key: TripType.Flight, value: SEARCH_TABS_FLIGHT_LABEL });
    }
    // !! DEV-Only !! Counts and prints number of renders for component
    useRenderCount('Search');
    const initalTabValue = !tripStateId ? searchTabs[0].key : state.tripType;
    const [selectedTripType, setSelectedTripType] = useState(initalTabValue);
    const [searchError, setSearchError] = useState(false);
    const [inViewRef, entry] = useIntersect({ threshold: 0.2 });
    const searchElementRef = useRef(null);
    const updateTripType = useCallback((value) => {
        setSelectedTripType(value);
    }, []);
    const isTransfer = selectedTripType === TripType.Transfer;
    const onSearchErrorChange = useCallback((val) => {
        setSearchError(val);
    }, []);
    const HotelHolidayFlightsSearchUnitComponent = React.useMemo(() => (React.createElement(HotelHolidayFlightsSearchUnit, { state: state, action: action, tracking: tracking, collapsible: collapsible, sticky: sticky, open: open, onOpenChange: onOpenChange, showToggle: showToggle, hotel: hotel, selectedTripType: selectedTripType, searchElementRef: searchElementRef, entry: entry, onSearchErrorChange: onSearchErrorChange })), [
        action,
        collapsible,
        entry,
        hotel,
        onOpenChange,
        onSearchErrorChange,
        open,
        selectedTripType,
        showToggle,
        state,
        sticky,
        tracking,
        searchElementRef,
    ]);
    const TransferSearchUnitComponent = React.useMemo(() => (React.createElement(TransferSearchUnit, { state: state, action: action, tracking: tracking, collapsible: collapsible, sticky: sticky, open: open, onOpenChange: onOpenChange, selectedTripType: selectedTripType, searchElementRef: searchElementRef, entry: entry, onSearchErrorChange: onSearchErrorChange })), [
        action,
        collapsible,
        entry,
        onOpenChange,
        searchElementRef,
        onSearchErrorChange,
        open,
        selectedTripType,
        state,
        sticky,
        tracking,
    ]);
    if (parseBoolean(HcDisplaySearchUnit) === false) {
        return null;
    }
    const expanded = collapsible && open;
    const WrapperComponent = expanded ? Container : Styler;
    return (React.createElement("div", { ref: (ref) => {
            inViewRef(ref);
            searchElementRef.current = ref;
        } },
        !isTransfer && collapsible && !open && HotelHolidayFlightsSearchUnitComponent,
        isTransfer && collapsible && !open && TransferSearchUnitComponent,
        (!collapsible || expanded) && (React.createElement(Paper, Object.assign({ p: expanded ? 3 : 4, py: expanded ? 3 : undefined, pt: expanded ? undefined : 2, pb: expanded ? undefined : searchError ? 2 : 4, border: expanded ? 2 : 0, pos: expanded ? 'relative' : undefined, bw: expanded ? '0 0 2px 0' : undefined, btr: expanded ? 0 : undefined }, paperProps),
            React.createElement(WrapperComponent, null,
                React.createElement(Styler, { mb: 3 },
                    React.createElement(FlexGrid, { container: true, alignItems: "center" },
                        React.createElement(FlexGrid, { item: true, xs: "fill" },
                            React.createElement(Tabs, { "aria-label": "Search panel tabs", onChange: (_e, value) => updateTripType(value), value: selectedTripType, positioning: "default", size: "s" },
                                React.createElement(TabsList, null, searchTabs.map((tab) => (React.createElement(Tab, { value: tab.key, key: tab.key, "data-id": "search-tab", "data-key": tab.key }, tab.value)))))),
                        expanded && (React.createElement(FlexGrid, { item: true, xs: "hold" },
                            React.createElement(Button, { onClick: () => onOpenChange(false), icon: React.createElement(EyeHideIcon, null), size: "xs", variant: "outlined" }, "Hide"))))),
                isTransfer ? TransferSearchUnitComponent : HotelHolidayFlightsSearchUnitComponent)))));
}
