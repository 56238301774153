var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useHoneycombTheme, Popover } from '@honeycomb/ui-core';
import { SEARCH_FIELD_POPOVER_INNER_PADDING } from '../../../../utils/style-constants';
const getEdgeOffset = (placement, innerPadding) => {
    switch (placement) {
        case 'bottom-start':
            return innerPadding * -1;
        case 'bottom-end':
            return innerPadding;
        default:
            return 0;
    }
};
const DYNAMIC_HEIGHT_BUFFER = 50;
// This Popup implementation is specifically for search unit fields. The popup offset is set to
// exactly align with the bottom of the search unit panel when it has a single row of fields.
export function SearchFieldPopover(_a) {
    var { children, placement = 'bottom-start', dynamicHeight, error = false, scrollingElement } = _a, other = __rest(_a, ["children", "placement", "dynamicHeight", "error", "scrollingElement"]);
    const { theme } = useHoneycombTheme();
    const offset = {
        // We want the top edge of the popup to align with the bottom of the search control.
        // If any of the fields are in the error state then the offset needs to be adjusted
        // to account for the additional height of the error label.
        mainAxis: error ? 28 : 16,
        // We want to offset the left alignment of the popup so that the inner container aligns with the edge of the field.
        crossAxis: getEdgeOffset(placement, SEARCH_FIELD_POPOVER_INNER_PADDING * theme.spacingUnit),
    };
    // We want the inner scrollable panel to use all available height without overflowing the boundary.
    const handleDynamicSizing = (element) => (_availableWidth, availableHeight) => {
        // eslint-disable-next-line no-param-reassign
        element.style.maxHeight = `${availableHeight - DYNAMIC_HEIGHT_BUFFER}px`;
    };
    return (React.createElement(Popover, Object.assign({ placement: placement, offset: offset, shift: true, dismiss: true, onCalculateSpace: dynamicHeight && scrollingElement ? handleDynamicSizing(scrollingElement) : undefined }, other), children));
}
