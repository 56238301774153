import React from 'react';
import { Button, EditIcon, FlexGrid, Paper, TypographyBody } from '@honeycomb/ui-core';
import { useGlobalContext } from '@honeycomb/data';
export function CollapsedSearchSummary({ fields, loading, onCtaClick, }) {
    const { resourceStrings: { COLLAPSED_SEARCH_CTA_LABEL }, } = useGlobalContext();
    const handleCtaClick = (event) => {
        if (onCtaClick) {
            onCtaClick(event);
        }
    };
    return (React.createElement(Paper, { radius: "s", px: 3, py: 2, border: 0, bg: "background.offset", w: "100%" },
        React.createElement(FlexGrid, { container: true, spacing: 2, alignItems: "center" },
            React.createElement(FlexGrid, { xs: "fill" }, fields.top[0] && (React.createElement(React.Fragment, null,
                React.createElement(TypographyBody, { size: "s", weight: "bold", truncate: true }, fields.top.map((field) => field.value).join(` ${String.fromCharCode(183)} `)),
                React.createElement(TypographyBody, { size: "s", truncate: true }, fields.bottom.map((field) => field.value).join(` ${String.fromCharCode(183)} `))))),
            React.createElement(FlexGrid, { xs: "hold" },
                React.createElement(Button, { color: "callToAction", size: "m", icon: React.createElement(EditIcon, null), onClick: handleCtaClick, loading: loading }, COLLAPSED_SEARCH_CTA_LABEL)))));
}
