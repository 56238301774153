import { gql, useMutation } from '@apollo/client';
import { callSideEffects } from '../../util';
import { GET_SHORTLIST } from './useShortlist';
const REMOVE_HOTEL_FROM_SHORTLIST_MUTATION = gql `
    mutation RemoveHotelFromShortlist($estabId: Int!) {
        removeHotelFromShortlist(estabId: $estabId) {
            success
            estabId
        }
    }
`;
export function useRemoveHotelFromShortlist(sideEffects) {
    return useMutation(REMOVE_HOTEL_FROM_SHORTLIST_MUTATION, {
        onCompleted: (removeData) => {
            callSideEffects(sideEffects, removeData);
        },
        refetchQueries: [GET_SHORTLIST, 'getShortlist'],
    });
}
