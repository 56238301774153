import { useGlobalContext, SearchFieldEnum } from '@honeycomb/data';
import { ButtonField, Paper, Stacker, TypographyBody, PopoverAnchor, } from '@honeycomb/ui-core';
import React from 'react';
import { getTransferOccupancySummary } from '../../../utils/getOccupancySummary';
import { FieldDialog } from '../components/FieldDialog';
import { AdultSelector } from '../components/AdultSelector';
import { ChildSelector } from '../components/ChildSelector';
import { FieldErrorMessage } from '../components/FieldErrorMessage';
import { OccupancyHelpMessage } from '../components/OccupancyHelpMessage';
import { OccupancyTotals } from '../components/OccupancyTotals';
import { SearchDisplayType, useSearchContext } from '../SearchContext';
import { SearchDispatchActionType } from '../SearchReducer';
import { SearchFieldPopover } from '../components/SearchFieldPopover/SearchFieldPopover';
import { SearchFieldPopoverPanel } from '../components/SearchFieldPopover/SearchFieldPopoverPanel';
import { SEARCH_FIELD_POPOVER_INNER_PADDING } from '../../../utils/style-constants';
const maxAdults = 30;
const maxChildren = 15;
const maxChildAge = 11;
const minAdultAge = 12;
const childAgeOptions = [...Array(maxChildAge + 1).keys()];
export function OccupantsSelector({ popupPlacement = 'bottom-end', onDone, }) {
    var _a, _b, _c;
    const { openField, onOpen, onClose, displayType, searchDispatch, searchState, fieldValidity, searchError } = useSearchContext();
    const { resourceStrings: { ADULT, ADULTS, CHILD, CHILDREN, DATEPICKER_CHILD_VALIDATION_MSG, SEARCH_OCCUPANCY_PLACEHOLDER, SEARCH_STEP_DONEBUTTONLABEL, SEARCH_PASSENGERS_FIELD_TITLE, }, } = useGlobalContext();
    // Is the search currently open on this field
    const open = openField === SearchFieldEnum.OCCUPANTS;
    const validOccupants = ((_a = fieldValidity[SearchFieldEnum.OCCUPANTS]) === null || _a === void 0 ? void 0 : _a.valid) || false;
    const error = ((_b = fieldValidity[SearchFieldEnum.OCCUPANTS]) === null || _b === void 0 ? void 0 : _b.error) || false;
    const { adults, childAges } = searchState;
    const totalsSummary = [
        { caption: ADULTS, value: adults !== null && adults !== void 0 ? adults : 2 },
        { caption: CHILDREN, value: (_c = childAges === null || childAges === void 0 ? void 0 : childAges.length) !== null && _c !== void 0 ? _c : 0 },
    ];
    const popup = displayType === SearchDisplayType.POPUP;
    const dialog = displayType === SearchDisplayType.DIALOG;
    const [scrollingPopoverElement, setScrollingPopoverElement] = React.useState(null);
    const setNumberOfAdults = (value) => {
        searchDispatch({
            type: SearchDispatchActionType.SET_ADULTS,
            adultsValue: value !== null && value !== void 0 ? value : 2,
        });
    };
    const setNumberOfChildren = (value) => {
        var _a, _b;
        const oldChildAgeLength = (_a = childAges === null || childAges === void 0 ? void 0 : childAges.length) !== null && _a !== void 0 ? _a : 0;
        let newChildAge = [];
        if (value - oldChildAgeLength > 0) {
            newChildAge = [...(childAges !== null && childAges !== void 0 ? childAges : []), ...Array(value - oldChildAgeLength).fill(undefined)];
        }
        else {
            newChildAge = (_b = childAges === null || childAges === void 0 ? void 0 : childAges.slice(0, value)) !== null && _b !== void 0 ? _b : [];
        }
        searchDispatch({
            type: SearchDispatchActionType.SET_CHILDAGES,
            childAges: newChildAge !== null && newChildAge !== void 0 ? newChildAge : [],
        });
    };
    const setSelectedChildAge = (childAge, childIndex) => {
        const updatedChildAges = [...(childAges !== null && childAges !== void 0 ? childAges : [])];
        updatedChildAges[childIndex] = childAge;
        searchDispatch({
            type: SearchDispatchActionType.SET_CHILDAGES,
            childAges: updatedChildAges,
        });
    };
    const content = (React.createElement(Stacker, { spacing: SEARCH_FIELD_POPOVER_INNER_PADDING },
        React.createElement(Paper, { radius: "s", py: 4, px: 3, border: 0 },
            React.createElement(Stacker, { spacing: 6 },
                React.createElement(AdultSelector, { state: { maxAdults, adults: adults !== null && adults !== void 0 ? adults : 2, minAdultAge }, setNumberOfAdults: setNumberOfAdults }),
                React.createElement(ChildSelector, { state: { maxChildAge, maxChildren, childAges: childAges !== null && childAges !== void 0 ? childAges : [], childAgeOptions, error }, setNumberOfChildren: setNumberOfChildren, setSelectedChildAge: setSelectedChildAge }))),
        React.createElement(OccupancyHelpMessage, null)));
    const summaryText = getTransferOccupancySummary(adults !== null && adults !== void 0 ? adults : 2, childAges !== null && childAges !== void 0 ? childAges : [], { ADULT, ADULTS, CHILD, CHILDREN });
    const titleText = SEARCH_PASSENGERS_FIELD_TITLE;
    const fieldError = (React.createElement(FieldErrorMessage, { error: error, message: DATEPICKER_CHILD_VALIDATION_MSG, "data-id": "occupancy-error-message" }));
    const handleClickOrFocus = () => {
        if (!open) {
            onOpen(SearchFieldEnum.OCCUPANTS);
        }
    };
    const handleReset = () => {
        searchDispatch({
            type: SearchDispatchActionType.RESET_OCCUPANCY,
        });
    };
    const button = (React.createElement(ButtonField, { label: titleText, fullWidth: true, error: error, value: summaryText, placeholder: SEARCH_OCCUPANCY_PLACEHOLDER, onClick: handleClickOrFocus, onFocus: () => popup && handleClickOrFocus(), size: { xs: 'm', m: 'l' }, variant: "alternative" }));
    return (React.createElement(React.Fragment, null,
        popup && (React.createElement(SearchFieldPopover, { open: open, onClose: () => onClose(), placement: popupPlacement, dynamicHeight: true, error: searchError, scrollingElement: scrollingPopoverElement },
            React.createElement(PopoverAnchor, null, button),
            React.createElement(SearchFieldPopoverPanel, { width: 375, scrollingElementRef: (ref) => setScrollingPopoverElement(ref) }, content))),
        dialog && (React.createElement(React.Fragment, null,
            button,
            React.createElement(FieldDialog, { open: open, onClose: onClose, title: titleText, ctaProps: {
                    onClick: onDone,
                    label: SEARCH_STEP_DONEBUTTONLABEL,
                    disabled: !validOccupants,
                }, resetProps: {
                    onClick: handleReset,
                    label: 'Reset',
                }, headerContent: error ? (React.createElement(Paper, { radius: "s", p: 2, border: 0 },
                    React.createElement(TypographyBody, { size: "s", color: "system.error.base" }, DATEPICKER_CHILD_VALIDATION_MSG))) : undefined, footerContent: React.createElement(OccupancyTotals, { totals: totalsSummary }) }, content))),
        fieldError));
}
