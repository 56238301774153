import { CardType, } from '@honeycomb/data';
function createMerchandiseCard(input) {
    const { imageLink, imageUrl, mobileImageUrl, overlayText1, overlayText2, overlayText3, promotionText1, promotionText2, promotionText3, overlayLogo, clickUrl, impressionUrl, viewEventUrl, useExternalImageSource, } = input;
    return {
        __typename: 'MerchandiseCard',
        type: CardType.Merchandise,
        imageUrl: imageUrl !== null && imageUrl !== void 0 ? imageUrl : null,
        mobileImageUrl: mobileImageUrl !== null && mobileImageUrl !== void 0 ? mobileImageUrl : null,
        imageLink: imageLink !== null && imageLink !== void 0 ? imageLink : null,
        overlayText1,
        overlayText2,
        overlayText3,
        promotionText1,
        promotionText2,
        promotionText3,
        clickUrl: clickUrl !== null && clickUrl !== void 0 ? clickUrl : '#',
        impressionUrl: impressionUrl !== null && impressionUrl !== void 0 ? impressionUrl : null,
        viewEventUrl: viewEventUrl !== null && viewEventUrl !== void 0 ? viewEventUrl : null,
        overlayLogo: overlayLogo !== null && overlayLogo !== void 0 ? overlayLogo : null,
        useExternalImageSource: useExternalImageSource !== null && useExternalImageSource !== void 0 ? useExternalImageSource : false,
    };
}
const IMAGE_SOURCE_PLATFORM195 = 'Platform 195';
export function mapMerchandiseCardsWithAds(item, getPlatform195AdImage) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    if (item.useExternalImageSource &&
        item.externalImageSource === IMAGE_SOURCE_PLATFORM195 &&
        item.zoneId &&
        (item === null || item === void 0 ? void 0 : item.zoneId) > 0) {
        const adImage = getPlatform195AdImage === null || getPlatform195AdImage === void 0 ? void 0 : getPlatform195AdImage.find((img) => (img === null || img === void 0 ? void 0 : img.zoneId) === item.zoneId);
        if (!adImage)
            return item;
        return createMerchandiseCard({
            imageLink: (_b = (_a = adImage === null || adImage === void 0 ? void 0 : adImage.link) !== null && _a !== void 0 ? _a : item.imageLink) !== null && _b !== void 0 ? _b : '',
            imageUrl: (_d = (_c = adImage === null || adImage === void 0 ? void 0 : adImage.imageUrl) !== null && _c !== void 0 ? _c : item.imageUrl) !== null && _d !== void 0 ? _d : '',
            mobileImageUrl: (_f = (_e = adImage === null || adImage === void 0 ? void 0 : adImage.mobileImageUrl) !== null && _e !== void 0 ? _e : item.imageUrl) !== null && _f !== void 0 ? _f : '',
            overlayText1: (_h = (_g = adImage === null || adImage === void 0 ? void 0 : adImage.headline) !== null && _g !== void 0 ? _g : item.overlayText1) !== null && _h !== void 0 ? _h : '',
            overlayText2: (_k = (_j = adImage === null || adImage === void 0 ? void 0 : adImage.subhead) !== null && _j !== void 0 ? _j : item.overlayText2) !== null && _k !== void 0 ? _k : '',
            overlayText3: adImage === null ? (_l = item.overlayText3) !== null && _l !== void 0 ? _l : '' : '',
            promotionText1: (_o = (_m = adImage === null || adImage === void 0 ? void 0 : adImage.promotionText) !== null && _m !== void 0 ? _m : item === null || item === void 0 ? void 0 : item.promotionText1) !== null && _o !== void 0 ? _o : '',
            promotionText2: adImage === null ? (_p = item === null || item === void 0 ? void 0 : item.promotionText2) !== null && _p !== void 0 ? _p : '' : '',
            promotionText3: adImage === null ? (_q = item === null || item === void 0 ? void 0 : item.promotionText3) !== null && _q !== void 0 ? _q : '' : '',
            overlayLogo: (_s = (_r = adImage === null || adImage === void 0 ? void 0 : adImage.overlayLogo) !== null && _r !== void 0 ? _r : item.overlayLogo) !== null && _s !== void 0 ? _s : '',
            clickUrl: (_t = adImage === null || adImage === void 0 ? void 0 : adImage.clickUrl) !== null && _t !== void 0 ? _t : '',
            impressionUrl: (_u = adImage === null || adImage === void 0 ? void 0 : adImage.impressionUrl) !== null && _u !== void 0 ? _u : '',
            viewEventUrl: (_v = adImage === null || adImage === void 0 ? void 0 : adImage.viewEventUrl) !== null && _v !== void 0 ? _v : '',
            useExternalImageSource: true,
        });
    }
    return item;
}
export function getMerchandiseZones(rootComponent) {
    const zoneId = [];
    rootComponent.forEach((component) => {
        if (component.__typename === 'GenericComponent') {
            component.content.forEach((content) => {
                if (content.__typename === 'Carousel') {
                    content.slides.forEach((slide) => {
                        if (slide.__typename === 'MerchandiseCard' && slide.zoneId > 0) {
                            zoneId.push(slide.zoneId);
                        }
                    });
                }
            });
        }
    });
    return zoneId;
}
