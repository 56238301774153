var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { PopoverPanel, Paper, Scrollbars } from '@honeycomb/ui-core';
import { SEARCH_FIELD_POPOVER_INNER_PADDING } from '../../../../utils/style-constants';
export function SearchFieldPopoverPanel(_a) {
    var { children, width, scrollingElementRef, disableScroll } = _a, other = __rest(_a, ["children", "width", "scrollingElementRef", "disableScroll"]);
    return (React.createElement(PopoverPanel, { animate: false },
        React.createElement(Paper, Object.assign({ cornerStyle: "rounded" // force rounded corners for all brands
            , border: 1, shadow: "m", bg: "background.offset", display: "flex", flexDirection: "column", w: width, overflow: "hidden" }, other),
            React.createElement(Scrollbars, { ref: scrollingElementRef, p: SEARCH_FIELD_POPOVER_INNER_PADDING, scrollbarEndMargin: SEARCH_FIELD_POPOVER_INNER_PADDING, mih: 100, disabled: disableScroll }, children))));
}
