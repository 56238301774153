import { useRef, useEffect } from 'react';
export function useRenderCount(name) {
    const renderCount = useRef(0);
    useEffect(() => {
        renderCount.current += 1;
        // TO DO remove console
        // eslint-disable-next-line
        if (process.env.NODE_ENV === 'development')
            console.log(`Rendered ${name} ${renderCount.current} times`);
    });
}
